import React from 'react';
import styled from 'styled-components';
import {
  CaretRightOutlined,
  PauseOutlined
} from '@ant-design/icons';
import Sound from 'react-hifi';

import { black, white } from '../../config/colors';
import { usePlayer } from '../../store';

interface Props {
 handlePress: () => void;
 size?: 'normal' | 'mini';
};


const ButtonPlay:React.FC<Props> = props => {

  const {
    handlePress,
    size = "normal"
  } = props;

  function displayIcon() {
    if (status === Sound.status.PAUSED || status === Sound.status.STOPPED) {
      return <CaretRightOutlined style={{fontSize: 24, color: white}} />;
    } else {
      return <PauseOutlined style={{fontSize: 24, color: white}} />
    }
  }

  const status = usePlayer(statePlayer => statePlayer.state.status);

  return (
    <Container size={size} onClick={handlePress}>
      {displayIcon()}
    </Container>
  );
}

const Container = styled.div<{size: 'normal' | 'mini' | undefined}>`
  width: ${({size}) => size === 'normal' ? '64px': '40px' };
  height: ${({size}) => size === 'normal' ? '64px': '40px' };
  background-color: ${black};
  border-radius: ${({size}) => size === 'normal' ? '12px': '8px' };
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export default ButtonPlay;