import storage from './storage';
import axios, { AxiosError } from 'axios';
import i18n from './i18n';
import { notification } from 'antd';
import { Modal } from 'antd';

import { useApp } from '../store/modelApp';
import { getTokenInfos } from './functions';
import ENVS from '../config';
import { URL_SUBSCRIPTION } from '../config/hardData';
import { TokenInfos } from '../store/modelApp';

const { myIpcRenderer } = window;

// Request function use for classic API
export interface ResponseApi<T> {
  success: boolean,
  message: string,
  data: T;
}

// Axios interceptors config for refresh token

axios.interceptors.response.use((response) => {
  return response;
}, async (error: AxiosError) => {
  const originalRequest = {
    _retry: false,
    config: error.config
  };
  console.error(error);
  if(error.response?.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    const res = await requestOAuth<any>(true);
    if (res.success) {
      const { config } = originalRequest;
      const { data: { token } } = res;
      return axios.request({
        ...config,
        headers: {
          ...config.headers,
          Authorization: 'Bearer' + token
        }
      });
    } else {
      return res;
    }
  }
  // Free Trial exired
  if (error.response?.status === 498) {
    await useApp.getState().effects.logout();
    Modal.info({
      title: i18n.t('Modal.titleFreeTrialFinished'),
      content: i18n.t('Modal.textFreeTrialFinished'),
      okText: i18n.t('Modal.labelButtonFreeTrialFinished'),
      onOk: () => {
        if (process.env.REACT_APP_PLATFORM === "electron") {
          myIpcRenderer.send('APP_OPEN_EXT_URL', URL_SUBSCRIPTION);
        } else {
          window.open(URL_SUBSCRIPTION, '_blank');
        }
      }
    });
  }
  if (error.response?.status !== 401 && error.response?.status !== 400) {
    notification.open({
      message: '',
      description: i18n.t('Notifications.requestFailed'),
    });
  }
  return {error, status: error.response?.status};
});

export async function requestApi<T>(
  endPoint: string,
  method: string = 'GET',
  body: any = false,
  tokenInHeader?: boolean,
): Promise<T> {

  let authToken: string = '';
  if (tokenInHeader) {
    const tokenInfos: TokenInfos | null = await getTokenInfos();
    authToken = `Bearer ${tokenInfos?.token}`;
  }

  const res = await axios.request({
    url: endPoint,
    baseURL: ENVS.API_URL,
    data: (['POST', 'PUT', 'DELETE', 'PATCH'].includes(method) && body) ? body : undefined,
    params: (['GET'].includes(method) && body)? body : undefined,
    // @ts-ignore
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: authToken
    }
  });

  let response = {} as T;
  if (res.status === 204) {
    // @ts-ignore
    response = true;
  } else if (res.status === 200 && res.data) {
    response = res.data;
  } else if( res.status === 400) {
    // @ts-ignore
    response = false;
  }
  return response;
}

export async function requestOAuth<T>(
  refresh: boolean,
  email?: string,
  password?: string
): Promise<ResponseApi<T>> {

  const config = {
    method: "POST",
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: ''
  };

  if (refresh) {
    // For refresh
    const tokenInfos: TokenInfos | null = await getTokenInfos();
    config.body = `grant_type=refresh_token&client_id=${ENVS.CLIENT_ID}&refresh_token=${encodeURIComponent(tokenInfos?.refreshToken || '')}`;
  } else {
    // For login
    config.body = `grant_type=password&client_id=${ENVS.CLIENT_ID}&username=${email}&password=${encodeURIComponent(password || '')}`;
  }

  return fetch(`${ENVS.API_URL}/v1/token`, config)
    .then(async response => {
      if (response.status === 200) {
        return response.json();
      } else if( response.status === 401) {
        const body = await response.json();
        if(body.code === "E_INVALID_CREDENTIALS") {
          throw body.message;
        } 
      } else {
        throw response.statusText;
      }
    })
    .then(async data => {
      try {
        const tokenInfosString: string = JSON.stringify(data);
        storage.setItem('@tokenInfos', tokenInfosString);
        return ({
          success: true,
          message: '',
          data
        });
      } catch (error) {
        throw error;
      }
    })
    .catch(error => {
      const data = {} as T;
      return ({
        success: false,
        message: error,
        data
      });
    });
}
