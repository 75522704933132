import React from 'react';
import styled from 'styled-components';
import {
  Routes,
  Route
} from "react-router-dom";

// import components
import AccountMenu from './AccountMenu';
import AccountInfos from './AccountInfos';
import AccountZones from './AccountZones';
import AccountTracks from './AccountTracks';
import AccountLanguage from './AccountLanguage';
import ReferBusinessScreen from './ReferBusinessScreen';
import ReferBusinessTuto from './ReferBusinessTuto';


interface Props {};

const AccountScreen: React.FC<Props> = props => {
  return (
    <Container>
      <Routes>
        <Route index element={<AccountMenu />} />
        <Route path="infos" element={<AccountInfos />} />
        <Route path="zones" element={<AccountZones />} />
        <Route path="tracks" element={<AccountTracks />} />
        <Route path="language" element={<AccountLanguage />} />
        <Route path="refer" element={<ReferBusinessScreen />} />
        <Route path="refertuto" element={<ReferBusinessTuto />} />
      </Routes>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export default AccountScreen;