import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { notification } from 'antd';

import iconAccountNoActive from '../../assets/icons/accountNoActive.png';
import iconEmail from '../../assets/icons/email.png';
import iconCity from '../../assets/icons/city.png';
import iconCountry from '../../assets/icons/country.png';

// import components
import InputText from '../atoms/InputText';
import Header from '../molecules/Header';

// import requestApi
import { loadUserProfile, updateUserProfile } from '../../services/SoundSuitService';
import { InfoAccountToUpdate } from '../../services/SoundSuitServiceTypes';

interface Props {};

const AccountInfos: React.FC<Props> = props => {

  async function handleSave(): Promise<void> {
    try {
      const dataToSend: InfoAccountToUpdate = {
        first_name: firstName === ' ' ? data?.first_name : firstName,
        last_name: lastName === ' ' ? data?.last_name : lastName,
        company_name: companyName === ' ' ? data?.company_name : companyName,
        company_address: {
          street_house: addressFirstLine === ' ' && data?.company_address?.street_house !== undefined ? data?.company_address.street_house : addressFirstLine,
          zip_city: addressSecondLine === ' ' && data?.company_address?.zip_city !== undefined ? data?.company_address.zip_city : addressSecondLine,
          country: country === ' ' && data?.company_address?.country !== undefined ? data?.company_address.country : country
        }
      };
      await updateAccount(dataToSend);
    } catch (error) {
      console.log(error);
    }
  }

  const [ t ] = useTranslation();
  const queryClient = useQueryClient();

  const { data } = useQuery('/userinfo', loadUserProfile);

  const { mutateAsync: updateAccount, isLoading } = useMutation(updateUserProfile, {
    onSuccess: () => {
      queryClient.invalidateQueries('/userinfo');
      notification.open({
        message: '',
        description: t('Notifications.requestSuccess'),
      });
    },
    onError: () => {
      notification.open({
        message: '',
        description: t('Notifications.requestFailed'),
      });
    }
  });

  const [ firstName, setFirstName ] = useState<string>(' ');
  const [ lastName, setLastName ] = useState<string>(' ');
  const [ email, setEmail ] = useState<string>(' ');
  const [ companyName, setCompanyName ] = useState<string>(' ');
  const [ addressFirstLine, setAddressFirstLine ] = useState<string>(' ');
  const [ addressSecondLine, setAddressSecondLine ] = useState<string>(' ');
  const [ country, setCountry ] = useState<string>(' ');

  return (
    <Container>
      <Header
        title={t('Account.businessInfo')}
        handlePressSave={handleSave}
        inProgress={isLoading}
      />
      <WrapperOptions>
        <InputText
          FixedIcon={<Icon src={iconAccountNoActive} />}
          label={t('Account.firstName')}
          value={firstName === ' ' ? data?.first_name : firstName}
          handleChange={setFirstName}
        />
        <InputText
          FixedIcon={<Icon src={iconAccountNoActive} />}
          label={t('Account.lastName')}
          value={lastName === ' ' ? data?.last_name : lastName }
          handleChange={setLastName}
        />
        <InputText
          FixedIcon={<Icon src={iconEmail} />}
          label={t('Account.email')}
          value={email === ' ' ? data?.email : email}
          handleChange={setEmail}
          disabled
        />
        <InputText
          FixedIcon={<Icon src={iconAccountNoActive} />}
          label={t('Account.companyName')}
          value={companyName === ' ' ? data?.company_name : companyName}
          handleChange={setCompanyName}
        />
        <InputText
          FixedIcon={<Icon src={iconCity} />}
          label={t('Account.streetAddress')}
          value={addressFirstLine === ' ' && data?.company_address?.street_house !== undefined ? data?.company_address.street_house : addressFirstLine}
          handleChange={setAddressFirstLine}
        />
        <InputText
          FixedIcon={<Icon src={iconCity} />}
          label={t('Account.zipCity')}
          value={addressSecondLine === ' ' && data?.company_address?.zip_city !== undefined ? data?.company_address.zip_city : addressSecondLine}
          handleChange={setAddressSecondLine}
        />
        <InputText
          FixedIcon={<Icon src={iconCountry} />}
          label={t('Account.country')}
          value={country === ' ' && data?.company_address?.country !== undefined ? data?.company_address.country : country}
          handleChange={setCountry}
        />
      </WrapperOptions>
    </Container>
  );
}



const Container = styled.div`
  width:100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 20px 0px 0px;
  padding-right: 20px;
`;
const WrapperOptions = styled.div`
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 40px;
`;
const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

export default AccountInfos;