import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useQuery} from 'react-query';
import { notification } from 'antd';

import { usePlayer } from '../../store';
import { lightgray } from '../../config/colors';
// import components
import Header from '../molecules/Header';
import ZonesRow from '../molecules/ZonesRow';
import Text from '../atoms/Text';

// import requestApi
import { loadUserProfile } from '../../services/SoundSuitService';

const { myIpcRenderer } = window;

interface Props {
  from?: 'login';
};

const AccountInfos: React.FC<Props> = props => {

  async function handleSave(): Promise<void> {
    setZoneSelected(zoneLocal as string);
    notification.open({
      message: '',
      description: t('Notifications.requestSuccess'),
    });
  }

  const [ t ] = useTranslation();

  const { data } = useQuery('/userinfo', loadUserProfile);
  const zoneSelected = usePlayer(state => state.state.zoneSelected);
  const setZoneSelected = usePlayer.getState().reducers.setZoneSelected;

  const [ zoneLocal, setZoneLocal ] = useState<string | null>(zoneSelected);

  useEffect(() => {
    if (data && zoneLocal === null) {
      setZoneLocal(data.zones[0]);
    }
  }, [data]);


  return (
    <Container>
      <Header
        title={t('Account.musicZones')}
        handlePressSave={handleSave}
      />
      <WrapperOptions>
        {(data?.zones || []).map(zone => (
          <ZonesRow
            key={zone}
            zoneId={zone}
            selectedId={zoneLocal}
            handleSelection={setZoneLocal}
          />
        ))}
        <BlocInfo>
          {data?.role === 'location' && (
            <Text align='center' size="xsmall" >{t('Settings.AddZoneTextLocation')}</Text>
          )}
          {data?.role === 'location' && process.env.REACT_APP_PLATFORM === 'web' && (
            <ButtonWeb href={"https://business.soundsuit.fm"} target="_blank">
              <Text color='white' bold>{t('Settings.AdminDashboardLabel')}</Text>
            </ButtonWeb>
          )}              
          {data?.role === 'location' && process.env.REACT_APP_PLATFORM === 'electron' && (
            <ButtonElectron
              onClick={() => {
                myIpcRenderer.send('APP_OPEN_EXT_URL', "https://business.soundsuit.fm")
              }}
            >
              <Text color='white' bold>{t('Settings.AdminDashboardLabel')}</Text>
            </ButtonElectron>
          )}              
          {data?.role === 'location-admin' && (
            <Text align='center' size="xsmall" >{t('Settings.AddZoneTextLocAdmin')}</Text>
          )}
        </BlocInfo>
      </WrapperOptions>
    </Container>
  );
}



const Container = styled.div`
  width:100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 20px 0px 0px;
  padding-right: 20px;
`;
const WrapperOptions = styled.div`
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 40px;
  padding-bottom: 40px;
`;
const Icon = styled.img`
  width: 24px;
  height: 24px;
`;
const BlocInfo = styled.div`
  width: 100%;
  margin-top: 30px;
  border: solid 1px ${lightgray};
  border-radius: 20px;
  padding: 30px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ButtonWeb = styled.a`
  width: 80%;
  background-color: black;
  margin-top: 20px;
  padding: 15px 10px 10px 10px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
`;
const ButtonElectron = styled.button`
  width: 80%;
  background-color: black;
  margin-top: 20px;
  padding: 15px 10px 10px 10px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
`;

export default AccountInfos;