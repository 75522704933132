import React from 'react';
import styled from 'styled-components';
import { Slider } from 'antd';

import iconVolumeNoSound from '../../assets/icons/volumeNoSound.png';
import iconVolume from '../../assets/icons/volume.png';

import { veryLightGrey, black } from '../../config/colors';
import { usePlayer } from '../../store';
// import components

interface Props {
};

const ProgressVolume: React.FC<Props> = props => {

  function onValueChange(value: number) {
    setVolume(value);
  }
  function handleMinVol() {
    setVolume(0);
  }
  function handleMaxVol() {
    setVolume(100);
  }

  const volume = usePlayer(statePlayer => statePlayer.state.volume);
  const setVolume = usePlayer.getState().reducers.setVolume;

  return (
    <ContainerProgressBar>
      <Button onClick={handleMinVol}>
         <Icon src={iconVolumeNoSound} />
      </Button>
      <WrapperSlider>
        <Slider
          onChange={onValueChange}
          value={volume}
          min={0}
          max={100}
          step={1}
          tooltipVisible={false}
          handleStyle={{ opacity: 0}}
          trackStyle={{
            backgroundColor: black
          }}
        />
      </WrapperSlider>
      <Button onClick={handleMaxVol}>
        <Icon src={iconVolume} />
      </Button>
    </ContainerProgressBar>
  );
};

const ContainerProgressBar = styled.div`
  width: 100%;
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const WrapperSlider = styled.div`
  width: 100%;
  padding: 0 10px 0 10px;
`;
const Icon = styled.img`
  width: 20px;
  height: 18px;
  tint-color: ${veryLightGrey};
`;
const Button = styled.div`
  cursor: pointer;
`;

export default ProgressVolume;