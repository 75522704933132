import create from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import { update } from './middleware';


import {
  Model,
  ModelReturn,
  ReducerEffect,
} from './';

export type Language = 'fr' | 'de' | 'en' | 'it' | 'es';


export interface UserState {
  loading: boolean;
  isOnBoardingFinished: boolean;
  isLogged: boolean;
  language: Language;
  // avatar: Image;
};
export interface UserReducers {
  initialize: ReducerEffect<[UserState]>;
  resetState: ReducerEffect<[]>;
  setIsOnBoardingFinished: ReducerEffect<[boolean]>;
  setIsLogged: ReducerEffect<[boolean]>;
  setLanguage: ReducerEffect<[Language]>;
  // setAvatar: ReducerEffect<[Image]>;
};
export interface UserEffects {};

const defaultStateUser: UserState = {
  loading: false,
  isOnBoardingFinished: false,
  isLogged: false,
  language: 'en',
  // avatar: {} as Image
};

const user: Model<UserState, UserReducers, UserEffects> = (update, get) => ({
  state: defaultStateUser,
  reducers: {
    initialize(initialState) {
      update(state => ({
        ...initialState
      }));
    },
    resetState() {
      update(state => ({...defaultStateUser}));
    },
    setIsOnBoardingFinished(isOnBoardingFinished) {
      update(state => ({
        ...state,
        isOnBoardingFinished
      }));
    },
    setIsLogged(isLogged) {
      update(state => ({
        ...state,
        isLogged
      }));
    },
    setLanguage(language) {
      update(state => ({
        ...state,
        language
      }));
    },
    setAvatar(avatar) {
      update(state => ({
        ...state,
        avatar
      }));
    }
  },
  effects: {
  }
});

// @ts-ignore
export const useUser = create(subscribeWithSelector((set, get) => user(update(set, get), get)));