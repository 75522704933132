import React from 'react';
import styled from 'styled-components';

import { usePlayer } from '../../store';
import { lightgray } from '../../config/colors';
// import components
import Text from '../atoms/Text';

interface Props {

};

const SmallInfoTrack: React.FC<Props> = props => {

  const playlist = usePlayer(statePlayer => statePlayer.state.playlist);
  const positionTrack = usePlayer(statePlayer => statePlayer.state.positionTrack);

  return (
    <Container>
      <Text size="xsmall">{playlist[positionTrack]?.artist || ""}</Text>
      <Text size="small" color={lightgray}>{playlist[positionTrack]?.title || ""}</Text>
      <Text size="small" color={lightgray}>{playlist[positionTrack]?.date || ""}</Text>
    </Container>
  );
}

const Container = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  padding-left: 5px;
`;

export default SmallInfoTrack;