import React from 'react';
import styled from 'styled-components';

import iconSplash from '../../assets/icons/iconSplash.png';

// import components
import ScreenContainer from '../atoms/ScreenContainer';

interface Props {};

const SplashScreen:React.FC<Props> = props => {

  return (
    <ScreenContainer gradientBG >
      <Container>
        <Image src={iconSplash} />
      </Container>
    </ScreenContainer>
  );
}


const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;
const Image = styled.img`
  width: 100px;
  height: 100px;
`;
export default SplashScreen;