import create from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import {update} from './middleware';
import i18n from '../utils/i18n';
import semver from 'semver';
import { Modal } from 'antd';

import { delay, getTokenInfos } from '../utils/functions';
import storage from '../utils/storage';
import {
  Model,
  ReducerEffect,
  ReducerEffectPromise
} from './';
import { useUser, Language } from './modelUser';
import { usePlayer } from './modelPlayer';
import { queryClient } from '../store/reactQueryInitial';
import { URL_DOWNLOAD_APP } from '../config/hardData';
// Api Requests
import {
  logout,
  refreshToken,
  loadUserProfile,
  checkAppInfo,
  getZoneById
} from '../services/SoundSuitService';

const { myIpcRenderer } = window;

export interface TokenInfos {
  token: string;
  expiresAt: string;
  refreshToken: string;
  refreshTokenExpiresAt: string;
}

export enum AccountScreen {
  InfoAccount = "INFO",
  ZonesAccount = "ZONES",
  TracksAccount = "TRACKS",
  LanguageAccount = "LANG",
  SupportAccount = "SUPPORT",
  ReferBusiness = "REFER",
  None = "NONE",
}
export enum SettingsScreen {
  TypeSetting = "TYPE",
  TargetSetting = "TARGET",
  GenresSetting = "GENRES",
  MoodSetting = "MOOD",
  AdvancedSetting = "ADVANCED",
  None = "NONE",
}

export type Initialized = 'NOT_CHECKED' | 'TOKEN_VALID' | 'NO_TOKEN';

export interface AppState {
  loading: boolean;
  initialized: Initialized;
  isReady: boolean;
  accountScreen: AccountScreen;
  settingsScreen: SettingsScreen;
  routeSelected: 'Settings' | 'Player' | 'Account' | 'Schedule';
  authError: boolean;
};
export interface AppReducers {
  setIsReady: ReducerEffect<[boolean]>;
  setInitialized: ReducerEffect<[Initialized]>;
  setAccountScreen: ReducerEffect<[AccountScreen]>;
  setSettingsScreen: ReducerEffect<[SettingsScreen]>;
  setRouteSelected: ReducerEffect<['Settings' | 'Player' | 'Account' | 'Schedule']>;
  setAuthError: ReducerEffect<[boolean]>;
};

export interface AppEffects {
  initializedApp: ReducerEffectPromise<[]>;
  loginSuccess: ReducerEffectPromise<[any, any]>;
  handleAtStartMediaToPlay: ReducerEffectPromise<[]>;
  logout: ReducerEffectPromise<[]>;
};



const app: Model<AppState, AppReducers, AppEffects> = (update, get) => ({
  state: {
    loading: false,
    initialized: 'NOT_CHECKED',
    isReady: false,
    accountScreen: AccountScreen.None,
    settingsScreen: SettingsScreen.None,
    routeSelected: 'Player',
    authError: false,
  },
  reducers: {
    setInitialized(initialized) {
      update(state => ({
        ...state,
        initialized
      }));
    },
    setIsReady(isReady) {
      update(state => ({
        ...state,
        isReady
      }));
    },
    setAccountScreen(accountScreen) {
      update(state => ({
        ...state,
        accountScreen
      }));
    },
    setSettingsScreen(settingsScreen) {
      update(state => ({
        ...state,
        settingsScreen
      }));
    },
    setRouteSelected(routeSelected) {
      update(state => ({
        ...state,
        routeSelected
      }));
    },
    setAuthError(authError) {
      update(state => ({
        ...state,
        authError
      }));
    },
  },
  effects: {
    initializedApp: async function() {

      const setInitialized = get().reducers.setInitialized;
      const handleLogout = get().effects.logout;
      const handleAtStartMediaToPlay = get().effects.handleAtStartMediaToPlay;

      if (process.env.REACT_APP_PLATFORM === "electron") {
        // We check if the app is of the minimum version required.
        const { min_version_electron } = await checkAppInfo();
        let currentVersion = '';
        if (process.env.NODE_ENV === 'development') {
          currentVersion = require('../../package.json').version;
        } else {
          currentVersion = await myIpcRenderer.invoke('APP_VERSION');
        }
        if (semver.lt(currentVersion, min_version_electron)) {
          Modal.info({
            title: i18n.t('Modal.titleCheckVersion'),
            content: i18n.t('Modal.textCheckVersion'),
            okText: i18n.t('Modal.labelButtonCheckVersion'),
            onOk: () => myIpcRenderer.send('APP_OPEN_EXT_URL', URL_DOWNLOAD_APP)
          });
          return;
        }
      }

      // loading the persist states if already login and token able to refresh
      try {
        const tokenInfos: TokenInfos | null = await getTokenInfos();
        if(tokenInfos) {
          // We refresh the token if possible
          const tokenExpire = new Date(tokenInfos.expiresAt);
          const refreshTokenExpire = new Date(tokenInfos.refreshTokenExpiresAt);
          const currentDate = new Date();
          const timeLeftToLive: number = ((tokenExpire.getTime() - currentDate.getTime())/1000)/60; // result in minute
          const refreshTimeLeftToLive: number = ((refreshTokenExpire.getTime() - currentDate.getTime())/1000)/60; // result in minute
          // if Token has more than 15min to live, the routine will take care of his refresh so we just load the persist data
          if (timeLeftToLive > 15) {
            await handleAtStartMediaToPlay();
            await delay(800);
            setInitialized('TOKEN_VALID');
          // if Token has less than 15min to live, or token is outdated but refresh token still valid, we refresh it
          } else if ((timeLeftToLive > 0 && timeLeftToLive <= 15) || (timeLeftToLive < 0 && refreshTimeLeftToLive > 1)) {
            const resRefresh = await refreshToken();
            if (resRefresh.success) {
              await handleAtStartMediaToPlay();
              await delay(800);
              setInitialized('TOKEN_VALID');
            } else {
              // if refresh failed for any reason, we logout.
              await handleLogout();
            }
          } else {
            // if not able to refresh, we logout.
            await handleLogout();
          }
        } else {
          await delay(800);
          setInitialized('NO_TOKEN');
        }
      } catch (error) {
        console.error(error);
      }
    },
    loginSuccess: async function(dataAuth, history) {
      const setIsLogged = useUser.getState().reducers.setIsLogged;
      const setLanguage = useUser.getState().reducers.setLanguage;
      const setAuthError = get().reducers.setAuthError;
      const handleAtStartMediaToPlay = get().effects.handleAtStartMediaToPlay;

      try {

        const tokenInfosString = JSON.stringify(dataAuth);
        storage.setItem('@tokenInfos', tokenInfosString);

        console.log('Getting user Data: ');
        const data = await queryClient.fetchQuery('/userinfo', loadUserProfile, {
          staleTime: Infinity,
          cacheTime: Infinity
        });

        // We check if the user is allowed to enter the app
        if(data.subscriptionState === null) {
          setAuthError(true);
          return;
        }

        if(data.language) {
          setLanguage(data.language as Language);
          i18n.changeLanguage(data.language);
        }

        await handleAtStartMediaToPlay();

        setAuthError(false);
        setIsLogged(true);
        if (history) {
          if (data?.isSubAccount && !data?.firstOnboardingDone) {
            history('/selectionZones');
          } else {
            history('/');
          }
        }
      } catch (error) {
        setAuthError(true);
        console.log("ERROR while login: ", error);
      }
    },
    handleAtStartMediaToPlay: async function () {
      const setZoneSelected = usePlayer.getState().reducers.setZoneSelected;
      const setMediaToPlay = usePlayer.getState().reducers.setMediaToPlayLocal;
      const setCurrentChoiceMedia = usePlayer.getState().reducers.setCurrentChoiceMedia;

      try {
        const data = await queryClient.fetchQuery('/userinfo', loadUserProfile);

        if (data.zones.length > 0) {
          setZoneSelected(data.zones[0]);
          const zoneInfo = await queryClient.fetchQuery(['zone', data.zones[0]], () => getZoneById(data.zones[0]));
          setMediaToPlay(zoneInfo.mediaToPlay);
          setCurrentChoiceMedia(zoneInfo.mediaToPlay);
        }
      } catch (error) {
        throw error;
      }
    },
    logout: async function() {
      const setIsLogged = useUser.getState().reducers.setIsLogged;
      const setInitialized = get().reducers.setInitialized;
      const resetStatePlayer: ReducerEffect<[]> = usePlayer.getState().reducers.resetState;

      resetStatePlayer();

      try {
        const success = await logout();
        if (success) {
          setIsLogged(false);
          setInitialized('NO_TOKEN');
          await storage.removeItem('@tokenInfos');
        }
      } catch (error) {
        console.log(error);
      }
    },
  }
});

// @ts-ignore
export const useApp = create(subscribeWithSelector((set, get) => app(update(set, get), get)));