import storage from './storage';
import { TokenInfos } from '../store/modelApp';
import {
  Song,
  MoodLabel,
  ItemSchedule,
  Days,
  WeeklyProgram,
  Track
} from '../services/SoundSuitServiceTypes';
import {
  moodSettings,
  reverseMoodSettings,
  defaultWeeklyProgram,
  startHourSchedule,
  compensationPaddingTopTablet,
  compensationPaddingTopMobile,
  heightTrancheScheduleTablet,
  heightTrancheSchedule,
  heightLineTimeSchedule
} from '../config/hardData';

export function noop():undefined { return undefined};

export function getWidthOrHeight(value: number | string | undefined): string {
  if(value) {
    if(typeof value === 'string') {
      return value;
    }
    if(typeof value === 'number') {
      return `${value}px`;
    }
  }
  return '100px';
}

export function delay(time: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, time)
  })
}

export async function getTokenInfos(): Promise<TokenInfos | null> {
  const tokenInfosString: string | null = await storage.getItem('@tokenInfos');
  if(tokenInfosString) {
    const tokenInfos: TokenInfos = JSON.parse(tokenInfosString);
    return tokenInfos;
  }
  return null;
}

export function secondsToTimeFormat(secondsTotal: number): string {
  const date = new Date(secondsTotal * 1000);
  const minutes = date.getUTCMinutes();
  const seconds = date.getSeconds();

  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}

export function getNowWithFormat(): string {
  const now = new Date();
  const month = now.getMonth() + 1;
  const day = now.getDate();
  const year = now.getFullYear();
  const hours = now.getHours();
  const mins = now.getMinutes();
  return `${day.toString().padStart(2, '0')}-${month.toString().padStart(2, '0')}-${year} ${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
}

export function convertSongsToTracks(songs: Song[]): Track[] {
  const toReturn = songs.reduce((acc, song) => {
    let artwork = '';
    if(song.coverImage) {
      artwork = song.coverImage.startsWith('https:') ? encodeURI(song.coverImage) : encodeURI('http:' + song.coverImage);
    } 
    acc.push({
      id: song.id,
      url: song.file,
      duration: song.duration,
      title: song.title,
      artist: song.artistName,
      description: song.displayName,
      date: song.releaseYear ? song.releaseYear.toString() : '',
      artwork,
      isLiked: song.isLiked,
      isBanned: song.isBanned || false,
      _id: song._id
    });
    return acc;
  }, [] as Track[]);
  return toReturn;
}

interface LabelToArray {
  [key: string]: number[]
}

export function getMoodFromRange(range: number[]): (MoodLabel | undefined)[]{
  if (range.length === 0 || typeof range === 'undefined') {
    return ['smartmood'];
  }
  const rangeToArray = Object.keys(moodSettings).reduce((acc, r) => {
    acc[r] = JSON.parse(moodSettings[r]);
    return acc;
  }, {} as LabelToArray);

  
  const max: number = Math.max(...range);
  const min: number = Math.min(...range);
  const moodsSelected: MoodLabel[] = [];
  Object.keys(rangeToArray).forEach(label => {
    if(rangeToArray[label][0] === min) {
      moodsSelected.push(label as MoodLabel);
    }
    if(rangeToArray[label][1] === max) {
      moodsSelected.push(label as MoodLabel);
    }
  });
  const arToReturn: MoodLabel[] = [...new Set(moodsSelected)];
  return arToReturn;
}

export function truncate(str: string, n: number): string{
  return (str.length > n) ? str.substr(0, n-1) + '...' : str;
};

export function transformHourStringtoDate(hour: string): Date {
  const date = new Date();
  const h = Number(hour.split(':')[0]);
  const m = Number(hour.split(':')[1]);
  const s = Number(hour.split(':')[2]);
  date.setHours(h, m, s);
  return date;
}

export function transformDatetoHourString(date: Date): string {
  return `${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}:00`;
}

export function getDecimalFromMinutes(min: number): number {
  return min / 60;
}

export function getTopItemSchedule(start: string): number {
  const hourItem = Number(start.split(':')[0]);
  const minItem = getDecimalFromMinutes(Number(start.split(':')[1]));
  const gapFromStart = (hourItem + minItem) - startHourSchedule;
  const trueHeightTranche =  heightTrancheScheduleTablet + heightLineTimeSchedule
  return gapFromStart * trueHeightTranche +  compensationPaddingTopTablet;
}

export function getHeightItemSchedule(start: string, end: string): number {
  const length = getSlotLength(start, end);
  const trueHeightTranche = heightTrancheScheduleTablet + heightLineTimeSchedule
  return length * trueHeightTranche;
}

export function getSlotLength(start: string, end: string): number {
  const hourItem = Number(start.split(':')[0]);
  const minItem = getDecimalFromMinutes(Number(start.split(':')[1]));
  const endHourItem = Number(end.split(':')[0]);
  const endMinItem = getDecimalFromMinutes(Number(end.split(':')[1]));
  const length = (endHourItem + endMinItem) - (hourItem + minItem);
  return length;
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}