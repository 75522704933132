import React, { useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Slider } from 'antd';


import { Gender, Styles } from '../../services/SoundSuitServiceTypes';
import { buttonRed } from '../../config/colors';
import { idToLabel, IdToLabel} from '../../config/hardData';
// import components
import Text from '../atoms/Text';
import TargetButton from '../molecules/TargetButton';

interface Option {
  id: Gender | Styles;
  image: any;
}
interface Options {
  [key: string]: Option;
}

const options: Options = {
 woman: {id: "woman", image: require('../../assets/icons/woman.png')},
 man: {id: "man", image: require('../../assets/icons/man.png')},
 manWoman: {id: "manWoman", image: require('../../assets/icons/manWoman.png')},
 mainstream: {id: "mainstream", image: require('../../assets/icons/mainstream.png')},
 trendy: {id: "trendy", image: require('../../assets/icons/trendy.png')},
 alternative: {id: "alternative", image: require('../../assets/icons/alternative.png')}
};

interface Props {
  gender: Gender;
  setGender: React.Dispatch<React.SetStateAction<Gender>>;
  age: [number, number];
  setAge: React.Dispatch<React.SetStateAction<[number, number]>>;
  styles: Styles[];
  setStyles: (id: Styles) => void;
};

const TargetOptions: React.FC<Props> = props => {

  function showLabelStyles():string {
    const labels: string = styles.reduce((acc, id, i) => {
      acc = `${acc}${i === 0 ? '' : ', '}${currentIdToLabel[id]}`;
      return acc;
    }, '');
    return labels;
  }

  const [ t ] = useTranslation();

  const currentIdToLabel: IdToLabel = useRef(idToLabel()).current;

  const {
    gender,
    setGender,
    age,
    setAge,
    styles,
    setStyles,
  } = props;

  return (
    <WrapperOptions>
      <BigRow>
        <Column>
            <Text bold size="xsmall" >{`${t('Settings.gender')}: ${currentIdToLabel[options[gender].id]}`}</Text>
          <Row>
            <TargetButton
              selected={options.woman.id === gender}
              image={options.woman.image}
              label={currentIdToLabel[options.woman.id]}
              id={options.woman.id}
              handlePress={setGender}
            />
            <TargetButton
              selected={options.man.id === gender}
              image={options.man.image}
              label={currentIdToLabel[options.man.id]}
              id={options.man.id}
              handlePress={setGender}
            />
            <TargetButton
              selected={options.manWoman.id === gender}
              image={options.manWoman.image}
              label={currentIdToLabel[options.manWoman.id]}
              id={options.manWoman.id}
              handlePress={setGender}
            />
          </Row>
        </Column>
      </BigRow>
      <BigRow>
        <Column>
          <Text bold size="xsmall" >{`${t('Settings.age')}: (${age[0]} - ${age[1] === 50 ? age[1]+"+" : age[1]})`}</Text>
          <WrapperSlider>
            <Slider
              trackStyle={[{
                backgroundColor: buttonRed
              }]}
              handleStyle={[{
                backgroundColor: buttonRed,
                color: buttonRed,
                borderColor: buttonRed
              }]}
              range
              min={0}
              max={50}
              step={null}
              marks={{
                0: '0',
                20: '20',
                30: '30',
                50: '50+'
              }}
              value={age}
              onChange={setAge}
            />
          </WrapperSlider>
        </Column>
      </BigRow>
      <BigRow>
        <Column>
            <Text bold size="xsmall" >{`${t('Settings.suitableStyles')}: ${showLabelStyles()}`}</Text>
          <Row>
            <TargetButton
              selected={styles.includes(options.mainstream.id as Styles)}
              image={options.mainstream.image}
              label={currentIdToLabel[options.mainstream.id]}
              id={options.mainstream.id}
              handlePress={setStyles}
              contentTip={{
                title: currentIdToLabel[options.mainstream.id],
                text: t(`Modal.${options.mainstream.id}Info`)
              }}

            />
            <TargetButton
              selected={styles.includes(options.trendy.id as Styles)}
              image={options.trendy.image}
              label={currentIdToLabel[options.trendy.id]}
              id={options.trendy.id}
              handlePress={setStyles}
              contentTip={{
                title: currentIdToLabel[options.trendy.id],
                text: t(`Modal.${options.trendy.id}Info`)
              }}

            />
            <TargetButton
              selected={styles.includes(options.alternative.id as Styles)}
              image={options.alternative.image}
              label={currentIdToLabel[options.alternative.id]}
              id={options.alternative.id}
              handlePress={setStyles}
              contentTip={{
                title: currentIdToLabel[options.alternative.id],
                text: t(`Modal.${options.alternative.id}Info`)
              }}

            />
          </Row>
        </Column>
      </BigRow>
    </WrapperOptions>
  );
}

const WrapperOptions = styled.div`
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 40px;
  position: relative;
  z-index: 0;
`;
const Row = styled.div`
  width: 100%;
  height: 130px;
  margin-top: 25px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`;
const BigRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`;
const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const WrapperSlider = styled.div`
  position: relative;
  width: 80%;
  margin-top: 50px;
  margin-bottom: 30px;
`;

export default TargetOptions;