const breakpoints = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1536,
} as const;

export type Breakpoint = keyof typeof breakpoints;

export default breakpoints;

type Query = "min-width" | "max-width";
export type MediaQuery = `(${Query}: ${number}px)`;

export function down(breakpoint: Breakpoint): MediaQuery {
  return `(max-width: ${breakpoints[breakpoint] - 1}px)`;
}

export function up(breakpoint: Breakpoint): MediaQuery {
  return `(min-width: ${breakpoints[breakpoint]}px)`;
}

export function between(
  start: Breakpoint,
  end: Breakpoint
): `${MediaQuery} and ${MediaQuery}` {
  return `(min-width: ${breakpoints[start]}px) and (max-width: ${
    breakpoints[end] - 1
  }px)`;
}

export function only(
  breakpoint: Breakpoint
): `${MediaQuery} and ${MediaQuery}` | MediaQuery {
  const keys = Object.keys(breakpoints) as Breakpoint[];
  const index = keys.indexOf(breakpoint);
  const next = keys[index + 1];

  return next ? between(breakpoint, next) : up(breakpoint);
}
