import React, { useState } from 'react';
import styled from 'styled-components';
import hexToRgba from 'hex-alpha';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { MailOutlined, LeftOutlined } from '@ant-design/icons';

import { white, buttonRed, strongRed} from '../../config/colors';
import { resetPassword } from '../../services/SoundSuitService'
// import components
import ScreenContainer from '../../components/atoms/ScreenContainer';
import Text from '../../components/atoms/Text';
import Title from '../../components/atoms/Title';
import ButtonText from '../../components/atoms/ButtonText';
import InputText from '../../components/atoms/InputText';

interface Props {};

const ForgotPasswordScreen: React.FC<Props> = props => {
  async function handleLogin() {
    setLoading(true);
    setError(false);
    let correct: boolean = true;
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      correct = false;
    }

    if (correct) {
      try {
        const res = await resetPassword({email});
        setLoading(false);
        if(res) {
          navigate(`forgotPassword/${email}`);
        } else {
          setError(true);
        }
      } catch (error) {
        console.log(error);
      }
    }

  }

  const [ t ] = useTranslation();
  const navigate = useNavigate();

  const [ email, setEmail ] = useState<string>('');
  const [ error, setError ] = useState<boolean>(false);
  const [ loading, setLoading ] = useState<boolean>(false);

  return (
    <ScreenContainer>
      <Container>
        <WrapperArrow onClick={() => navigate(-1)}>
          <LeftOutlined style={{ fontSize: '28px' }} />
        </WrapperArrow>
        <Column>
          <Form>
            <Title>
              {t('Auth.forgotPasswordTitle')}
            </Title>
            <InputText
              Icon={<MailOutlined />}
              label={t('Auth.email')}
              value={email}
              handleChange={setEmail}
              keyboardType="email-address"
            />
            {error && (
              <Error>
                <Text size="small" color={strongRed} bold >{t('Auth.emailUnknow')}</Text>
              </Error>
            )}
          </Form>
          <ButtonText
            colorType="red"
            color={white}
            onHandleClick={handleLogin}
            width='65%'
            height={50}
            disabled={!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)}
            loading={loading}
          >
            {t('Auth.buttonResetPassword')}
          </ButtonText>
        </Column>
      </Container>
    </ScreenContainer>
  );
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Column = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 80px 0 30px 0;
`;
const Form = styled.div`
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Error = styled.div`
  width: 50%;
  height: 32px;
  background-color: ${hexToRgba(buttonRed, 0.1)};
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  padding-top: 4px;
`;
const WrapperArrow = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
`;

export default ForgotPasswordScreen;