import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';

import {
  veryVeryLightGrey,
  veryLightLightGrey,
  almostWhite,
} from '../../config/colors';
import { Genres } from '../../services/SoundSuitServiceTypes';
// import components
import Header from '../../components/molecules/Header';
import GenreOptionsSchedule from '../../components/organisms/GenreOptionsSchedule';

interface Props {};

const Genre: React.FC<Props> = (props) => {

  const navigate = useNavigate();
  const location = useLocation();

  const [params, setSearchParams] = useSearchParams();
  const data = Object.fromEntries([...params]);
  const [genre, setGenre] = useState<(Genres | undefined)[]>(typeof data?.genre !== 'undefined' ? JSON.parse(decodeURIComponent(data.genre)) : ['acoustic']);

  const { t } = useTranslation();

  useEffect(() => {
    setSearchParams({
      ...data,
      genre: encodeURIComponent(JSON.stringify(genre))
    });
  }, [genre, setSearchParams]);

  return (
    <Container>
      <Header
        title={t('Schedule.labelGenre')}
        customGoBack={() => navigate(`..${location.search}`)}
      />
      <Body>
        <Wrapper>
          <GenreOptionsSchedule genre={genre} setGenre={setGenre} />
        </Wrapper>
      </Body>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  background-color: ${veryVeryLightGrey};
`;
const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
`;
const Wrapper = styled.div`
  width: 100%;
  background-color: ${almostWhite};
  border-color: ${veryLightLightGrey};
  border-width: 1px;
  border-radius: 10px;
  margin-top: 25px;
`;

export default Genre;