import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { notification, Modal, Button as AntButton } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import {Link} from "react-router-dom";

import iconAdd from '../../assets/icons/add.png';

import { Days} from '../../services/SoundSuitServiceTypes';
import {
  deleteAllScheduleItems,
  deleteSelectedScheduleItems
} from '../../services/SoundSuitService';
import { usePlayer } from '../../store';
import { veryVeryLightGrey, lightgray } from '../../config/colors';
import { useSchedule } from '../../hooks';
// import components
import Title from '../atoms/Title';

interface Props {
  day: Days;
};

const HeaderSchedule: React.FC<Props> = props => {

  async function handleDeleteAll() {
    await deleteAllItems();
  }
  async function handleDeleteSelected() {
    const slotsday = sortTimeslotsByDay(timeslots, day);
    const tabIds = slotsday.map(s => s.id as string);
    await deleteSelectedItems(tabIds);
  }

  const {
    day
  } = props;

  const { sortTimeslotsByDay } = useSchedule();

  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const timeslots = usePlayer(statePlayer => statePlayer.state.timeslots);

  const [ deleteModalOpened, setDeleteModalOpened ] = useState<boolean>(false);


  const { mutateAsync: deleteAllItems, isLoading: isLoadingDeleteAll } = useMutation(deleteAllScheduleItems, {
    onSuccess: () => {
      queryClient.invalidateQueries('/scheduleprogram');
      notification.open({
        message: '',
        description: t('Notifications.requestSuccess'),
      });
      setDeleteModalOpened(false);
    },
    onError: (error) => {
      console.log("ERROR: ", error);
      notification.open({
        message: '',
        description: t('Notifications.requestFailed'),
      });
    }
  });
  const { mutateAsync: deleteSelectedItems, isLoading: isLoadingSelectedDelete } = useMutation(deleteSelectedScheduleItems, {
    onSuccess: () => {
      queryClient.invalidateQueries('/scheduleprogram');
      notification.open({
        message: '',
        description: t('Notifications.requestSuccess'),
      });
      setDeleteModalOpened(false);
    },
    onError: (error) => {
      console.log("ERROR: ", error);
      notification.open({
        message: '',
        description: t('Notifications.requestFailed'),
      });
    }
  });

  return (
    <>
    <Header>
      <Title bold>{t('Schedule.scheduleTitle')}</Title>
      <BarOptions>
        <Link
          to={`item?day=${day}`}
        >
          <Icon src={iconAdd}/>
        </Link>
        <Button style={{ marginLeft: 20}} onClick={() => setDeleteModalOpened(true)} disabled={timeslots.length === 0}>
          <DeleteOutlined style={{ fontSize: '24px', color:timeslots.length > 0 ? "black" : lightgray }} />
        </Button>
      </BarOptions>
    </Header>
    <Modal
      title={t('Schedule.deleteItemsTitle')}
      onCancel={() => setDeleteModalOpened(false)}
      visible={deleteModalOpened}
      closable
      footer={null}
    >
      <WrapperButtons>
      <AntButton danger onClick={handleDeleteAll} >
        {t('Schedule.deleteAllItems')}
      </AntButton>
      <AntButton danger onClick={handleDeleteSelected} >
        {`${t('Schedule.DeleteItemsOf')} ${t(`Schedule.${day}`)}`}
      </AntButton>
      </WrapperButtons>
    </Modal>
    </>
  );
}

const Header = styled.div`
  width: 100%;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const BarOptions = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0;
`;
const Icon = styled.img`
  width: 25px;
  height: 25px;
`;
const Button = styled.div`
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const WrapperButtons = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export default HeaderSchedule;