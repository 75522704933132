import { Genres} from '../services/SoundSuitServiceTypes';

type GenreConverstion = {
  old: Genres;
  new: Genres;
};

export default function useUpdateTags() {

  function convertOldsToNews(list: Genres[]): Genres[] {
    const genreConvertion: GenreConverstion[] = [
      { old: 'nature sound', new: 'nature' },
      { old: 'country', new: 'folk' },
      { old: 'dance&electronic', new: 'dance' },
      { old: 'dance & electronic', new: 'dance' },
      { old: 'world-music', new: 'world' },
      { old: 'jazz&soul', new: 'soul' },
      { old: 'jazz-soul', new: 'soul' }
    ];

    return list.map(genre => {
      const genreObj = genreConvertion.find((g) => g.old === genre);
      return genreObj ? genreObj.new : genre;
    });
  }

  return {
    convertOldsToNews
  };

};
