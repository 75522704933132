import React from 'react';
import {
  useLocation,
  Navigate
} from "react-router-dom";
import { useUser } from "../../store";

interface Props {
  children: JSX.Element;
};

const RequireAuth: React.FC<Props> = props => {

  const {
    children,
  } = props;

  const isLogged = useUser(userState => userState.state.isLogged);
  const location = useLocation();

  if (!isLogged) {
    return <Navigate to="/welcome" state={{ from: location}} replace />
  }

  return children;
}

export default RequireAuth;