import React from 'react';
import styled from 'styled-components';
import { Popover } from 'antd';

import { white, buttonRed, black } from '../../config/colors';
import { Styles, Gender } from '../../services/SoundSuitServiceTypes';
// import components
import Text from '../atoms/Text';

interface ContentTip {
  title: string;
  text: string;
};

interface Props {
  selected: boolean;
  label: string;
  image: any;
  handlePress: (id: Gender & Styles) => void;
  id: Gender | Styles;
  contentTip?: ContentTip | undefined
};

const TargetButton: React.FC<Props> = props => {

  function onHandlePress(){
    handlePress(id as (Gender & Styles));
  }

  const {
    selected,
    label,
    image,
    handlePress,
    id,
    contentTip
  } = props;

  return (
    <Container
      selected={selected}
      onClick={onHandlePress}
    >
      {contentTip && (
        <Popover
          title={contentTip.title}
          content={contentTip.text}
          overlayInnerStyle={{ borderRadius: 10}}
        >
          <Tip>
            <Text
              color={buttonRed}
              size="small"
            >
              i
            </Text>
          </Tip>
        </Popover>
      )}
      <Icon src={image} />
      <TextWrapper>
        <Text align="center" size="small" bold color={selected ? white : black} >{label}</Text>
      </TextWrapper>
    </Container>
  );
}

const Container = styled.div<{selected: boolean}>`
  position: relative;
  width: 88px;
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 44px;
  background-color: ${({selected}) => selected ? buttonRed : white};
  padding: 8px;
  cursor: pointer;
  box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, .1);
  z-index: 50;
`;
const Icon = styled.img`
  width: 72px;
  height: 72px;
  border-radius: 36px;
  margin-bottom: 15px;
`;
const TextWrapper = styled.div`
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
const Tip = styled.div`
  position: absolute;
  top: 0;
  left: 68px;
  width: 18px;
  height: 18px;
  border-radius: 9px;
  border-width: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 2px ${buttonRed};
  background-color: ${white};
  z-index: 100;
`;

const TargetButtonMemoized = React.memo(TargetButton);


export default TargetButtonMemoized;