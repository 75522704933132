import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Tabs } from 'antd';
import { useQueries, useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';

import { usePlayer } from '../../store';
import { white, grey } from '../../config/colors';
import {
  getBannedSongs,
  getLikedSongs,
  unlikeSongs,
  unbanSongs
} from '../../services/SoundSuitService';
import { SongForSetting, ResSongsSetting } from '../../services/SoundSuitServiceTypes';
// import components
import Text from '../../components/atoms/Text';
import Header from '../../components/molecules/Header';
import TrackRow from '../../components/molecules/TrackRow';

interface SongOptions extends SongForSetting {}

interface AllSongs {
  like: SongOptions[];
  ban: SongOptions[];
}

interface PropsList {
  songsList: SongOptions[];
  type: "like" | "ban";
  songsIdsSelected: string[];
  handleToggle: (id: string) => void;
  handleDelete: (type: 'like' | 'ban', id: string) => void;
};

const ListTracks: React.FC<PropsList> = (props) => {

  const {
    songsList,
    type,
    songsIdsSelected,
    handleToggle,
    handleDelete
  } = props;

  const [ t ] = useTranslation();

  return (
    <TabContainer>
      <TextWrapper>
        <Text align="center" size="small" color={grey}>
          {type === 'ban' ? t('Account.tracksTextBanned'): t('Account.tracksTextLiked')}
        </Text>
      </TextWrapper>
      {songsList.map((item, index) => (
        <div  key={item.id} style={{ backgroundColor: white}}>
          <TrackRow
            id={item.id}
            title={item.title}
            artist={item.artistName}
            image={item.coverImage}
            type={type}
            valueOption={!songsIdsSelected.includes(item.id)}
            handleToggle={handleToggle}
            isLast={index === songsList.length - 1}
          />
        </div>
      ))}
    </TabContainer>
  );
}


interface Props {};

const { TabPane } = Tabs;

const AccountTracks: React.FC<Props> = props => {

  function handleSave(): void {
    const tabPromises = [];
    if(songIdsToUnlike.length > 0) {
      tabPromises.push(mutateLikeChanges(songIdsToUnlike));
    }
    if(songIdsToUnbanned.length > 0) {
      tabPromises.push(mutateBanChanges(songIdsToUnbanned));
    }
    Promise.all(tabPromises)
      .then(async () => {
        // We refresh the tracks to come to fit new changes
        toggleRefreshTracks();
        // We refresh the list on this screen for next time
        refreshList();
        notification.open({
          message: '',
          description: t('Notifications.requestSuccess'),
        });
      })
      .catch((error) => {
        console.log('ERROR: ', error);
        notification.open({
          message: '',
          description: t('Notifications.requestFailed'),
        });
      });
  }

  function handleDelete(type: 'like' | 'ban', id: string): void {
    const copySongs: AllSongs = JSON.parse(JSON.stringify(allSongs));
    const index = copySongs[type].findIndex(s => s.id === id);
    copySongs[type].splice(index, 1);
    setAllSongs(copySongs);
  }

  function toggleLike(id: string): void {
    let newL: string[];
    if(songIdsToUnlike.includes(id)) {
      newL = songIdsToUnlike.filter(i => i !== id);
    } else {
      newL = songIdsToUnlike.slice();
      newL.push(id);
    }
    setSongIdsToUnlike(newL);
  };

  function toggleBan(id: string): void {
    let newL: string[];
    if(songIdsToUnbanned.includes(id)) {
      newL = songIdsToUnbanned.filter(i => i !== id);
    } else {
      newL = songIdsToUnbanned.slice();
      newL.push(id);
    }
    setSongIdsToUnbanned(newL);
  }

  async function refreshList() {
    await refetchBan();
    await refetchLike();
    loaded.current = false;
  }

  const [ t ] = useTranslation();
  const toggleRefreshTracks = usePlayer.getState().reducers.toggleRefreshTracks;

  const loaded: React.MutableRefObject<boolean> = useRef(false);

  const [songIdsToUnlike, setSongIdsToUnlike] = useState<string[]>([]);
  const [songIdsToUnbanned, setSongIdsToUnbanned] = useState<string[]>([]);
  const [ allSongs, setAllSongs ] = useState<AllSongs>({like: [], ban:[]});

  const [
    { data: likeData, isSuccess: successLike, refetch: refetchLike },
    { data: banData, isSuccess: successBan, refetch: refetchBan },
  ] = useQueries([
    { queryKey: 'getLikedSongs', queryFn: getLikedSongs},
    { queryKey: 'getBannedSongs', queryFn: getBannedSongs},
  ]);

  const { mutateAsync: mutateBanChanges, isLoading: loadingBanMutation } = useMutation(unbanSongs);
  const { mutateAsync: mutateLikeChanges, isLoading: loadingLikeMutation} = useMutation(unlikeSongs);

  useEffect(() => {
    let like = [] as SongOptions[];
    let ban = [] as SongOptions[];
    if (successLike && successBan && !loaded.current) {
      like = (likeData as ResSongsSetting).songs;
      ban = (banData as ResSongsSetting).songs;
      setAllSongs({like, ban});
      loaded.current = true;
    }
  }, [likeData, banData, successBan, successLike, loaded.current]);

  return (
    <Container>
      <Header
        title={t('Account.likesBannedTracks')}
        handlePressSave={handleSave}
        inProgress={loadingBanMutation || loadingLikeMutation}
      />
      <WrapperOptions>
        <Tabs
          centered
          defaultActiveKey="1"
          tabBarStyle={{
            color: grey
          }}
        >
          <TabPane tab={t('Account.trackLabelLiked')} key="1">
            <ListTracks
              type="like"
              songsList={allSongs.like}
              songsIdsSelected={songIdsToUnlike}
              handleToggle={toggleLike}
              handleDelete={handleDelete}
            />
          </TabPane>
          <TabPane tab={t('Account.trackLabelBanned')} key="2">
            <ListTracks
              type="ban"
              songsList={allSongs.ban}
              songsIdsSelected={songIdsToUnbanned}
              handleToggle={toggleBan}
              handleDelete={handleDelete}
            />
          </TabPane>
        </Tabs>
      </WrapperOptions>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 20px 0px 0px;
  padding-right: 20px;
`;
const WrapperOptions = styled.div`
  width: 100%;
  padding-top: 40px;
`;
const TabContainer = styled.div`
  width: 100%;
  overflow-y: visible;
`;
const TextWrapper = styled.div`
  padding: 20px;
  width: 100%;
`;

export default AccountTracks;