import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import {SearchOutlined} from '@ant-design/icons';

import {
  lightgray,
  almostWhite,
} from '../../config/colors';
import { TypeMedia, Station  } from '../../services/SoundSuitServiceTypes';
import { getZoneById, getStations } from '../../services/SoundSuitService';
import {usePlayer} from '../../store';
import { capitalizeFirstLetter } from '../../utils/functions';
// import components 
import Text from '../../components/atoms/Text';
import MediaRow from './MediaRow';

const { myIpcRenderer } = window;

interface Props {
  type: TypeMedia;
  close: () => void;
};

const ModalMedias: React.FC<Props> = props => {

  const { type, close } = props;

  const { t } = useTranslation();
  const zoneSelected = usePlayer(statePlayer => statePlayer.state.zoneSelected);
  const mediaToPlay = usePlayer(state => state.state.mediaToPlay);

  const { data: dataZone } = useQuery(['zone', zoneSelected], () => {
    if (zoneSelected) {
      return getZoneById(zoneSelected)
    }
  });
  const { data: dataStations } = useQuery('stations', () => {
    if (type === 'station') {
      return getStations();
    }
  });

  const [listMedia, setListMedia] = useState<Station[]>([]);
  const [listMediaSearch, setListMediaSearch] = useState<Station[]>([]);
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    if (dataZone) {
      const keyMedia = `authorized${capitalizeFirstLetter(type)}s`;
      // @ts-ignore
      const authorizedIds = dataZone[keyMedia];
      if (type === 'station' && dataStations && dataStations.length > 0) {
        const list = dataStations.filter(s => authorizedIds.includes(s.id));
        setListMedia(list);
      }
    }

  }, [dataZone, type, dataStations]);

  useEffect(() => {
    if (search) {
      var regexSearch = new RegExp(search, 'gi');
      const rslt = listMedia.reduce<Station[]>((acc, m) => {
        const toTest = `${m.name} ${m.description}`;
        if (regexSearch.test(toTest)) {
          acc.push(m);
        }
        return acc;
      }, []);
      setListMediaSearch(rslt);
    } else {
      setListMediaSearch(listMedia);
    }
  },[search]);

  return (
    <Container>
        <Header>
          <Text bold>{t('Modal.selectStationsTitle')}</Text>
        </Header>
        <Body>
          <WrapperOption>
            <WrapperInput>
              <Input
                type="text"
                placeholder='Search'
                value={search}
                onChange={e => setSearch(e.target.value)}
              />
              <SearchOutlined
                style={{
                  position: 'absolute',
                  right: 10,
                  top: 10,
                  fontSize: '20px',
                  color: "grey"
                }}
              />
            </WrapperInput>
          </WrapperOption>
          <ScrollContainer>
            {(listMediaSearch.length > 0 ? listMediaSearch : listMedia).map((media, i) => (
              <MediaRow
                key={`${i}-media`}
                media={media}
                type={type}
                closeModal={close}
                isSelected={media.id === mediaToPlay?.id}
              />
            ))}
            <BlocInfo>
              <Text align='center' size="xsmall" >{t('Settings.EditStationsText')}</Text>
              {process.env.REACT_APP_PLATFORM === 'web' && (
                <ButtonWeb href={"https://business.soundsuit.fm"} target="_blank">
                  <Text color='white' bold>{t('Settings.AdminDashboardLabel')}</Text>
                </ButtonWeb>
              )}              
              {process.env.REACT_APP_PLATFORM === 'electron' && (
                <ButtonElectron
                  onClick={() => {
                    myIpcRenderer.send('APP_OPEN_EXT_URL', "https://business.soundsuit.fm")
                  }}
                >
                  <Text color='white' bold>{t('Settings.AdminDashboardLabel')}</Text>
                </ButtonElectron>
              )}              
            </BlocInfo>
          </ScrollContainer>
        </Body>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  flex: 1;
  height: 500px;
  background-color: ${almostWhite};
  overflow-y: hidden;
`;
const ScrollContainer = styled.div`
  position: relative;
  background-color: ${almostWhite};
  overflow-y: auto;
  height: 100%;
  padding-bottom: 200px;
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px;
`;
const Body = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 20px 0 20px;
  align-items: center;
  overflow-y: hidden;
`;
const WrapperOption = styled.div`
  width: 100%;
  margin-top: 30px;
  margin-bottom: 40px;
`;
const WrapperInput = styled.div`
  position: relative;
`;
const Input = styled.input`
  width: 100%;
  border: solid 1px ${lightgray};
  border-radius: 10px;
  height: 40px;
  padding: 5px 10px;
`;
const BlocInfo = styled.div`
  width: 100%;
  margin-top: 30px;
  border: solid 1px ${lightgray};
  border-radius: 20px;
  padding: 30px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ButtonWeb = styled.a`
  width: 80%;
  background-color: black;
  margin-top: 20px;
  padding: 15px 10px 10px 10px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
`;
const ButtonElectron = styled.button`
  width: 80%;
  background-color: black;
  margin-top: 20px;
  padding: 15px 10px 10px 10px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
`;


export default ModalMedias;