import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  StepForwardOutlined,
  StepBackwardOutlined
} from '@ant-design/icons';

import { black } from '../../config/colors';

interface Props {
  next: boolean;
  handlePress: () => void;
  size?: 'normal' | 'mini';
};

const ButtonChangeTrack: React.FC<Props> = props => {

  function onHandlePress():void {
    if(!disabled) {
      handlePress();
    }
  }

  const {
    next,
    handlePress,
    size = "normal"
  } = props;
  const [ disabled, setDisabled] = useState<boolean>(false);

  useEffect(() => {

  }, [])


  return (
    <Container size={size} disabled={disabled} onClick={onHandlePress} >
      {next ? (
        <StepForwardOutlined style={{fontSize: 24, color: black}} />
      ):(
        <StepBackwardOutlined style={{fontSize: 24, color: black}} />
      )}
    </Container>
  );
}

const Container = styled.div<{disabled: boolean, size: 'normal' | 'mini' | undefined}>`
  width: ${({size}) => size === 'normal' ? '64px': '40px' };
  height: ${({size}) => size === 'normal' ? '64px': '40px' };
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${({disabled}) => disabled ? 0.3 : 1};
  cursor: pointer;
`;

export default ButtonChangeTrack;