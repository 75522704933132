import { Switch } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Genres, ToggleLabels } from "../../services/SoundSuitServiceTypes";
// import components
import Text from "../atoms/Text";

interface Props {
  selected: (Genres | ToggleLabels)[];
  setSelected: React.Dispatch<React.SetStateAction<(Genres | ToggleLabels)[]>>;
  explicitTitles: boolean;
  setExplicitTitles: React.Dispatch<React.SetStateAction<boolean>>;
  forOnBoarding?: boolean;
}

const AdvancedOptions: React.FC<Props> = (props) => {
  const {
    explicitTitles,
    setExplicitTitles,
    forOnBoarding = false,
  } = props;

  const [t] = useTranslation();

  return (
    <WrapperOptions>
      {/* <Row>
        <Column>
          <div>
            <Text bold size="xsmall">
              Here you can change your advanced settings that will apply on the
              whole SoundSuit App.
            </Text>
          </div>
          <div style={{ marginTop: 10 }}>
            <Text size="xsmall" color="gray">
              Please proceed with caution since those settings are global.
            </Text>
          </div>
        </Column>
      </Row> */}

      <WrapperForm>
        <Line topBorder>
          <Text size="normal">{t("Settings.includeExplicit")}</Text>
          <Switch
            onChange={() => setExplicitTitles((h) => !h)}
            checked={explicitTitles}
          />
        </Line>
      </WrapperForm>
    </WrapperOptions>
  );
};

const WrapperOptions = styled.div`
  position: relative;
  width: 100%;
  padding: 0px 10px;
`;
const WrapperCard = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;
const Row = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin: 40px 0;
`;
const Column = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 15px;
`;
const WrapperForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  margin-top: 20px;
`;
const Line = styled.div<{ topBorder: boolean }>`
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: ${({ topBorder }) =>
    topBorder ? "solid 1px rgba(239, 240, 245, .6)" : "0"};
  border-bottom: solid 1px rgba(239, 240, 245, 0.6);
`;

export default AdvancedOptions;
