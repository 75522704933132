// @ts-nocheck
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { notification } from "antd";

import {
  Genres,
  ToggleLabels,
  PreferredGenresToUpdate,
  LanguagesSong,
} from "../../services/SoundSuitServiceTypes";
import {
  updatePreferredGenres,
  loadUserProfile,
} from "../../services/SoundSuitService";
// import components
import AdvancedOptions from "../../components/organisms/AdvancedOptions";
import Header from "../../components/molecules/Header";

interface Props {}

const minSelectionGenres: number = 1;
const toggleLabels: ToggleLabels[] = ["christmas", "explicit"];

const SettingsAdvanced: React.FC<Props> = (props) => {
  async function handleSave(): Promise<void> {
    if (smartGenres || selectedWithoutToggle.length >= minSelectionGenres) {
      try {
        // @ts-ignore
        let genresData: (Genres | ToggleLabels)[] = [
          ...new Set(selectedWithoutToggle),
        ];
        let languagesData: LanguagesSong[] = ["english"];
        if (explicitTitles) {
          genresData.push("explicit");
        }
        if (christmasTitles) {
          genresData.push("christmas");
        }
        if (frenchLyrics) {
          languagesData.push("french");
        }
        if (germanLyrics) {
          languagesData.push("german");
        }
        const dataToSend: PreferredGenresToUpdate = {
          genres: genresData,
          languages: languagesData,
        };
        await updateGenre(dataToSend);
      } catch (error) {
        console.log(error);
      }
    }
  }

  const [t] = useTranslation();

  const [selected, setSelected] = useState<(Genres | ToggleLabels)[]>([]);
  const [selectedWithoutToggle, setSelectedWithoutToggle] = useState<Genres[]>(
    []
  );
  const [smartGenres, setSmartGenres] = useState<boolean>(false);
  const [frenchLyrics, setFrenchLyrics] = useState<boolean>(false);
  const [germanLyrics, setGermanLyrics] = useState<boolean>(false);
  // const [ spaPortLyrics, setSpaPortLyrics ] = useState<boolean>(spanishPortugueseLyricsInitial);
  const [christmasTitles, setChristmasTitles] = useState<boolean>(false);
  const [explicitTitles, setExplicitTitles] = useState<boolean>(false);

  const queryClient = useQueryClient();
  const { data } = useQuery("/userinfo", loadUserProfile);
  const { mutateAsync: updateGenre, isLoading } = useMutation(
    updatePreferredGenres,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("/userinfo");
        notification.open({
          message: "",
          description: t("Notifications.requestSuccess"),
        });
      },
      onError: () => {
        notification.open({
          message: "",
          description: t("Notifications.requestFailed"),
        });
      },
    }
  );

  useEffect(() => {
    function isSmartGenre(tab: string[]): boolean {
      let isPop: boolean = tab.includes("pop");
      let isDance: boolean = tab.includes("dancehall");
      let isLounge: boolean = tab.includes("lounge");

      return isPop && isDance && isLounge;
    }

    if (data?.genres) {
      setSelected(data.genres);
      setSmartGenres(isSmartGenre(data.genres));
      setChristmasTitles(data.genres.includes("christmas"));
      setExplicitTitles(data.genres.includes("explicit"));
    }
    if (data?.languages) {
      setFrenchLyrics(data.languages.includes("french"));
      setGermanLyrics(data.languages.includes("german"));
    }
  }, [data]);

  // We Remove the toggle labels from genre selection for counting the right amount of genre
  useEffect(() => {
    // @ts-ignore
    const selectedWT: Genres[] = selected.filter(
      (g): g is Genres => !toggleLabels.includes(g)
    );
    setSelectedWithoutToggle(selectedWT);
  }, [selected]);

  return (
    <Container>
      <Header
        active={
          smartGenres || selectedWithoutToggle.length >= minSelectionGenres
        }
        title={t("Settings.advancedSettings")}
        handlePressSave={handleSave}
        inProgress={isLoading}
      />
      <AdvancedOptions
        selected={selected}
        setSelected={setSelected}
        smartGenres={smartGenres}
        setSmartGenres={setSmartGenres}
        frenchLyrics={frenchLyrics}
        setFrenchLyrics={setFrenchLyrics}
        christmasTitles={christmasTitles}
        setChristmasTitles={setChristmasTitles}
        explicitTitles={explicitTitles}
        setExplicitTitles={setExplicitTitles}
        germanLyrics={germanLyrics}
        setGermanLyrics={setGermanLyrics}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 20px 0px 0px;
  padding-right: 20px;
`;

export default SettingsAdvanced;
