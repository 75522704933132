import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { notification } from 'antd';

import { BusinessType, BusinessTypeToUpdate } from '../../services/SoundSuitServiceTypes';
import { loadUserProfile, updateBusinessType, saveSettingsForStations } from '../../services/SoundSuitService';
// import components
import TypeOptions from '../../components/organisms/TypeOptions';
import Header from '../../components/molecules/Header';

interface Props {};

const SettingsType: React.FC<Props> = props => {

  async function handleSave(): Promise<void> {
    try {
      if(selected !== undefined) {
        const dataToSend: BusinessTypeToUpdate = {
          business_type: selected
        };
        await updateType(dataToSend);
        await updateSettingsForStations({
          business_type: selected,
          genres: data?.genres,
          styles: data?.styles,
          customer_age: data?.customer_age,
          languages: data?.languages,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  const queryClient = useQueryClient();
  const { data } = useQuery('/userinfo', loadUserProfile);
  const { mutateAsync: updateType, isLoading } = useMutation(updateBusinessType, {
    onSuccess: () => {
      queryClient.invalidateQueries('/userinfo');
      notification.open({
        message: '',
        description: t('Notifications.requestSuccess'),
      });
    },
    onError: () => {
      notification.open({
        message: '',
        description: t('Notifications.requestFailed'),
      });
      setSelected(data?.business_type as BusinessType);
    }
  });
  const {mutateAsync: updateSettingsForStations} = useMutation(saveSettingsForStations);

  const [ t ] = useTranslation();
  const [ selected, setSelected ] = useState<BusinessType>('' as BusinessType);

  useEffect(() => {
    if(data?.business_type) {
      setSelected(data.business_type as BusinessType);
    }
  }, [data])

  return (
    <Container>
      <Header
        title={t('Account.businessInfo')}
        handlePressSave={handleSave}
        inProgress={isLoading}
      />
      <TypeOptions
        selected={selected}
        setSelected={setSelected}
      />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 20px 0px 0px;
  padding-right: 20px;
`;

export default SettingsType;