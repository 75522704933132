import React, { useRef } from 'react';
import styled from 'styled-components';

import { BusinessType } from '../../services/SoundSuitServiceTypes';
import { idToLabel, IdToLabel} from '../../config/hardData';
// import components
import CardOption from '../molecules/CardOption';

interface Option {
  id: BusinessType;
  image: any;
}

const options: Option[] = [
 {id: "design_store", image: require('../../assets/images/designStore.jpg')},
 {id: "bar", image: require('../../assets/images/bar.jpg')},
 {id: "event", image: require('../../assets/images/event.jpg')},
 {id: "hotel", image: require('../../assets/images/hotelSLobby.jpg')},
 {id: "workplace", image: require('../../assets/images/workplace.jpg')},
 {id: "medical_practice", image: require('../../assets/images/medicalPractice.jpg')},
 {id: "restaurant", image: require('../../assets/images/restaurant.jpg')},
 {id: "wellness_spa", image: require('../../assets/images/wellnessSpa.jpg')},
 {id: "cafe", image: require('../../assets/images/coffeeShop.jpg')},
 {id: "fashion_boutique", image: require('../../assets/images/fashionStore.jpg')},
 {id: "fintess_studio", image: require('../../assets/images/gym.jpg')},
 {id: "hair_salon", image: require('../../assets/images/beautySalon.jpg')},
];

interface Props {
  selected: string;
  setSelected: React.Dispatch<React.SetStateAction<BusinessType>>;
};

const TypeOptions: React.FC<Props> = props => {

  const {
    selected,
    setSelected
  } = props;

  const currentIdToLabel: IdToLabel = useRef(idToLabel()).current;

  return (
    <WrapperOptions>
      {options.map(({id, image}) => (
        <CardOption
          key={id}
          label={currentIdToLabel[id]}
          image={image}
          selected={selected === id}
          handlePress={() => setSelected(id)}
        />
      ))}
    </WrapperOptions>
  );
}

const WrapperOptions = styled.div`
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

export default TypeOptions;