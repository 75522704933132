import i18n from '../utils/i18n';
import { Mood, MoodLabel, Genres, WeeklyProgram, Days } from '../services/SoundSuitServiceTypes';

export const URL_REFERER_BUSINESS= 'https://soundsuit.fm?fpr=';
export const URL_DOWNLOAD_APP = 'https://soundsuit.fm/download/';
export const URL_SUBSCRIPTION = `https://soundsuit.fm/pricing?utm_source=electron`;

export interface IdToLabel {
  wellness_spa: string; 
  restaurant: string; 
  hotel: string; 
  cafe: string; 
  design_store: string;
  hair_salon: string; 
  fashion_boutique: string; 
  bar: string; 
  event: string;
  workplace: string;
  medical_practice: string; 
  fintess_studio: string; // gym
  pop: string;
  hit: string;
  rock: string;
  disco: string;
  christmas: string;
  jazz: string;
  soul: string;
  acoustic: string; 
  'hip-hop': string; 
  'dance': string; 
  dancehall: string; 
  lounge: string; 
  'r&b': string; 
  folk: string; 
  chillout: string; 
  smartgenres: string; 
  party: string; 
  exciting: string;
  happy: string; 
  loungy: string;
  chill: string;
  calm: string; 
  smartmood: string;
  mainstream: string; 
  trendy: string; 
  alternative: string; 
  woman: string;
  man: string; 
  manWoman: string;
  nature: string;
  fitness: string;
  world: string;
  reggae: string;
  'jazz-pop': string;
  electronic: string;
  'liked_songs': string;
  'bossa-nova': string;
  'workout-pop': string;
  'workout-rock': string;
  'workout-hip-hop': string;
  // GOING TO BE DEPRECIETED
  'nature sound': string;
  country: string;
  'dance&electronic': string;
  'dance & electronic': string;
  'world-music': string;
  'jazz&soul': string;
  'jazz-soul': string;
}

export const idToLabel = (): IdToLabel => ({
  'design_store': i18n.t('Settings.design_store'), 
  'bar': i18n.t('Settings.bar'),
  'event': i18n.t('Settings.event'),
  'hotel': i18n.t('Settings.hotel'),
  'workplace': i18n.t('Settings.workplace'),
  'medical_practice': i18n.t('Settings.medical_practice'),
  'restaurant': i18n.t('Settings.restaurant'),
  'wellness_spa': i18n.t('Settings.wellness_spa'),
  'cafe': i18n.t('Settings.cafe'),
  'christmas': i18n.t('Settings.christmas'),
  'fashion_boutique': i18n.t('Settings.fashion_boutique'),
  'fintess_studio': i18n.t('Settings.gym'),
  'hair_salon': i18n.t('Settings.hair_salon'),
  'pop': i18n.t('Settings.pop'),
  'hit': i18n.t('Settings.hit'),
  'rock': i18n.t('Settings.rock'),
  'disco': i18n.t('Settings.disco'),
  'jazz': i18n.t('Settings.jazz'),
  'soul': i18n.t('Settings.soul'),
  'acoustic': i18n.t('Settings.acoustic'),
  'hip-hop': i18n.t('Settings.hiphop'),
  'dance': i18n.t('Settings.dance'),
  'dancehall': i18n.t('Settings.danceHall'),
  'lounge': i18n.t('Settings.lounge'),
  'r&b': i18n.t('Settings.rnb'),
  'folk': i18n.t('Settings.folk'),
  'chillout': i18n.t('Settings.chillout'),
  'smartgenres': i18n.t('Settings.smartGenreLabel'),
  'party': i18n.t('Settings.party'),
  'exciting': i18n.t('Settings.exciting'),
  'happy': i18n.t('Settings.happy'),
  'loungy': i18n.t('Settings.loungy'),
  'chill': i18n.t('Settings.chill'),
  'calm': i18n.t('Settings.calm'),
  'smartmood': i18n.t('Settings.smartMood'),
  'mainstream': i18n.t('Settings.mainstream'),
  'trendy': i18n.t('Settings.trendy'),
  'alternative': i18n.t('Settings.alternative'),
  'woman':i18n.t('Settings.woman'),
  'man': i18n.t('Settings.man'),
  'manWoman': i18n.t('Settings.manWoman'),
  'nature': i18n.t('Settings.nature'),
  'fitness': i18n.t('Settings.fitness'),
  'world': i18n.t('Settings.world'),
  'reggae': i18n.t('Settings.reggae'),
  'jazz-pop': i18n.t('Settings.jazzPop'),
  'electronic': i18n.t('Settings.electronic'),
  'liked_songs': i18n.t('Settings.likedSongs'),
  'bossa-nova': i18n.t('Settings.bossaNova'),
  'workout-pop': i18n.t('Settings.workoutPop'),
  'workout-rock': i18n.t('Settings.workoutRock'),
  'workout-hip-hop': i18n.t('Settings.workoutHipHop'),
  // GOING TO BE DEPRECIETED
  'nature sound': i18n.t('Settings.nature'),
  'country': i18n.t('Settings.folk'),
  'dance&electronic': i18n.t('Settings.dance'),
  'dance & electronic': i18n.t('Settings.dance'),
  'world-music': i18n.t('Settings.world'),
  'jazz&soul': i18n.t('Settings.soul'),
  'jazz-soul': i18n.t('Settings.soul'),
});

export const moodSettings: Mood = {
  party: i18n.t('Settings.rangeParty'),
  exciting: i18n.t('Settings.rangeExciting'),
  happy: i18n.t('Settings.rangeHappy'),
  loungy: i18n.t('Settings.rangeLoungy'),
  chill: i18n.t('Settings.rangeChill'),
  calm: i18n.t('Settings.rangeCalm'),
  smartmood: i18n.t('Settings.rangeSmartMood')
};

interface ReverseMoodSettings {
  [key: string]: MoodLabel;
}
export const reverseMoodSettings: ReverseMoodSettings =  {
  [i18n.t('Settings.rangeParty')]: 'party',
  [i18n.t('Settings.rangeExciting')]: 'exciting',
  [i18n.t('Settings.rangeHappy')]: 'happy',
  [i18n.t('Settings.rangeLoungy')]: 'loungy',
  [i18n.t('Settings.rangeChill')]: 'chill',
  [i18n.t('Settings.rangeCalm')]: 'calm',
  [i18n.t('Settings.rangeSmartMood')]: 'smartmood',
};

interface ReverseDayTranslate {
  [key: string]: Days;
};
export const reverseDayTranslate = (): ReverseDayTranslate => ({
  [i18n.t('Schedule.monday')]: 'monday',
  [i18n.t('Schedule.tuesday')]: 'tuesday',
  [i18n.t('Schedule.wednesday')]: 'wednesday',
  [i18n.t('Schedule.thursday')]: 'thursday',
  [i18n.t('Schedule.friday')]: 'friday',
  [i18n.t('Schedule.saturday')]: 'saturday',
  [i18n.t('Schedule.sunday')]: 'sunday',
});

export interface ListMood {
  label: MoodLabel;
  img: any;
};

export const listMoods: ListMood[] = [
  { label: 'party', img: require('../assets/images/party.png')},
  { label: 'exciting', img: require('../assets/images/exciting.png')},
  { label: 'happy', img: require('../assets/images/happy.png')},
  { label: 'loungy', img: require('../assets/images/loungy.png')},
  { label: 'chill', img: require('../assets/images/chill.png')},
  { label: 'calm', img: require('../assets/images/calm.png')},
  { label: 'smartmood', img: require('../assets/images/smartmood.png')}
];

export interface ListGenres {
  label: Genres;
  img: any;
}

export const listGenres: ListGenres[] = [
  {label: "pop", img: require('../assets/images/pop.jpg')},
  {label: "rock", img: require('../assets/images/rock.jpg')},
  {label: "hit", img: require('../assets/images/hits.jpg')},
  {label: "jazz", img: require('../assets/images/jazz.jpg')},
  {label: "soul", img: require('../assets/images/soul.jpg')},
  {label: "acoustic", img: require('../assets/images/acoustic.jpg')},
  {label: "hip-hop", img: require('../assets/images/hipHop.jpg')},
  {label: "disco", img: require('../assets/images/disco.jpg')},
  {label: "dance", img: require('../assets/images/dance.jpg')},
  {label: "lounge", img: require('../assets/images/lounge.jpg')},
  {label: "r&b", img: require('../assets/images/rB.jpg')},
  {label: "dancehall", img: require('../assets/images/dancehall.jpg')},
  {label: "chillout", img: require('../assets/images/chillout.jpg')},
  {label: "folk", img: require('../assets/images/country.jpg')},
  {label: "christmas", img: require('../assets/images/christmas.jpg')},
  {label: "nature", img: require('../assets/images/nature.jpg')},
  {label: "fitness", img: require('../assets/images/fitness.jpg')},
  {label: "world", img: require('../assets/images/world.jpg')},
  {label: "reggae", img: require('../assets/images/reggae.jpg')},
  {label: "jazz-pop", img: require('../assets/images/jazz-pop.jpg')},
  {label: "electronic", img: require('../assets/images/electronic.jpg')},
  {label: 'liked_songs', img: require('../assets/images/loved.jpg')},
  {label: 'bossa-nova', img: require('../assets/images/bossa-nova.jpg')},
  {label: 'workout-pop', img: require('../assets/images/workout-pop.jpg')},
  {label: 'workout-rock', img: require('../assets/images/workout-rock.jpg')},
  {label: 'workout-hip-hop', img: require('../assets/images/workout-hiphop.jpg')},
  // GOING TO BE DEPRECIETED
  // {label: "nature sound", img: require('../assets/images/nature.jpg')},
  // {label: "country", img: require('../assets/images/country.jpg')},
  // {label: "dance & electronic", img: require('../assets/images/dance.jpg')},
  // {label: "dance&electronic", img: require('../assets/images/dance.jpg')},
  // {label: "world-music", img: require('../assets/images/world.jpg')},
  // {label: "jazz&soul", img: require('../assets/images/jazz.jpg')},
  // {label: "jazz&soul", img: require('../assets/images/jazz.jpg')},
 ];

 export const defaultWeeklyProgram: WeeklyProgram = {
  monday: [],
  tuesday: [],
  wednesday: [],
  thursday: [],
  friday: [],
  saturday: [],
  sunday: []
};

export const startHourSchedule: number = 0;
export const nbHourDaySchedule: number = 24;
export const heightTrancheSchedule: number = 60;
export const heightLineTimeSchedule: number = 20;

export const heightTrancheScheduleTablet: number = 100;
export const widthTrancheScheduleTablet: number = 150;
export const leftMarginVerticalScheduleTablet: number = 80;
export const widthTotalCalendarTablet: number = leftMarginVerticalScheduleTablet + 150 * 7;
export const compensationPaddingTopTablet: number = 80;
export const compensationPaddingTopMobile: number = 30;

export const leftMarginDays = {
  monday: leftMarginVerticalScheduleTablet,
  tuesday: leftMarginVerticalScheduleTablet + widthTrancheScheduleTablet,
  wednesday: leftMarginVerticalScheduleTablet + widthTrancheScheduleTablet * 2,
  thursday: leftMarginVerticalScheduleTablet + widthTrancheScheduleTablet * 3,
  friday: leftMarginVerticalScheduleTablet + widthTrancheScheduleTablet * 4,
  saturday: leftMarginVerticalScheduleTablet + widthTrancheScheduleTablet * 5,
  sunday: leftMarginVerticalScheduleTablet + widthTrancheScheduleTablet * 6
};