import React from 'react';
import styled from 'styled-components';
import { Avatar as AvatarAntd } from 'antd';
import { UserOutlined } from '@ant-design/icons';

// components


interface Props {};

const Avatar: React.FC<Props> = props => {

  return (
    <Container>
      <AvatarAntd
        icon={<UserOutlined />}
        size={86}
      />
    </Container>
  );
}

const Container = styled.div`
  position: relative;
`;

export default Avatar;