export const redLightBG = 'rgb(255, 99, 89)';
export const redBG = 'rgb(239, 61, 76)';
export const redBGOpacity = 'rgba(239, 61, 76, 0.5)';
export const white = 'white';
export const black = 'black';
export const grey = 'grey';
export const green = '#4BA613';
export const lightgreen = '#E6F2DE';
export const lightRedText = '#F6B0AF';
export const redText = '#ED6D60';
export const orangeText = '#ED9C5C';
export const buttonLightRed = '#E37073';
export const buttonRed = '#ED6D60';
export const strongRed = '#E42925';
export const lightgray = '#dbdce5';
export const buttonPurple = '#9B8ACE';
export const buttonGreen = '#A4C399';
export const buttonBlue = '#ACCCF4';
export const veryLightGrey = '#D9DAE4';
export const veryLightLightGrey = '#E8E9EA';
export const veryVeryLightGrey = '#F7F7F9';
export const almostWhite = '#FDFDFD';
export const shinyRed = '#FF5851';
export const lightBeige = '#272932';
export const almostBlack = '#666666';
export const skyBlue = '#1E90FF';