import './App.css';
import React, { useEffect } from 'react';
import { createGlobalStyle } from 'styled-components';
import { setLogger, QueryClientProvider } from 'react-query';
import {
  BrowserRouter,
  MemoryRouter
} from "react-router-dom";
import { notification } from 'antd';

// import fontAvenirNormal from './assets/fonts/Avenir-normal.otf';
import fontAvenir from './assets/fonts/Avenir-black.otf';
import fontAvenirMedium from './assets/fonts/Avenir-medium.otf';
import { queryClient } from './store/reactQueryInitial';
import {
  useApp,
  usePlayer,
  useUser
} from './store';
import { TokenInfos } from './store/modelApp';
import { getTokenInfos } from './utils/functions';
import { refreshToken } from './services/SoundSuitService';
import { PlayerState } from './store/modelPlayer';
import { UserState } from './store/modelUser';
import storage from './utils/storage';
import i18n from './utils/i18n';
// import components
import MainLayout from './layout/MainLayout';
import SplashScreen from './components/organisms/SplashScreen';

notification.config({
  placement: 'bottomLeft',
  bottom: 50,
  duration: 3,
});

// React-Query Settings
setLogger({
  log: console.log,
  warn: console.warn,
  error: console.warn
});

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'AvenirBlack';
    src: url(${fontAvenir});
  }
  @font-face {
    font-family: 'AvenirMedium';
    src: url(${fontAvenirMedium});
  }
  body {
    margin: 0;
    padding:0;
  }
`;

function App() {

  const setIsReady = useApp.getState().reducers.setIsReady;
  const isReady = useApp(appState => appState.state.isReady);
  const initialized = useApp(appState => appState.state.initialized);

  // We initialize the app
  useEffect(() => {
    const initializedApp = useApp.getState().effects.initializedApp;
    initializedApp();
  }, []);


  // We fetch for persist state once app initialized
  useEffect(() => {

    const initializeForWeb = () => new Promise<any>(async (resolve, reject) => {
      try {
        const stringifiedUserState: string | null = await storage.getItem('userState');
        const stringifiedPlayerState: string | null = await storage.getItem('playerState');
  
        if (stringifiedUserState !== null) {
          const userState: UserState = JSON.parse(stringifiedUserState);
          initializedUserState(userState);
          i18n.changeLanguage(userState.language);
        }
  
        if (stringifiedPlayerState !== null) {
          const playerState: PlayerState = JSON.parse(stringifiedPlayerState);
          initializedPlayerState(playerState);
        }
        resolve({success: true});
      } catch (error) {
        reject({success: false, error});
      }

    });

    async function start() {
      if (initialized === 'TOKEN_VALID') {
        if (process.env.REACT_APP_PLATFORM === 'web') {
          await initializeForWeb();
        } else if (process.env.REACT_APP_PLATFORM === 'electron') {
          const { myIpcRenderer } = window;
          const initialStates = await myIpcRenderer.invoke('APP_INITIAL_STATE');
          if (initialStates) {
            initializedUserState(initialStates.initialUser);
            initializedPlayerState(initialStates.initialPlayer);
            i18n.changeLanguage(initialStates.initialUser.language);
          }
        }
        setIsReady(true);
      } else if (initialized === 'NO_TOKEN') {
        setIsReady(true);
      }
    }

    let unsubInitialData;
    const initializedUserState = useUser.getState().reducers.initialize;
    const initializedPlayerState = usePlayer.getState().reducers.initialize;

    start();

    return () => {
      if (unsubInitialData) {
        unsubInitialData();
      }
    }
  }, [initialized, setIsReady]);

  // Persist the state User and Player
  useEffect(() => {

    function handlePersist(state: UserState | PlayerState, nameStorage: 'userState' | 'playerState'): void {
      if (isReady) {
        storage.persistState(state, nameStorage);
      }
    }

    const unsubUserState = useUser.subscribe(
      userState => userState.state,
      (state) => handlePersist(state as UserState, 'userState'),
    );
    const unsubPlayerState = usePlayer.subscribe(
      playerState => playerState.state,
      (state) => handlePersist(state as PlayerState, 'playerState'),
    );

    return () => {
      unsubUserState();
      unsubPlayerState();
    };
  }, [isReady]);

  // we automatically refresh the token every hours
  useEffect(() => {
    function refreshTokenRoutine() {
      const routine = setTimeout(async() => {
        const tokenInfos: TokenInfos | null = await getTokenInfos();
        if(tokenInfos) {
          const tokenExpireDate = new Date(tokenInfos.expiresAt);
          const currentDate = new Date();
          const timeLeftToLive: number = ((tokenExpireDate.getTime() - currentDate.getTime())/1000)/60; // Time left to live in minutes
          // We check the token life every 5min (300000ms), if he has less than 15min to live, we refresh it.
          // If one refesh failed, there is at least one try that will follow.
          if (timeLeftToLive <= 15) {
            try {
              const resRefresh = await refreshToken();
              if (resRefresh.success) {
                console.log('token refresh success');
              } else {
                console.log('refresh token failed');
              }
            } catch (error) {
              console.error(error);
            }
          }
        }
        clearTimeout(routine);
        refreshTokenRoutine();
      }, 300000);
    }

    if(isReady) {
      refreshTokenRoutine();
    }
  }, [isReady]);

  if(!isReady || initialized === 'NOT_CHECKED') {
    return (
      <SplashScreen />
    );
  }

  const Router = process.env.REACT_APP_PLATFORM === 'electron' ? MemoryRouter : BrowserRouter;

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Router>
            <GlobalStyle />
            <MainLayout />
        </Router>
      </QueryClientProvider>
    </>
  );
}

export default App;
