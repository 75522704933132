import React from 'react';
import styled from 'styled-components';

import { Track } from '../../services/SoundSuitServiceTypes';
// import components

interface Props {
  track: Track;
  isLargeScreen: boolean;
};

const MainTrackCard: React.FC<Props> = ({track, isLargeScreen}) => {

  const artwork_url = decodeURIComponent(decodeURIComponent(track.artwork as string));

  return (
    <Container isLarge={isLargeScreen}>
      <Image
        src={artwork_url}
        alt={track.title}
        width={isLargeScreen ? "100%":200}
        height={isLargeScreen ? "100%":200}
      />
    </Container>
  );
}

const Container = styled.div<{isLarge: boolean}>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({isLarge}) => isLarge ? '40%':'200px'};
  height: ${({isLarge}) => isLarge ? 'auto':'200px'};
  border-radius: ${({isLarge}) => isLarge ? '10%':'10px'};
  margin-bottom: ${({isLarge}) => isLarge ? '0':'20px'};
  overflow: hidden;
  z-index: 0;
`;
const Image = styled.img`
`;

export default MainTrackCard;