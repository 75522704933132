import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CheckOutlined } from "@ant-design/icons";

import iconSmartMood from '../../assets/images/smartmood.png';

import { grey, buttonRed, black, redText, orangeText } from '../../config/colors';
import { moodSettings, listMoods } from '../../config/hardData';
import { MoodLabel } from '../../services/SoundSuitServiceTypes';
import { usePlayer } from '../../store';
// import components
import Text from '../atoms/Text';

interface Props {
  mood: (MoodLabel | undefined)[];
  setMood: React.Dispatch<React.SetStateAction<(MoodLabel | undefined)[]>>;
};

const MoodOptionsSchedule: React.FC<Props> = props => {

  function handleMultipleSelection(index: number): void {
    setPlayWithSchedule(false);
    const curSel: number = listMoods.findIndex(m => m.label === mood[0] as MoodLabel);
    if ( [curSel + 1, curSel - 1].includes(index) && mood.length < 2 && mood[0] !== 'smartmood' ) {
      if (mood.includes(listMoods[index].label)) {
        // We remove the already selected index
        const newAr = mood.filter(m => m !== listMoods[index].label);
        setMood(newAr);
      } else {
        // Multiple Selections here
        const newAr = mood.slice();
        newAr.push(listMoods[index].label);
        setMood(newAr);
      }
    } else {
      // Selection outside of range: new selection
      setMood([listMoods[index].label]);
    }
  }

  function getColorText(index: number, sub: boolean = false): string {
    if (playWithSchedule) {
      return black;
    }
    if (mood.includes(listMoods[index].label)) {
      return redText;
    }
    if (mood.length < 2) {
      const curSel: number = listMoods.findIndex(m => m.label === mood[0] as MoodLabel);
      if(curSel !== -1 && [curSel + 1, curSel - 1].includes(index)) {
        return orangeText;
      }
    }
    if (sub) {
      return grey;
    }
    return black;
  }

  const [ t ] = useTranslation();

  const playWithSchedule = usePlayer(statePlayer => statePlayer.state.playWithSchedule);
  const setPlayWithSchedule = usePlayer.getState().reducers.setPlayWithSchedule;

  const {
    mood,
    setMood
  } = props;

  return (
    <WrapperOptions>
      <RowCenter>
        {listMoods.map((m, i) => {

          if (m.label === 'smartmood') {
            return null;
          }

          const originalLabel = t(`Settings.${m.label}`);
          const nameMood = originalLabel.replace(/\(.*\)/, "");
          const rangeMood = originalLabel.replace(/^.*\(/gmi, "").replace(/\)/, "");

          return (
            <Line key={`${m.label}-${i}`} top={i !== 0} bottom={true} onClick={() => handleMultipleSelection(i)} >
              <Icon src={m.img} />
              <Column>
                <Text size="xsmall" style={{ color: getColorText(i)}}>
                  {nameMood}
                </Text>
                <Text size="small" style={{ color: getColorText(i, true)}}>
                  {rangeMood}
                </Text>
              </Column>
              {!playWithSchedule && mood.includes(m.label) && <WrapperCheck><CheckOutlined style={{ color: buttonRed }} /></WrapperCheck>}
            </Line>
          );
        }
        )}
        <Line top={false} bottom={false} onClick={() => setMood(['smartmood'])} >
          <Row>
            <Icon src={iconSmartMood} />
            <Text style={{paddingTop: 6, marginLeft: 15}} color={mood.includes('smartmood') ? buttonRed: black} size="xsmall" >{t('Settings.smartMood')}</Text>
            {!playWithSchedule &&  mood.includes('smartmood') && <WrapperCheck><CheckOutlined style={{ color: buttonRed }} /></WrapperCheck>}
          </Row>
        </Line>
      </RowCenter>
    </WrapperOptions>
  );
}

const WrapperOptions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Row = styled.div`
  width: 100%;
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;
const Column = styled.div`
  width: 100%;
  height: 100%;
  margin-left: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
`;
const RowCenter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
const Line = styled.div<{top: boolean, bottom?: boolean}>`
  height: 70px;
  width: 100%;
  flex-direction: row;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  border-top: solid  ${({top}) => top ? '1px': '0px'} rgba(239, 240, 245, .4);
  border-bottom: solid ${({bottom}) => bottom ? '1px' : '0px'} rgba(239, 240, 245, .4);
  cursor: pointer;
`;
const Icon = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 5px;
`;
const WrapperCheck = styled.div`
  margin-left: auto;
`;

export default MoodOptionsSchedule;