import {useEffect, useState, useRef} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import {PlayCircleFilled} from '@ant-design/icons';

import { veryVeryLightGrey, grey, redLightBG } from '../../config/colors';
import { usePlayer } from '../../store';
import { TypeMedia } from '../../services/SoundSuitServiceTypes';
import { listGenres, idToLabel, IdToLabel} from '../../config/hardData';
import IconImg from "../../assets/icons/poste.png";
// import components
import Text from '../atoms/Text';
import MultipleImagesThumbnail from '../atoms/MultipleImagesThumbnail';

type Props = {
  type: TypeMedia;
  media: any;
  closeModal: () => void;
  isSelected: boolean;
};

const MediaRow = (props: Props) => {

  async function handleSelection() {
    try {
      await setMediaToPlay({id: media.id, type});
      toggleRefreshTracks();
      closeModal()
    } catch (error) {
      console.log("Error choosing media: ", error);
      notification.open({
        message: '',
        description: t('Notifications.requestFailed'),
      });
    }
  }

  const {
    media,
    type,
    closeModal,
    isSelected
  } = props;

  const [ t ] = useTranslation();

  const setMediaToPlay = usePlayer.getState().effects.setMediaToPlay;
  const toggleRefreshTracks = usePlayer.getState().reducers.toggleRefreshTracks;
  const currentIdToLabel: IdToLabel = useRef(idToLabel()).current;

  const [images, setImages] = useState<string[]>([]);
  const [tags, setTags] = useState<string[]>([]);

  useEffect(() => {
    if (media && Array.isArray(media.genres)) {
      const imgs = media.genres.map((genre: string) => listGenres.find(lg => lg.label === genre)?.img); 
      const tgs = (media.genres as string[]).reduce<string[]>((acc, g, i) => {
        if (i === 3) {
          acc.push('...');
        } else if (i > 3) {
          return acc;
        } else {
          acc.push(g);
        }
        return acc;
      }, []);
      setImages(imgs);
      setTags(tgs);
    }
  }, [media]);

  return (
    <Container>
      <WrapperImage>
        {images.length > 0 && <MultipleImagesThumbnail imgs={images}  />}
      </WrapperImage>
      <Column>
      {isSelected ? (
          <WrapperEqualizer>
            <Equalizer
              src={require('../../assets/icons/equalizer_white.gif')}
            />
          </WrapperEqualizer>
        ):(
          <WrapperButton onClick={handleSelection}>
          <PlayCircleFilled style={{ fontSize: "32px" }} />
          </WrapperButton>
        )}
        <WrapperTitle>
          <Icon src={IconImg} alt="poste" />
          <Text bold>{media.name || ''}</Text>
        </WrapperTitle>
        <WrapperDescription>
          <Text
            color={grey}
            size="xsmall"
            numberOfLines={2}
            ellipsizeMode='tail'
          >
            {media.description || ''}
          </Text>
        </WrapperDescription>
        <WrapperTags>
          {/* @ts-ignore */}
          {tags.map((g: string, i: number) => <Tag key={`${i}-${g}`}><Text size="small" color={grey}>{g === '...' ? g : currentIdToLabel[g]}</Text></Tag>)}
        </WrapperTags>
      </Column>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 116px;
  background-color: ${veryVeryLightGrey};
  border-radius: 16px;
  margin-bottom: 10px;
  padding: 10px;
  z-index: 0;
`;

const WrapperButton = styled.button`
  position: absolute;
  right: 10px;
  top: 0px;
  width: 32px;
  height: 32px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 0;
  z-index: 50;
  cursor: pointer;
`;
const Column = styled.div`
  position: relative;
  flex: 2;
  height: 100%;
  padding-left: 10px;
`;
const WrapperDescription = styled.div`
  width: 100%;
  margin-bottom: 5px;
`;
const WrapperImage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const WrapperTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
  margin-bottom: 5px;
`;
const Icon = styled.img`
  width: 25px;
  height: 20px;
  margin-right: 5px;
`;
const WrapperTags = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  margin-top: auto;
`;
const Tag = styled.div`
  padding: 2px 3px 2px 3px;
  border: solid 1px ${grey};
  border-radius: 5px;
  margin-right: 3px;
`;
const WrapperEqualizer = styled.div`
  position: absolute;
  right: 10px;
  top: 0px;
  width: 32px;
  height: 32px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${redLightBG};
  z-index: 50;
`;
const Equalizer = styled.img`
  width: 20px;
  height: 20px;
`;

export default MediaRow;