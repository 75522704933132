import React from 'react';
import styled from 'styled-components';
import {
  Routes,
  Route
} from "react-router-dom";

// import components
import RequireAuth from '../components/atoms/RequireAuth';
import AppLayout from './AppLayout';
import WelcomeScreen from '../components/organisms/WelcomeScreen';
import SignScreen from '../components/organisms/SignScreen';
import SelectionZones from '../components/organisms/SelectionZones';
import ForgotPasswordScreen from '../components/organisms/ForgotPasswordScreen';
import ThankResetPassword from '../components/organisms/ThankResetPassword';


const MainLayout = () => {

  return (
    <Container>
      <Routes>
        <Route
          path="*"
          element={
            <RequireAuth>
              <AppLayout />
            </RequireAuth>
          }
        />
        <Route path="/welcome" >
          <Route index element={<WelcomeScreen />} />
          <Route path="signAccount/:type" element={<SignScreen />} />
          <Route path="forgotPassword" element={<ForgotPasswordScreen />}/>
          <Route path="resetPassword/:email" element={<ThankResetPassword />}/>
        </Route>
        <Route path="/selectionZones" element={<SelectionZones />} />
      </Routes>
    </Container>
  );
}

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow: hidden;
`;

export default MainLayout;