import React from 'react';
import styled from 'styled-components';

import {
  white,
  buttonRed,
  lightgray
} from '../../config/colors';
// import components
import Text from '../atoms/Text';

interface Props {
  active: boolean;
  handlePress: any;
  children: string;
  id: number | string;
  colorText?: string;
  small?: boolean;
  width?: number;
  inactiveColor?: string;
};

const FlatButton: React.FC<Props> = props => {

  function handlePressButton(): void {
    handlePress(id);
  }

  const {
    active,
    handlePress,
    children,
    id,
    colorText = lightgray,
    small = false,
    width = 200,
    inactiveColor = 'transparent'
  } = props;

  return (
    <Container
      active={active}
      style={{
        boxShadow: active ? "1px 5px 5px rgba(50, 50, 50, 0.1)" : undefined
      }}
      onClick={handlePressButton}
      small={small}
      width={width}
      inactiveColor={inactiveColor}
    >
      <Text
        size={small ? "small" : "xsmall"}
        color={active ? white : colorText }
        bold={small}
      >
        {children}
      </Text>
    </Container>
  );
}

const Container = styled.div<{active: boolean, small:boolean, width: number, inactiveColor: string}>`
  width: ${({width}) => `${width - 10}px`};
  height: 40px;
  border-radius: 8px;
  padding: ${({small}) => small ? "5px 10px 0 10px" : "5px 20px 0 20px"};
  margin: 0 10px 0 10px;
  background-color: ${({active, inactiveColor}) => active ? buttonRed : (inactiveColor ? inactiveColor : white) };
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export default FlatButton;