import React, { useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Slider } from 'antd';

import { Gender, Styles } from '../../services/SoundSuitServiceTypes';
import { buttonRed } from '../../config/colors';
import { idToLabel, IdToLabel} from '../../config/hardData';
// import components
import Text from '../atoms/Text';
import FlatButton from '../molecules/FlatButton';

interface Option {
  id: Gender | Styles;
  image: any;
}
interface Options {
  [key: string]: Option;
}

const options: Options = {
 woman: {id: "woman", image: require('../../assets/icons/woman.png')},
 man: {id: "man", image: require('../../assets/icons/man.png')},
 manWoman: {id: "manWoman", image: require('../../assets/icons/manWoman.png')},
 mainstream: {id: "mainstream", image: require('../../assets/icons/mainstream.png')},
 trendy: {id: "trendy", image: require('../../assets/icons/trendy.png')},
 alternative: {id: "alternative", image: require('../../assets/icons/alternative.png')}
};

interface Props {
  gender: Gender;
  setGender: React.Dispatch<React.SetStateAction<Gender>>;
  age: [number, number];
  setAge: React.Dispatch<React.SetStateAction<[number, number]>>;
  styles: Styles[];
  setStyles: (id: Styles) => void;
};

const TargetOptionsSchedule: React.FC<Props> = props => {

  const [ t ] = useTranslation();

  const currentIdToLabel: IdToLabel = useRef(idToLabel()).current;

  const {
    gender,
    setGender,
    age,
    setAge,
    styles,
    setStyles,
  } = props;

  return (
    <WrapperOptions>
      <Column>
        <Text size="xsmall" >{t('Settings.gender')}</Text>
        <Row>
          <FlatButton
            inactiveColor='white'
            active={options.woman.id === gender}
            handlePress={setGender}
            width={200}
            id={options.woman.id}
          >
            {currentIdToLabel[options.woman.id]}
          </FlatButton>
          <FlatButton
            inactiveColor='white'
            active={options.man.id === gender}
            handlePress={setGender}
            width={200}
            id={options.man.id}
          >
            {currentIdToLabel[options.man.id]}
          </FlatButton>
          <FlatButton
            inactiveColor='white'
            active={options.manWoman.id === gender}
            handlePress={setGender}
            width={200}
            id={options.manWoman.id}
          >
            {currentIdToLabel[options.manWoman.id]}
          </FlatButton>
        </Row>
      </Column>
      <Column>
        <Text size="xsmall" >{t('Settings.age')}</Text>
        <WrapperSlider>
          <Slider
            trackStyle={[{
              backgroundColor: buttonRed
            }]}
            handleStyle={[{
              backgroundColor: buttonRed,
              color: buttonRed,
              borderColor: buttonRed
            }]}
            range
            tooltipVisible
            min={0}
            max={50}
            step={null}
            marks={{
              0: '0',
              20: '20',
              30: '30',
              50: '50+'
            }}
            value={age}
            onChange={setAge}
          />
        </WrapperSlider>
      </Column>
      <Column>
        <Text size="xsmall" >{t('Settings.suitableStyles')}</Text>
        <Row>
          <FlatButton
            inactiveColor='white'
            active={styles.includes(options.mainstream.id as Styles)}
            handlePress={setStyles}
            width={200}
            id={options.mainstream.id}
          >
            {currentIdToLabel[options.mainstream.id]}
          </FlatButton>
          <FlatButton
            inactiveColor='white'
            active={styles.includes(options.trendy.id as Styles)}
            handlePress={setStyles}
            width={200}
            id={options.trendy.id}
          >
            {currentIdToLabel[options.trendy.id]}
          </FlatButton>
          <FlatButton
            inactiveColor='white'
            active={styles.includes(options.alternative.id as Styles)}
            handlePress={setStyles}
            width={200}
            id={options.alternative.id}
          >
            {currentIdToLabel[options.alternative.id]}
          </FlatButton>
        </Row>
      </Column>
    </WrapperOptions>
  );
}

const WrapperOptions = styled.div`
  width: 100%;
  padding-top: 40px;
  position: relative;
  z-index: 0;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Row = styled.div`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;
const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const WrapperSlider = styled.div`
  width: 80%;
  margin-top: 50px;
  margin-bottom: 30px;
`;
export default TargetOptionsSchedule;