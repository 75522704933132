import {State} from 'zustand';
import {
  Update,
  Callback
} from './';

// *** MiddleWare Logger
export function stateLogger(oldState: State, newState: State): void {
  const diff = Object.keys(oldState).filter(key => oldState[key] !== newState[key]);
  console.log('%c changing state: ', 'color: orange; font-weight: bold', diff.toString());
  console.log('%c before: ', 'color: lightblue', oldState);
  console.log('%c after: ', 'color: blue', newState);
}

// **** Type Update function ****

export const update: Update = (set, get) => (callback: Callback<any>) => {
  // @ts-ignore
  const oldState = get().state;
  const newState = callback(oldState);
  // if(process.env.NODE_ENV === 'development') {
  //   stateLogger(oldState, newState);
  // }
  set(model => ({
    ...model,
    state: newState,
  }));
};