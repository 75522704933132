"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const easeOutCubic = (t, b, c, d) => {
    t /= d;
    t--;
    return c * (t * t * t + 1) + b;
};
exports.setScrollAnimation = (root, currentPosition, changingValue) => {
    let start = 1;
    let isStop = false;
    const animation = () => {
        if (isStop)
            return;
        const offset = easeOutCubic(start / 100, currentPosition, changingValue, 0.1);
        requestAnimationFrame(animation);
        root.scrollTo(0, offset);
        const target = currentPosition + changingValue;
        start += 1;
        isStop = offset === target;
    };
    return animation;
};
const useScrollAnimation = (root, refs) => {
    const timer = react_1.useRef(null);
    const onScroll = react_1.useCallback((data, itemID, hasAnimation) => {
        if (timer.current) {
            clearTimeout(timer.current);
        }
        const firstID = data[0].id;
        const basicElm = refs[firstID].current;
        const currentElm = refs[itemID || firstID].current;
        const _root = root.current;
        if (_root && basicElm && currentElm) {
            timer.current = setTimeout(() => {
                const basicOffsetTop = basicElm.offsetTop;
                const targetOffsetTop = currentElm.offsetTop - basicOffsetTop;
                const animation = exports.setScrollAnimation(_root, _root.scrollTop, targetOffsetTop - _root.scrollTop);
                requestAnimationFrame(animation);
            }, hasAnimation ? 300 : 0);
        }
    }, [refs, root]);
    return onScroll;
};
exports.default = useScrollAnimation;
