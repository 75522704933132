import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { notification } from "antd";
import { CheckOutlined } from "@ant-design/icons";

import { useUser } from '../../store';
import { Language as LanguageType } from '../../store/modelUser';
import { buttonRed } from '../../config/colors';
import { loadUserProfile, updateLanguageProfile } from '../../services/SoundSuitService';
// import components
import Text from '../../components/atoms/Text';
import Header from '../../components/molecules/Header';


interface Props {};

const AccountLanguage: React.FC<Props> = props => {

  async function handleSave(): Promise<void> {
    await updateLanguage({language});
  }

  const { t, i18n } = useTranslation();
  const setLanguageGlobal = useUser.getState().reducers.setLanguage;

  const queryClient = useQueryClient();
  const { data } = useQuery('/userinfo', loadUserProfile);

  const [ language, setLanguage ] = useState<LanguageType>(data?.language as LanguageType || 'en');

  const { mutateAsync: updateLanguage, isLoading } = useMutation(updateLanguageProfile, {
    onSuccess: () => {
      queryClient.invalidateQueries('/userinfo');
      i18n.changeLanguage(language);
      setLanguageGlobal(language);
      notification.open({
        message: '',
        description: t('Notifications.requestSuccess'),
      });
    },
    onError: () => {
      notification.open({
        message: '',
        description: t('Notifications.requestFailed'),
      });
    }
  });

  useEffect(() => {
    if(data?.language) {
      setLanguage(data.language as LanguageType);
    }
  }, [data]);

  return (
    <Container>
      <Header
        title={t('Account.language')}
        handlePressSave={handleSave}
        inProgress={isLoading}
      />
      <WrapperOptions>
        <RowCenter>
          <Line top onClick={() => setLanguage('en')} >
            <Text style={{paddingTop: 6}} size="xsmall" >{t('Account.langEnglish')}</Text>
            {language === 'en' && <WrapperCheck><CheckOutlined style={{ color: buttonRed }} /></WrapperCheck>}
          </Line>
          <Line top={false} onClick={() => setLanguage('de')} >
            <Text style={{paddingTop: 6}} size="xsmall" >{t('Account.langGerman')}</Text>
            {language === 'de' && <WrapperCheck><CheckOutlined style={{ color: buttonRed }} /></WrapperCheck>}
          </Line>
          <Line top={false} onClick={() => setLanguage('fr')} >
            <Text style={{paddingTop: 6}} size="xsmall" >{t('Account.langFrench')}</Text>
            {language === 'fr' && <WrapperCheck><CheckOutlined style={{ color: buttonRed }} /></WrapperCheck>}
          </Line>
          <Line top={false} onClick={() => setLanguage('it')} >
            <Text style={{paddingTop: 6}} size="xsmall" >{t('Account.langItalian')}</Text>
            {language === 'it' && <WrapperCheck><CheckOutlined style={{ color: buttonRed }} /></WrapperCheck>}
          </Line>
          <Line top={false} onClick={() => setLanguage('es')} >
            <Text style={{paddingTop: 6}} size="xsmall" >{t('Account.langSpanish')}</Text>
            {language === 'es' && <WrapperCheck><CheckOutlined style={{ color: buttonRed }} /></WrapperCheck>}
          </Line>
        </RowCenter>
      </WrapperOptions>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 20px 0px 0px;
  padding-right: 20px;
`;
const WrapperOptions = styled.div`
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 40px;
`;
const RowCenter = styled.div`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
const Line = styled.div`
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
  border-top: ${({top}) => top ? 'solid 1px rgba(239, 240, 245, .6)': '0'};
  border-bottom: solid 1px rgba(239, 240, 245, .6);
  cursor: pointer;
`;
const WrapperCheck = styled.div`
  margin-left: auto;
  padding-right: 10px;
`;

export default AccountLanguage;