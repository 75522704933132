"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const styled_components_1 = __importStar(require("styled-components"));
const optionID_1 = require("../constants/optionID");
const Item = styled_components_1.default.li `
  position: relative;
  display: flex;
  align-items: center;
  justify-content: left;
  cursor: pointer;
  ${(props) => `
    min-height: ${props.height}px;
  `}
  &:focus {
    outline: none;
  }
`;
const fadeIn = styled_components_1.keyframes `
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const ICON_WIDTH = 20;
const Icon = styled_components_1.default.span `
  position: absolute;
  top: 0;
  bottom: 0;
  left: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${ICON_WIDTH}px;
  opacity: 0;
  animation: ${fadeIn} 200ms ease-in;
  animation-fill-mode: forwards;
  animation-delay: 200ms;
  ${(props) => `
    font-size: ${props.fontSize};
  `}
`;
const Text = styled_components_1.default.p `
  margin: 0;
  text-align: left;
  word-wrap: break-word;
  padding-left: 10px;
`;
const WheelPickerItem = ({ id, value, activeID, height, color, activeColor, fontSize, onClick, onFocus }, ref) => {
    const selected = react_1.useMemo(() => id === activeID, [id, activeID]);
    const textColor = react_1.useMemo(() => (selected ? activeColor : color), [
        activeColor,
        color,
        selected
    ]);
    const textStyle = react_1.useMemo(() => ({
        color: textColor,
        fontSize
    }), [fontSize, textColor]);
    return (react_1.default.createElement(Item, { role: "option", "aria-selected": selected, "aria-label": value.toString(), ref: ref, id: `${optionID_1.OPTION_ID}${id}`, "data-itemid": id, "data-itemvalue": value, height: height, onClick: onClick, onFocus: onFocus, tabIndex: 0 },
        selected && react_1.default.createElement(Icon, { fontSize: fontSize }, "\u2713"),
        react_1.default.createElement("span", { style: { width: ICON_WIDTH } }),
        react_1.default.createElement(Text, { style: textStyle }, value)));
};
exports.default = react_1.forwardRef(WheelPickerItem);
