import React, { useRef, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Switch } from 'antd';

import { Genres, ToggleLabels } from '../../services/SoundSuitServiceTypes';
import { idToLabel, IdToLabel, listGenres} from '../../config/hardData';
// import components
import CardOption from '../molecules/CardOption';
import Text from '../atoms/Text';

interface Props {
  selected: (Genres|ToggleLabels)[];
  setSelected: React.Dispatch<React.SetStateAction<(Genres|ToggleLabels)[]>>;
  smartGenres: boolean;
  setSmartGenres: React.Dispatch<React.SetStateAction<boolean>>;
  frenchLyrics: boolean;
  setFrenchLyrics: React.Dispatch<React.SetStateAction<boolean>>;
  germanLyrics: boolean;
  setGermanLyrics: React.Dispatch<React.SetStateAction<boolean>>;
  // spaPortLyrics: boolean;
  // setSpaPortLyrics: React.Dispatch<React.SetStateAction<boolean>>;
  explicitTitles: boolean;
  setExplicitTitles: React.Dispatch<React.SetStateAction<boolean>>;
  forOnBoarding?: boolean;
};

const GenreOptions: React.FC<Props> = props => {

  const {
    selected,
    setSelected,
    smartGenres,
    setSmartGenres,
    frenchLyrics,
    setFrenchLyrics,
    germanLyrics,
    setGermanLyrics,
    // spaPortLyrics,
    // setSpaPortLyrics,
    explicitTitles,
    setExplicitTitles,
    forOnBoarding = false,
  } = props;

  const [ t ] = useTranslation();

  const currentIdToLabel: IdToLabel = useRef(idToLabel()).current;
  const listGenresSorted = useMemo(() => listGenres.sort((a, b) =>  t(`Settings.${a.label}`).localeCompare(t(`Settings.${b.label}`))), [listGenres]);

  return (
    <WrapperOptions>
      <WrapperCard>
        {listGenresSorted.sort((a, b) => {
          const aLabel = currentIdToLabel[a.label];
          const bLabel = currentIdToLabel[b.label];

          return aLabel.localeCompare(bLabel);
        }).map(({label, img}) => (
          <CardOption
            key={label}
            label={currentIdToLabel[label]}
            image={img}
            selected={selected.includes(label) && !smartGenres}
            handlePress={() => {
              if(smartGenres) {
                setSmartGenres(false);
                setSelected([label]);
              } else {
                if (selected.includes(label)) {
                  const newTab: (Genres | ToggleLabels)[] = selected.filter(e => e !== label);
                  setSelected(newTab);
                } else {
                  const newTab = selected.slice();
                  newTab.push(label);
                  setSelected(newTab);
                }
              }
            }}
          />
        ))}
      </WrapperCard>
      {!forOnBoarding && (
        <WrapperForm>
          <Line topBorder>
            <Text size='normal' >{t('Settings.includeFrench')}</Text>
            <Switch
              onChange={() => setFrenchLyrics(h=>!h)}
              checked={frenchLyrics}
            />
          </Line>
          {/* <Line topBorder>
            <Text size='normal' >{t('Settings.includeExplicit')}</Text>
            <Switch
              onChange={() => setExplicitTitles(h=>!h)}
              checked={explicitTitles}
            />
          </Line> */}
          <Line topBorder>
            <Text size='normal' >{t('Settings.includeGerman')}</Text>
            <Switch
              onChange={() => setGermanLyrics(h=>!h)}
              checked={germanLyrics}
            />
          </Line>
          {/*
          <Line topBorder>
            <Text size='normal' >{t('Settings.includeSpanish')}</Text>
            <Switch
              onChange={() => setSpaPortLyrics(h=>!h)}
              checked={spaPortLyrics}
            />
          </Line> */}
        </WrapperForm>
      )}
    </WrapperOptions>
  );
}

const WrapperOptions = styled.div`
  position: relative;
  width: 100%;
  padding: 40px 10px;
`;
const WrapperCard = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;
const Row = styled.div`
  width: 100%;
  height: 140px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin: 40px 0;
`;
const Column = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 15px;
`;
const WrapperForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  margin-top: 50px;
`;
const Line = styled.div<{topBorder: boolean}>`
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: ${({topBorder}) => topBorder ? 'solid 1px rgba(239, 240, 245, .6)': '0'};
  border-bottom: solid 1px rgba(239, 240, 245, .6);
`;

export default GenreOptions;