import React from 'react';
import styled from 'styled-components';
import { RightOutlined } from '@ant-design/icons';


import hexToRgba from 'hex-alpha';
import { lightgray, redBG, black } from '../../config/colors';
// import components
import Text from '../atoms/Text';

interface Icons {
  [arg: string]: any
}

const icons: Icons = {
  info: require('../../assets/icons/info.png'),
  zones: require('../../assets/icons/zones.png'),
  language: require('../../assets/icons/language.png'),
  support: require('../../assets/icons/support.png'),
  tracks: require('../../assets/icons/tracks.png'),
  refer: require('../../assets/icons/refer.png'),
  logout: require('../../assets/icons/logOut.png'),
}

interface Props {
  title: string;
  active: boolean;
  handlePress: () => void,
  icon: string;
  top?: boolean;
  bottom?: boolean;
  logout?: boolean;
};

const ButtonMenu: React.FC<Props> = props => {

  const {
    title,
    active,
    handlePress,
    icon,
    top = false,
    bottom = false,
    logout = false
  } = props;

  return (
    <Container bottom={bottom} top={top} active={active} onClick={handlePress}>
      <Icon src={icons[icon]} size={35} />
      <Text
        size="normal"
        style={{paddingTop: 6}}
        color={logout ? redBG : black }
        bold={logout}
      >
        {title}
      </Text>
      {!logout && <RightOutlined style={{ marginLeft: 'auto'}} />}
    </Container>
  );
}

const Container = styled.div<{active: boolean, top: boolean, bottom: boolean}>`
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: ${({active}) => active ? 'rgba(239, 240, 245, 0.4)' : 'transparent'};
  padding: 10px;
  border-top: ${({top}) => top ? 'solid 1px rgba(239, 240, 245, .6)': '0'};
  border-bottom: ${({bottom}) => bottom ? 'solid 1px rgba(239, 240, 245, .6)': '0'};
  cursor: pointer;
`;
const Icon = styled.img<{size: number}>`
  width: ${({size}) => `${size}px`};
  height: ${({size}) => `${size}px`};
  margin-right: 10px;
`;


export default React.memo(ButtonMenu);