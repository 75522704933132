import React, { useRef, Fragment } from 'react';
import styled from 'styled-components';

import { MoodLabel, Genres} from '../../services/SoundSuitServiceTypes';
import {
  listMoods,
  ListMood,
  listGenres,
  ListGenres,
  idToLabel,
  IdToLabel,
} from '../../config/hardData';
import { grey } from '../../config/colors';
// import components
import Text from '../atoms/Text';

interface Props {
  list: MoodLabel[] | Genres[];
  type: 'moods' | 'genres'
};

const RowIconsLabels: React.FC<Props> = ({list, type}) => {

  const currentIdToLabel: IdToLabel = useRef(idToLabel()).current;

  // temporary fix
  const tempList = (list as any[]).reduce((acc, l) => {
    if (l === "jazz&soul") {
      acc.push("jazz");
      acc.push("soul");
    } else {
      acc.push(l);
    }
    return acc;
  }, []);

  return (
    <Container>
      {tempList.map((l: MoodLabel | Genres, i) => {
        const img = ((type === 'moods' ? listMoods : listGenres) as (ListMood | ListGenres)[]).find(m => m.label === l)?.img;
        const label =  currentIdToLabel[l].replace(/\(.*\)/, '');
        if(i > 1) {
          return null;
        }
        return (
          <Fragment key={`${i}-${l}`}>
            <Icon src={img!} />
            <Text size="small" color={grey}>{label}</Text>
            {i === 1 && list.length > 2 && <Text size="xsmall" color={grey} >...</Text>}
          </Fragment>
        );
      })}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const Icon = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 5px;
  margin-left: 3px;
`;

export default RowIconsLabels;

