import {
  ItemSchedule,
  Days,
  WeeklyProgram
} from '../services/SoundSuitServiceTypes';
import { defaultWeeklyProgram } from '../config/hardData';
import {
  isAfter,
  isBefore,
  addSeconds,
  addMinutes,
  subMinutes,
  differenceInSeconds
} from 'date-fns';
import {
  transformHourStringtoDate,
} from '../utils/functions';

type IsValid = 'valid'|'startNoValid'|'endNoValid'|'notAvailable'| 'minOneHour';

const days: Days[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
const mapDays = {
  'monday': 1,
  'tuesday': 2,
  'wednesday': 3,
  'thursday': 4,
  'friday': 5,
  'saturday': 6,
  'sunday': 7
};

function useSchedule() {

  function checkTimeSlotAvailable(timeslots: ItemSchedule[], day: Days, idSlot: string | undefined, startSlot: Date, endSlot: Date):IsValid {

    const lengthSlot = differenceInSeconds(endSlot, startSlot);
    if( lengthSlot < 3599) {
      return "minOneHour";
    }

    const program: ItemSchedule[] = sortTimeslotsByDay(timeslots, day);
    for (let i = 0; i < program.length; i++) {
      const {
        id: idItem,
        timeSlot: {
          start: {
            hour: hourStartItem
          },
          end: {
            hour: hourEndItem
          }
        }
      } = program[i];
      
      if (idItem !== idSlot) {
        // We add a few seconds to the test date to allow a slot to finish at 09:00 and an other to start at 09:00 for example.
        const dateForStartLimit = addMinutes(startSlot, 1);
        // We sub a few seconds to the test date to allow a slot to finish at 09:00 and an other to start at 09:00 for example.
        const dateForEndLimit = subMinutes(endSlot, 1);
        if (isAfter(startSlot, transformHourStringtoDate(hourStartItem)) && isBefore(dateForStartLimit, transformHourStringtoDate(hourEndItem))) {
          return 'startNoValid';
        }
        if (isAfter(dateForEndLimit, transformHourStringtoDate(hourStartItem)) && isBefore(dateForEndLimit, transformHourStringtoDate(hourEndItem))) {
          return 'endNoValid';
        }
        if(isAfter(transformHourStringtoDate(hourStartItem), startSlot) && isBefore(transformHourStringtoDate(hourEndItem), endSlot)  ) {
          return 'notAvailable';
        }
        if(isAfter(transformHourStringtoDate(hourEndItem), addMinutes(startSlot, 1)) && isBefore(transformHourStringtoDate(hourEndItem), endSlot)) {
          return 'notAvailable';
        }
      }
    }

    return 'valid';
  }

  function sortTimeslotsByDay(timeslots: ItemSchedule[], day: Days): ItemSchedule[] {
    const sortedProgram = timeslots.reduce<ItemSchedule[]>((acc, item) => {
      if (item.timeSlot.start.day === day) {
        acc.push(item);
      }
      return acc;
    }, []);
    return sortedProgram;
  }
  
  function timeslotsToWeeklyProgram(timeslots: ItemSchedule[]): WeeklyProgram {
    const cloneTab: ItemSchedule[] = JSON.parse(JSON.stringify(timeslots));
    const weekly = cloneTab.reduce<WeeklyProgram>((acc, item) => {
      acc[item.timeSlot.start.day].push(item);
      return acc;
    }, defaultWeeklyProgram);
    return weekly;
  }

  function generateRecurrentSlots(timeslots: ItemSchedule[]):  ItemSchedule[] {

    const cloneTimeSlots: ItemSchedule[] = JSON.parse(JSON.stringify(timeslots));
    cloneTimeSlots.sort((a, b) => {
      return mapDays[a.timeSlot.start.day] - mapDays[b.timeSlot.start.day];
    });

    return cloneTimeSlots.reduce((acc, slot) => {

      if(slot.days.length > 1) {
        slot.days.forEach(day => {
          const newSlot: ItemSchedule = {
            ...slot,
            originalDay: slot.timeSlot.start.day,
            timeSlot: {
              ...slot.timeSlot,
              start: {
                day,
                hour: slot.timeSlot.start.hour
              },
              end: {
                day,
                hour: slot.timeSlot.end.hour
              }
            }
          }

          const isValid = checkTimeSlotAvailable(
            acc,
            day,
            slot.id,
            transformHourStringtoDate(slot.timeSlot.start.hour),
            transformHourStringtoDate(slot.timeSlot.end.hour)
          );

          if (isValid === 'valid') {
            acc.push(newSlot);
          }
        });
      } else {
        acc.push(slot)
      }

      return acc;
    }, []);
  }

  return {
    generateRecurrentSlots,
    sortTimeslotsByDay,
    timeslotsToWeeklyProgram,
    checkTimeSlotAvailable
  };

}

export default useSchedule;