import React from 'react';
import styled from 'styled-components';
import { redLightBG, redBG, black, white } from '../../config/colors';


interface Props {
  children: React.ReactNode;
  gradientBG? : boolean;
  borderBottom?: boolean;
  forceBottomRadius? :boolean;
  noBottomRadius?: boolean;
  [key: string]: any;
  backgroundColor?: string;
};

const ScreenContainer: React.FC<Props> = props => {
  const {
    children,
    gradientBG = false,
    forceBottomRadius = false,
    noBottomRadius = false,
    backgroundColor = white,
    ...rest
  } = props;

  return (
    <BackgroundColor {...rest}>
      <Container
        forceBottomRadius={forceBottomRadius}
        noBottomRadius={noBottomRadius}
        backgroundColor={backgroundColor}
        {...props}
      >
        {gradientBG ? (
          <LinearGradient colors={[redLightBG, redBG]}>
            {children}
          </LinearGradient>
        ) : (
          children
        )}
      </Container>
    </BackgroundColor>
  );
};


const Container = styled.div<{forceBottomRadius: boolean, noBottomRadius: boolean, backgroundColor: string}>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: ${({forceBottomRadius, noBottomRadius}) => {
    if (noBottomRadius) {
      return '0px';
    }
    if(forceBottomRadius) {
      return '25px';
    }
    return '0px';
  }};
  border-bottom-right-radius: ${({forceBottomRadius, noBottomRadius}) => {
    if (noBottomRadius) {
      return '0px';
    }
    if(forceBottomRadius) {
      return '25px';
    }
    return '0px';
  }};
  background-color: ${({backgroundColor}) => backgroundColor};
  overflow: hidden;
`;
const LinearGradient = styled.div<{colors: string[]}>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: ${({colors}) => `linear-gradient(${colors[0]},${colors[1]})`};
`;
const BackgroundColor = styled.div`
  flex: 1;
  background-color: ${black};
`;

export default ScreenContainer;
