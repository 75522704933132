import React from "react";
import styled from "styled-components";
import { Routes, Route } from "react-router-dom";

// import components
import SettingsMenu from "./SettingsMenu";
import SettingsTarget from "./SettingsTarget";
import SettingsType from "./SettingsType";
import SettingsGenre from "./SettingsGenre";
import SettingsAdvanced from "./SettingsAdvanced";

interface Props {}

const SettingsScreen: React.FC<Props> = (props) => {
  return (
    <Container>
      <Routes>
        <Route index element={<SettingsMenu />} />
        <Route path="type" element={<SettingsType />} />
        <Route path="target" element={<SettingsTarget />} />
        <Route path="genres" element={<SettingsGenre />} />
        <Route path="advancedSettings" element={<SettingsAdvanced />} />
      </Routes>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export default SettingsScreen;
