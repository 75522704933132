import React, {useMemo} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useUser } from '../../store';
import i18n from '../../utils/i18n';
// import components
import Header from '../../components/molecules/Header';
import Text from '../../components/atoms/Text';
import Title from '../../components/atoms/Title';

interface Props {};

const ReferBusinessTuto: React.FC<Props> = (props) => {

  const [ t ] = useTranslation();
  const lg = useUser(state => state.state.language);

  const steps = useMemo(() => (
    [
      { title: i18n.t('Account.referTutoTitleOne'), text: i18n.t('Account.referTutoTextOne')},
      { title: i18n.t('Account.referTutoTitleTwo'), text: i18n.t('Account.referTutoTextTwo')},
      { title: i18n.t('Account.referTutoTitleThree'), text: i18n.t('Account.referTutoTextThree')},
    ]
  ), [lg]);

  return (
    <Container>
      <Header
        title=""
      />
      <WrapperOptions>
        <TitleWrapper>
            <Title bold >{t('Account.referTitleTuto')}</Title>
        </TitleWrapper>
        <div>
          {steps.map((step, i) => (
            <WrapperStep key={`${step.title}-${i}`}>
              <WrapperTitleStep>
                <Circle><Text bold size="large">{`${i + 1}`}</Text></Circle>
                <Text bold size="large">{step.title}</Text>
              </WrapperTitleStep>
              <Text>{step.text}</Text>
            </WrapperStep>
          ))}
        </div>
      </WrapperOptions>
    </Container>
  );
}


const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 20px 30px 0px;
  padding-right: 20px;
`;
const WrapperOptions = styled.div`
  width: 65%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 40px;
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  margin-bottom: 50px;
`;
const WrapperStep = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 30px 0;
`;
const WrapperTitleStep = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  align-items: center;
`;
const Circle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: solid 2px black;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  padding: 4px 1px 0 0;
`;


export default ReferBusinessTuto;