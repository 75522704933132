import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { TailSpin } from 'react-loading-icons'
import { useNavigate } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';

import { redText } from '../../config/colors';
// import components
import Text from '../atoms/Text';

interface Props {
  title: string;
  handlePressSave?: () => void;
  inProgress?: boolean;
  color?: string;
  active?: boolean;
  customGoBack?: () => void,
  noBack?: boolean,
};

const Header:React.FC<Props> = (props) => {

  const {
    title,
    handlePressSave,
    inProgress = false,
    color = redText,
    active = true,
    customGoBack,
    noBack = false
  } = props;

  const [t] = useTranslation();
  const navigate = useNavigate();

  return (
    <Container>
      {!noBack ? (
        <ButtonBack onClick={() => customGoBack ? customGoBack() : navigate(-1)} >
          <LeftOutlined style={{ fontSize: '24px' }} color="black" />
        </ButtonBack>
      ): (
        <FakeBlock />
      )}
      <Text bold>{title}</Text>
      {!inProgress ? (
        handlePressSave ? (
          <ButtonSave
            onClick={() => {
              if (active) {
                handlePressSave();
              }
            }}
            style={{
              opacity: active? 1 : 0.3
            }}
          >
            <Text size="xsmall" bold color={color} >{t('Modal.save')}</Text>
          </ButtonSave>
        ):(
          <FakeBlock />
        )
      ):(
        <TailSpin stroke={redText} height="2em" />
      )}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
const ButtonSave = styled.div`
  cursor: pointer;
`;
const ButtonBack = styled.div`
  cursor: pointer;
`;
const FakeBlock = styled.div`
  width: 50px;
  height: 10px;
`;

export default Header;