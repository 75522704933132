import React, { useState } from 'react';
import styled from 'styled-components';
import { Audio } from 'react-loading-icons'
import hexToRgba from 'hex-alpha';

import { noop, getWidthOrHeight } from '../../utils/functions';
import { white, buttonRed, black} from '../../config/colors';
// import components
import Text from './Text';

interface ParamsStyle {
  colorType?: 'white' | 'red' | 'light' | 'black';
  width: number | string;
  height: number | string;
  disabled?: boolean;
  borderRadius?: 'normal' | 'big';
  paddingSide?: number | string;
  active?: boolean;
}

interface Props extends ParamsStyle {
  children: string;
  onHandleClick: () => void;
  color?: string;
  fontSize?: number;
  loading?: boolean;
  [key:string]: any;
};

const ButtonText: React.FunctionComponent<Props> = props => {
  const {
    children,
    onHandleClick,
    colorType = "white",
    color = "white",
    width,
    height,
    fontSize = 15,
    disabled,
    borderRadius = 'normal',
    loading = false,
    paddingSide,
    ...rest
  } = props;

  const [ isActive, setIsActive ] = useState<boolean>(false);

  return (
    <Container
      onClick={disabled ? noop : onHandleClick}
      onMouseDown={() => setIsActive(true)}
      onMouseUp={() => setIsActive(false)}
      colorType={colorType}
      width={width}
      height={height}
      disabled={disabled}
      borderRadius={borderRadius}
      paddingSide={paddingSide}
      active={isActive}
    >
      {loading ? (
        <Audio stroke={color} height="2em" />
      ):(
        <WrapperText
        color={color}
        fontSize={fontSize}
        bold
        {...rest}
      >
        {children}
      </WrapperText>
      )}
    </Container>
  );
}

const Container = styled.div<ParamsStyle>`
  cursor: pointer;
  width: ${({width}) => getWidthOrHeight(width)};
  height: ${({height}) => getWidthOrHeight(height)};
  background-color: ${({colorType, disabled}) => {
    if(disabled && colorType === 'red') {
      return hexToRgba(buttonRed, 0.4);
    }
    if(colorType === 'red') {
      return buttonRed
    }
    if(colorType === 'light') {
      return 'rgba(255, 255, 255, 0.2)';
    }
    if(colorType === 'black') {
      return black;
    }
    return white;
  }};
  border-radius:${({borderRadius}) => borderRadius === 'normal' ? '8px' : '20px'} ;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: ${({paddingSide}) => paddingSide ? getWidthOrHeight(paddingSide) : 0 };
  padding-right: ${({paddingSide}) => paddingSide ? getWidthOrHeight(paddingSide) : 0 };
  opacity: ${({active}) => active ? 0.3 : 1};
  transition: opacity 0.05s;
`;

const WrapperText = styled(Text)<{color: string, fontSize: number}>`
  color: ${({color}) => color};
  font-size: ${({fontSize}) => `${fontSize}px`};
`;

export default ButtonText;