import React, { useEffect, useState } from "react";


function useDimensionContainer(refContainer: React.MutableRefObject<any>):({width: number; height: number}) {

  const [dimensions, setDimensions] = useState<{width: number; height: number}>({
    width: 0,
    height: 0
  });

  useEffect(() => {
    const getDimensions = () => ({
      width: refContainer.current.offsetWidth,
      height: refContainer.current.offsetHeight
    });
    const handleResize = () => {
      setDimensions(getDimensions())
    }
    if (refContainer.current) {
      setDimensions(getDimensions())
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [refContainer]);

  return dimensions;
}

export default useDimensionContainer;