import React from 'react';
import styled from 'styled-components';
import hexToRgba from 'hex-alpha';
import { CheckOutlined } from "@ant-design/icons";

import { buttonRed } from '../../config/colors';
// import components
import Text from '../atoms/Text';

interface Props {
  label: string;
  image: any;
  handlePress: () => void;
  selected: boolean;
  bold?: boolean;
};

const CardOption: React.FC<Props> = props => {

  function onHandlePress() {
    handlePress();
  }

  const {
    label,
    image,
    handlePress,
    selected,
    bold = false
  } = props;

  return (
    <Container onClick={onHandlePress}>
      {selected && (
        <Selected>
          <CheckOutlined style={{ color:"white", fontSize: '32px'}} />
        </Selected>
      )}
      <CardImage src={image} />
      <Text align="center" bold={bold} size="xsmall" >{label}</Text>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 120px;
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 10px;
  cursor: pointer;
`;
const CardImage = styled.img`
  width: 90px;
  height: 90px;
  border-radius: 13px;
  margin-bottom: 10px;
  z-index: 0;
`;
const Selected = styled.div`
  background-color: ${hexToRgba(buttonRed, 0.4)};
  width: 90px;
  height: 90px;
  border-radius: 13px;
  position: absolute;
  top: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CardOptionMemoized = React.memo(CardOption);

export default CardOptionMemoized;