import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {RightOutlined} from "@ant-design/icons";

import iconGift from '../../assets/icons/gift.png';
import iconBusiness from '../../assets/images/business.jpg';

import { veryLightGrey, redText } from '../../config/colors';
import { loadUserProfile } from '../../services/SoundSuitService';
import { URL_REFERER_BUSINESS } from '../../config/hardData';
// import components
import Header from '../../components/molecules/Header';
import Text from '../../components/atoms/Text';
import Title from '../../components/atoms/Title';

interface Props {};

const ReferBusinessScreen: React.FC<Props> = props => {

  function copyToClipboard() {
    navigator.clipboard.writeText(URL_TO_REFER);
    setLinkCopied(true);
  };

  const { data } = useQuery('/userinfo', loadUserProfile);

  const URL_TO_REFER = `${URL_REFERER_BUSINESS}${data?.firstpromoter_id || ''}`;

  const [ t ] = useTranslation();
  const navigate = useNavigate();

  const [ linkCopied, setLinkCopied] = useState<boolean>(false);


  return (
    <Container>
      <Header
        title=""
      />
      <WrapperOptions>
        <TitleWrapper>
          <Title bold >{t('Account.referBusinessTitle')}</Title>
          <Icon src={iconGift} />
        </TitleWrapper>
        <WrapperImage>
          <ImageCover
            src={iconBusiness}
          />
        </WrapperImage>
        <TextWrapper>
          <Text size="large" bold >{t('Account.referStrongLine')}</Text>
          <Text size="normal" align="center" >{t('Account.referSubLine')}</Text>
        </TextWrapper>
        <ButtonHowWork onClick={() => navigate('../refertuto')}>
          <Text size="normal" align="center" >{t('Account.referHelpButton')}</Text>
          <RightOutlined color="black" />
        </ButtonHowWork>
        <ButtonsWrapper>
          <div onClick={copyToClipboard}>
            <Text size="normal" bold color={redText} >{linkCopied ? t('Account.referCopied') : t('Account.referCopyButton')}</Text>
          </div>
        </ButtonsWrapper>
      </WrapperOptions>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 20px 50px 0px;
  padding-right: 20px;
`;
const WrapperOptions = styled.div`
  width: 90%;
  padding-left: 10px;
  padding-right: 10px;
`;
const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const Icon = styled.img`
  width: 40px;
  height: 40px;
`;
const WrapperImage = styled.div`
  margin-top: 20px;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 40px;
`;
const ImageCover = styled.img`
  width: 100%;
`;
const ButtonHowWork = styled.div`
  margin-top: 100px;
  width: 100%;
  height: 60px;
  border-top: 0.5px solid ${veryLightGrey};
  border-bottom: 0.5px solid ${veryLightGrey};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 5px;
  cursor: pointer;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 40px;
`;


export default ReferBusinessScreen;