import React, { useState } from 'react';
import styled from 'styled-components';
import { Menu as MenuAntd } from 'antd';
import { useNavigate } from 'react-router-dom';

import logoSS from '../../assets/images/logo1x.png';
import iconAccount from '../../assets/icons/accountNoActive.png';
import iconSetting from '../../assets/icons/settingsNoActive.png';
import iconSchedule from '../../assets/icons/scheduleNoActive.png';
import iconPlayer from '../../assets/icons/playerNoActive.png';
// import components

interface Props {
  activeScreen: string;
  setActiveScreen: (index: string) => void;
};

const Menu:React.FC<Props> = props => {

  const {
    activeScreen,
    setActiveScreen
  } = props;

  const navigate = useNavigate();
  const [ menuOn, setMenuOn ] = useState<boolean>(false);

  return (
    <Container>
      <Button onClick={() => setMenuOn(!menuOn)}>
        <Logo alt="soundsuit-logo" src={logoSS} />
      </Button>
      <MenuAntd
          onClick={({key}) => {
            setActiveScreen(key);
            switch (key) {
              case "1":
                navigate('/');
                break;
              case "2":
                navigate('account');
                break;
              case "3":
                navigate('settings');
                break;
              case "4":
                navigate('/schedule');
                break;
              default:
                break;
            }
          }}
          defaultSelectedKeys={[activeScreen]}
          selectedKeys={[activeScreen]}
          mode="inline"
          theme="light"
          inlineCollapsed={!menuOn}
          style={{
            height: "100%",
          }}
        >
          <MenuAntd.Item key="1" title="" icon={<Icon src={iconPlayer} />} >
            Player
          </MenuAntd.Item>
          <MenuAntd.Item key="2" title="" icon={<Icon src={iconAccount} />} >
            Account
          </MenuAntd.Item>
          <MenuAntd.Item key="3" title="" icon={<Icon src={iconSetting} />} >
            Settings
          </MenuAntd.Item>
          <MenuAntd.Item key="4" title="" icon={<Icon src={iconSchedule} />} >
            Schedule
          </MenuAntd.Item>
        </MenuAntd>
    </Container>
  );
}

const Container = styled.div`
  grid-column: 1 / 2;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  min-width: 50px;
  background-color: white;
  z-index: 50;
`;
const Button = styled.div`
  cursor: pointer;
  margin: 15px 0 15px 15px;
`;
const Logo = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 25px;
`;
const Icon = styled.img`
  width: 10px;
  height: 15px;
`;

export default Menu;