import React, { useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';

import iconRightArrow from '../../assets/icons/rightArrow.png';

import {
  almostWhite,
  grey,
  white,
  veryLightLightGrey
} from '../../config/colors';
// import components
import Text from '../atoms/Text';

export interface OptionsDetails {
  label: string | React.ReactNode;
  selection: React.ReactNode | string;
  onPressType?: 'link' | 'slot';
  onPressOption?: () => any;
  componentToDisplay?: React.ReactNode;
}

interface Props {
  data: OptionsDetails[];
  heightSlot?: number;
};

const ListOptions: React.FC<Props> = props => {

  function handleSlot(index: number):void {
    if(indexSelected === null) {
      setIndexSelected(index);
      apiOpacity.start({ opacity: 1});
      apiHeight.start({
        height: heightSlot,
        onRest: () => {
          setSlotCompleted(true);
        }
      });
    } else if (indexSelected === index) { 
      apiOpacity.start({
        opacity: 0,
        onRest: () => {
          apiHeight.start({
            height: 0,
            onRest: () => {
              unstable_batchedUpdates(() => {
              setIndexSelected(null);
              setSlotCompleted(false);
            });
            }
          });
        }
      });
    } else {
      apiOpacity.start({ opacity: 0});
      apiHeight.start({
        height: 0,
        onRest: () => {
          unstable_batchedUpdates(() => {
            setSlotCompleted(false);
            setIndexSelected(index);
          });
          apiOpacity.start({ opacity: 1 });
          apiHeight.start({
            height: heightSlot,
            onRest: () => {
              setSlotCompleted(true);
            }
          });
        }
      });
    }
  }

  const {
    data,
    heightSlot = 180
  } = props;

  const [indexSelected, setIndexSelected] = useState<number | null>(null);
  const [slotCompleted, setSlotCompleted] = useState<boolean>(false);

  const [opacityAnim, apiOpacity] = useSpring(() => ({opacity: 0, config: {duration: 100 }}));
  const [heightAnim, apiHeight] = useSpring(() => ({height: 0, config: { duration: 150 }}));

  return (
    <Container>
      {data.map((option, i) => {
        const {
          label,
          selection,
          onPressType,
          componentToDisplay,
          onPressOption
        } = option;

        return (
          <div key={`items-${i}`}>
            <Row
              clickable={onPressType === 'slot' || typeof onPressOption !== 'undefined'}
              onClick={() => {
                if (onPressType === 'slot') {
                  handleSlot(i);
                }
                if (onPressOption) {
                  onPressOption();
                }
              }}
              isLast={i === data.length - 1}
              activeOpacity={onPressType === 'link' ? 0.2 : 1}
            >
              {typeof label === 'string' ? <Text size="xsmall">{label}</Text>: label}
              <Selection>
                {typeof selection === 'string' ? <Text size="xsmall" color={grey}  >{selection}</Text> : selection}
              </Selection>
              {onPressType === 'link' ? (
                <div
                  style={{
                    position: 'absolute',
                    right: 15,
                  }}
                >
                  <RightArrow src={iconRightArrow} />
                </div>
              ): typeof componentToDisplay !== 'undefined' ? (
                <animated.div
                  style={{
                    position: 'absolute',
                    right: 15,
                    // transform: [
                    //   {rotateZ: '90deg'},
                    //   {rotateY: indexSelected === i ? animSlot.interpolate({inputRange: [0, 1], outputRange: ['0deg', '180deg']}) : '0deg' },
                    //   {perspective: 1000}
                    // ]
                  }}
                >
                  <RightArrow src={iconRightArrow} />
                </animated.div>
              ): null}
            </Row>
            {componentToDisplay && onPressType === 'slot' && (
              <animated.div 
                style={{
                  borderBottomWidth: indexSelected === i ? 1 : 0,
                  borderBottomColor: veryLightLightGrey,
                  height: indexSelected === i ? heightAnim.height : 0
                }}
              >
                {indexSelected === i && slotCompleted && (
                  <animated.div
                    style={{
                      position: 'relative',
                      backgroundColor: white,
                      flex: 1,
                      alignItems: 'center',
                      opacity: indexSelected === i ? opacityAnim.opacity : 0 
                    }}
                  >
                    {componentToDisplay}
                  </animated.div>
                )}
              </animated.div>
            )}
          </div>
        );
      })}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  background-color: ${almostWhite};
  border-radius: 10px;
  margin-top: 25px;
  padding: 10px 0 10px 15px;
  border-width: 1px;
  border-color: ${veryLightLightGrey};
`;
const Row = styled.div<{isLast: boolean, clickable: boolean}>`
  position: relative;
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid ${({isLast}) => isLast ? '0' : '1px'}  ${veryLightLightGrey};
  cursor: ${({clickable}) => clickable ? 'pointer' : 'default'};
`;
const Selection = styled.div`
  margin-right: 40px;
`;
const RightArrow = styled.img`
  width: 12px;
  height: 24px;
  tint-color: ${grey};
`;

export default ListOptions;