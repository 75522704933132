import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Outlet } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { TailSpin } from 'react-loading-icons'

import soundsuitLogo from '../../assets/images/soundsuitlogo.png';

import { usePlayer } from '../../store';
import { Track } from '../../services/SoundSuitServiceTypes';
import { getZoneById, loadUserProfile } from '../../services/SoundSuitService';
import { almostBlack, redText } from '../../config/colors';
// import components
import TitleAuthorTrack from '../molecules/TitleAuthorTrack';
import BarOptionsTrack from '../molecules/BarOptionsTrack';
import CarouselPlaylist from '../molecules/CarouselPlaylist';
import BarOptionsPlayback from '../molecules/BarOptionsPlayback';
import Text from '../atoms/Text';

interface Props {};

const PlayerScreen: React.FC<Props> = props => {

  const isLargeScreen = useMediaQuery({ query: '(min-width: 1200px)' });

  const [ t ] = useTranslation();

  const navigate = useNavigate();
  const positionTrack = usePlayer(playerState => playerState.state.positionTrack);
  const playlist = usePlayer(playerState => playerState.state.playlist);
  const criteriaError = usePlayer(playerState => playerState.state.criteriaError);
  const zoneSelected = usePlayer(statePlayer => statePlayer.state.zoneSelected);
  const setZoneSelected = usePlayer.getState().reducers.setZoneSelected;

  const [ currentTrack, setCurrentTrack ] = useState<Track>({} as Track);

  const { data } = useQuery('/userinfo', loadUserProfile);

  const { data: dataZone } = useQuery(['zone', zoneSelected], () => {
    if (zoneSelected) {
      return getZoneById(zoneSelected)
    }
  });

  useEffect(() => {
    if (!zoneSelected && data && data.zones.length > 0) {
      setZoneSelected(data.zones[0]);
    }
  }, [data]);

  useEffect(() => {
    if (playlist.length > 0) {
      const current: Track = playlist[positionTrack];
      setCurrentTrack(current);
    }
  }, [playlist, setCurrentTrack, positionTrack]);

  if (playlist.length < 1) {
    return (
      <ContainerSpinner>
         <TailSpin stroke={redText} height="2em" />
      </ContainerSpinner>
    );
  }

  return (
    <>
    <Container>
      <Header>
        <Logo src={soundsuitLogo} />
        <InfoZone
          onClick={() => {
            navigate('account/zones')
          }}
        >
          <Text color={almostBlack} size='xsmall'>{`${t('Account.musicZones')}`}</Text>
          <Pipe>
            <Text color={almostBlack} size='xsmall'>|</Text>
          </Pipe>
          <Text color={almostBlack} size='xsmall' >{dataZone?.name || ''}</Text>
        </InfoZone>
      </Header>
      <WrapperPlayback>
        <PositionWrapperPlayback isLarge={isLargeScreen}>
          <BarOptionsPlayback />
        </PositionWrapperPlayback>
      </WrapperPlayback>
      {criteriaError ? (
        <WrapperError>
          <ExclamationCircleOutlined style={{ color: '#FF6359', fontSize: 48, marginBottom: 10 }} />
          <Text align='center' size='xsmall' >{t('Player.criteriaError')}</Text>
        </WrapperError>
      ):(
        <>
          <CarouselPlaylist currentTrack={currentTrack} />
          <WrapperInfosControls>
            <TitleAuthorTrack />
            <BarOptionsTrack currentTrack={currentTrack} />
          </WrapperInfosControls>
        </>
      )}
    </Container>
    <Outlet />
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 50px;
`;
const ContainerSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 50px;
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 10px 60px 0 60px;
`;
const WrapperInfosControls = styled.div`
  display: flex;
  flex-direction: column;
`;
const WrapperPlayback = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
`;
const PositionWrapperPlayback = styled.div`
  width: ${({isLarge}) => isLarge ? '53%' : '60%' };
  display: flex;
`;
const Logo = styled.img`
  width: 100px;
  height: 22px;
`;
const WrapperError = styled.div`
  width: 80%;
  padding: 20px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const InfoZone = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: transparent;
  border: 0;
  cursor: pointer;
`;
const Pipe = styled.div`
  margin: 0 10px;
`;

export default PlayerScreen;