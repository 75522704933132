import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {CheckOutlined} from '@ant-design/icons';

import { white, buttonRed, redText} from '../../config/colors';
// import components
import ScreenContainer from '../../components/atoms/ScreenContainer';
import Text from '../../components/atoms/Text';
import Title from '../../components/atoms/Title';
import ButtonText from '../../components/atoms/ButtonText';


interface Props {};

const ThankResetPassword: React.FC<Props> = props => {

  function handleDirection() {
    navigate('../signIn');
  }

  const params = useParams();

  const [ t ] = useTranslation();
  const navigate = useNavigate();

  return (
    <ScreenContainer noBottomRadius>
      <Container>
        <Column>
          <WrapperText>
            <WrapperCheck>
              <CheckOutlined style={{ fontSize: '40px', color: buttonRed}} />
            </WrapperCheck>

            <Title>{t('Auth.thankYouTitle')}</Title>
            <Text align="center" size="xsmall">{t('Auth.resetPasswordParaph1')}</Text>
            <Text align="center" size="xsmall" color={redText}>{params.email}</Text>
            <Text style={{ marginTop: 30 }} align="center" size="xsmall">{t('Auth.resetPasswordParaph2')}</Text>
          </WrapperText>
          <ButtonText
            colorType="red"
            color={white}
            onHandleClick={handleDirection}
            width='65%'
            height={50}
          >
            {t('Auth.login')}
          </ButtonText>
        </Column>
      </Container>
    </ScreenContainer>
  );
};

const Container = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
`;
const Column = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 80px 0 30px 0;
`;
const WrapperText = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 30%;
`;
const WrapperCheck = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 10px;
  background-color: rgb(249, 237, 237);
  margin-bottom: 30px;
`;

export default ThankResetPassword;