import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import {
  getZoneById,
  getLocationById
} from '../../services/SoundSuitService';
import {
  veryVeryLightGrey,
  lightgray,
  redBG,
  almostBlack
} from '../../config/colors';
// import components
import Text from '../atoms/Text';
import Avatar from '../atoms/Avatar';

type Props = {
  zoneId?: string;
  locationId?: string;
  name?: string;
};

const AccountCard = (props: Props) => {

  const {
    zoneId,
    locationId,
    name
  } = props;
  const [t] = useTranslation();

  const { data: dataZone } = useQuery(['zone', zoneId], () => {
    if (zoneId) {
      return getZoneById(zoneId)
    }
  });
  const { data: dataLocation } = useQuery('/locationId', () => {
    if (locationId) {
      return getLocationById(locationId)
    }
  });

  return (
    <Container>
      <Wrapper>
        <Avatar />
        <WrapperInfo>
          <BlocInfo>
            <Text size='small' color={redBG} style={{marginBottom: 3}}>{t('Account.labelLocationName')}</Text>
            <Text bold color={almostBlack} >{dataLocation?.name || name || ''}</Text>
          </BlocInfo>
          <Separator />
          <BlocInfo>
            <Text size='small' color={redBG} style={{marginBottom: 3}}>{t('Account.musicZones')}</Text>
            <Text bold color={almostBlack} >{dataZone?.name || ''}</Text>
          </BlocInfo>
        </WrapperInfo>
      </Wrapper>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
`;
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 15px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 20px;
  background-color: ${veryVeryLightGrey};
  overflow: hidden;
`;
const WrapperInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
`;
const Separator = styled.div`
  width: 70%;
  height: 1px;
  margin: 5px 0px;
  background-color: ${lightgray};
`;
const BlocInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 5px ;
  padding-bottom: 5px ;
`;

export default AccountCard;