import styled from 'styled-components';
import {useQuery} from 'react-query';
import {FileImageOutlined } from '@ant-design/icons';
import { TailSpin } from 'react-loading-icons'

import {getZoneById} from '../../services/SoundSuitService';
import {veryVeryLightGrey, white, lightgray, almostBlack, buttonRed} from '../../config/colors';
// import component
import Text from '../atoms/Text';
import CheckBox from '../atoms/CheckBox';

type Props = {
  zoneId: string;
  selectedId: string | null;
  handleSelection: (id: string) => void;
};

const ZonesRow = (props: Props) => {
  
  const {zoneId, selectedId, handleSelection} = props;

  const {data} = useQuery(['zone', zoneId], () => getZoneById(zoneId));

  if (!data) {
    return (<TailSpin stroke={buttonRed} height="2em" />);
  }

  return (
    <Container onClick={() => handleSelection(data.id)}>
      <WrapperImage>
        <FileImageOutlined />
      </WrapperImage>
      <WrapperInfo>
        <Text bold color={almostBlack}>
          {data.name}
        </Text>
        <Text size="xsmall" color={almostBlack}>
          {data.description || ''}
        </Text>
      </WrapperInfo>
      <WrapperCheckBox>
        <CheckBox active={selectedId === data.id} />
      </WrapperCheckBox>
    </Container>
  );
};

const Container = styled.button`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  height: 80px;
  border-radius: 20px;
  margin-bottom: 10px;
  background-color: ${veryVeryLightGrey};
  border: 0;
  cursor: pointer;
`;
const WrapperImage = styled.div`
  width: 65px;
  height: 65px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${white};
  border-radius: 15px;
  border: solid 1px ${lightgray};
`;
const WrapperInfo = styled.div`
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 10px;
`;
const WrapperCheckBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  padding-right: 7px;
`;

export default ZonesRow;
