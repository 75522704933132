import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';

import { usePlayer } from '../../store';
import { Track } from '../../services/SoundSuitServiceTypes';
// import components
import TrackCover from '../atoms/TrackCover';
import MainTrackCard from '../atoms/MainTrackCard';

interface Props {
  currentTrack: Track
};

const CarouselPlaylist: React.FC<Props> = props => {

  const {
    currentTrack
  } = props;

  const isLargeScreen = useMediaQuery({ query: '(min-width: 1200px)' })

  const playlist = usePlayer(playerState => playerState.state.playlist);
  const positionTrack = usePlayer(playerState => playerState.state.positionTrack);

  const [ listUp, setListUp ] = useState<Track[]>([]);
  const [ listDown, setListDown ] = useState<Track[]>([]);
  const [ listSmall, setListSmall ] = useState<Track[]>([]);

  useEffect(() => {
    function createSmallList(): Track[] {
      let smallList: Track[] = [];
      if (typeof playlist[positionTrack - 2] !== 'undefined') {
        const t = playlist[positionTrack - 2];
        t.position = 'recent';
        smallList.push(t);
      }
      if (typeof playlist[positionTrack - 1] !== 'undefined') {
        const t = playlist[positionTrack - 1];
        t.position = 'recent';
        smallList.push(t);
      }
      smallList.push(playlist[positionTrack]);
      if (typeof playlist[positionTrack + 1] !== 'undefined') {
        const t = playlist[positionTrack + 1];
        t.position = 'queue';
        smallList.push(t);
      }
      if (typeof playlist[positionTrack + 2] !== 'undefined') {
        const t = playlist[positionTrack + 2];
        t.position = 'queue';
        smallList.push(t);
      }
      return smallList;
    }

    if (playlist.length > 0) {
        const clone = JSON.parse(JSON.stringify(playlist));
        const up = clone.slice(positionTrack + 1);
        const down = clone.slice(0, positionTrack).reverse();

        setListUp(up);
        setListDown(down);
        setListSmall(createSmallList());
    }
  }, [playlist, positionTrack]);

  return (
    <Container isLarge={isLargeScreen}>
      <MainTrackCard track={currentTrack} isLargeScreen={isLargeScreen} />
        {isLargeScreen ? (
          <WrapperCarousel>
            <WrapperListUp>
              <ScrollMenu>
                {listUp.map((t, i) => {
                  if (i > 2) {
                    return null;
                  }
                  return (
                    <TrackCover
                      track={t}
                      key={t._id}
                      position='queue'
                    />
                  );
                })}
              </ScrollMenu>
            </WrapperListUp>
            <ScrollMenu>
              {listDown.map((t, i) => {
                if (i > 2) {
                  return null;
                }
                return (
                  <TrackCover
                    track={t}
                    key={t._id}
                    position='recent'
                  />
                );
              })}
            </ScrollMenu>
          </WrapperCarousel>
        ):(
          <ScrollMenu>
            {listSmall.map((t, i) => {
              if (i > 6) {
                return null;
              }
              return (
                <TrackCover
                  track={t}
                  key={t._id}
                  isLargeScreen={false}
                  position={t.position}
                />
              );
            })}
          </ScrollMenu>
        )}
    </Container>
  );
}

const Container = styled.div<{isLarge: boolean}>`
  position: relative;
  display: flex;
  flex-direction: ${({isLarge}) => isLarge ? 'row' : 'column'};
  justify-content: space-evenly;
  align-items: center;
  width: ${({isLarge}) => isLarge ? '60%' : 'auto' };
  height: ${({isLarge}) => isLarge ? '40%' : '290px'};
  min-height: ${({isLarge}) => isLarge ? '380px' : 'auto'};;
  overflow: hidden;
  margin-top: ${({isLarge}) => isLarge ? '50px' : '0' };
`;
const WrapperCarousel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: auto;
  width: 60%;
`;
const WrapperListUp = styled.div`
  margin-bottom: 10px;
`;

export default CarouselPlaylist;