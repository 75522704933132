import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { up } from '../../utils/breakpoints';

import LogoSrc from '../../assets/icons/logo.svg';
import WelcomeImage from '../../assets/images/welcome.jpeg';

interface Props {};

const WelcomeScreen: React.FC<Props> = props => {

  const [ t ] = useTranslation();
  let navigate = useNavigate();

  return (
    <Page>
      <Main>
          <Logo src={LogoSrc} alt="SoundSuit Logo" />
          <Title>{t('Auth.mainTitle')}</Title>
          <Actions>
            <ContainedButton onClick={() => navigate("signAccount/signup")}>
              {t('Auth.createAccount')}
            </ContainedButton>
            <OutlinedButton onClick={() => navigate("signAccount/signin")}>
              {t('Auth.loginEmail')}
            </OutlinedButton>
          </Actions>
      </Main>
      <Aside>
        <Image src={WelcomeImage} alt="Welcome Image" />
      </Aside>
    </Page>
  );
};

export default WelcomeScreen;

const Page = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  @media (prefers-color-scheme: dark) {
    background: #000;
    color: #fff;
  }
`;

const Main = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  flex: 1 1 auto;
  padding: 2rem;
  
  @media ${up('lg')} {
    flex: 1 0 50%;
    padding: 4rem 3rem;
  }

  @media ${up('xl')} {
    flex: 1 0 40%;
  }
`;

const Actions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 1rem;
  margin-top: 3rem;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-weight: 700;
  cursor: pointer;
  transition: opacity 0.3s ease-out;
  
  &:hover {
    opacity: 0.8;
  }
`;

const ContainedButton = styled(Button)`
  background: #ff6259;
  color: #fff;
  border: 1px solid #ff6259;

`;

const OutlinedButton = styled(Button)`
  background: transparent;
  color: #ff6259;
  border: 1px solid #ff6259;
`;


const Aside = styled.aside`
  display: none;
  justify-content: center;
  align-items: center;
  background: #fa0505;

  @media ${up('lg')} {
    display: flex;
    flex: 1 0 50%;
  }

  @media ${up('xl')} {
    flex: 1 0 60%;
  }
`;

const Title = styled.h2`
  font-size: 2rem;
  font-weight: 700;
  color: currentColor;
  margin: 1em 0;
  text-align: center;
`;

const Logo = styled.img`
  width: 80px;
  height: 80px;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;