import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { veryLightGrey, black } from '../../config/colors';
import { usePlayer } from '../../store';
// import components
import Text from '../atoms/Text';
import Title from '../atoms/Title';

interface Props {};

const TitleAuthorTrack: React.FC<Props> = props => {

  const positionTrack = usePlayer(playerState => playerState.state.positionTrack);
  const playlist = usePlayer(playerState => playerState.state.playlist);

  const [trackTitle, setTrackTitle] = useState<string>("Track Title");
  const [trackArtist, setTrackArtist] = useState<string>("Autor");

  useEffect(() => {
    if (typeof playlist[positionTrack] !== 'undefined') {
      const {
        artist,
        title
      } = playlist[positionTrack];
      setTrackArtist(artist);
      setTrackTitle(title);
    }
  }, [playlist, positionTrack]);

  return (
    <Container>
      <Title color={black} >{trackTitle}</Title>
      <Text color={veryLightGrey} size="normal" >{trackArtist}</Text>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default TitleAuthorTrack;