import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import {shinyRed, black, white} from '../../config/colors';
// import components
import Text from './Text';

interface Props {
  position?: 'queue' | 'recent';
};

const TagTrack: React.FC<Props> = props => {

  const {
    position,
  } = props;

  const [t] = useTranslation();

  return (
    <Container position={position}>
      {position && position === 'queue' &&  <Text size="verysmall" color={white} >{t('Player.queueTag')}</Text>}
      {position && position === 'recent' && <Text size="verysmall" color={white} >{t('Player.recentlyTag')}</Text>}
    </Container>
  );
}

const Container = styled.div<{position: 'queue' | 'recent'}>`
  position: absolute;
  top: 5px;
  right: -2px;
  height: 15px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  background-color: ${({position}) => {
    if( position === 'recent' ) {
      return black;
    }
    if( position === 'queue' ) {
      return shinyRed;
    }
    return 'transparent';
  }};
`;

export default TagTrack;