import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { notification } from 'antd';

import { usePlayer } from '../../store';
import { Gender, Styles } from '../../services/SoundSuitServiceTypes';
import { updateTargetGroup, loadUserProfile, saveSettingsForStations } from '../../services/SoundSuitService';
import { TargetGroupToUpdate } from '../../services/SoundSuitServiceTypes';
// import components
import TargetOptions from '../../components/organisms/TargetOptions';
import Header from '../../components/molecules/Header';

interface Props {};

const SettingsTarget: React.FC<Props> = props => {

  async function handleSave(): Promise<void> {
    try {
      const dataToSend: TargetGroupToUpdate = {
        customer_age: [`<${age[1]}`],
        styles: styles
      };
      await updateTarget(dataToSend);
      // We persist the gender, not handle by server yet
      setTargetGroup(gender, age);
      await updateSettingsForStations({
        business_type: data?.business_type,
        genres: data?.genres,
        styles: dataToSend.styles,
        customer_age: dataToSend.customer_age,
        languages: data?.languages,
      });
    } catch (error) {
      console.log(error);
    }
  }

  function handleSelectStyles(id: Styles) {
    const copyStyle: Styles[] = styles.slice();
    if (styles.includes(id)) {
      const index: number = copyStyle.findIndex(s => s === id);
      copyStyle.splice(index, 1);
    } else {
      copyStyle.push(id);
    }
    setStyles(copyStyle);
  }

  const [ t ] = useTranslation();

  const setTargetGroup = usePlayer.getState().reducers.setTargetGroup;
  const targetGender = usePlayer.getState().state.settings.targetGender;
  const targetAge = usePlayer.getState().state.settings.targetAge;

  const [ gender, setGender ] = useState<Gender>(targetGender);
  const [ age, setAge ] = useState<[number, number]>(targetAge);
  const [ styles, setStyles ] = useState<Styles[]>([]);

  const queryClient = useQueryClient();
  const { data } = useQuery('/userinfo', loadUserProfile);
  const { mutateAsync: updateTarget, isLoading } = useMutation(updateTargetGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries('/userinfo');
      notification.open({
        message: '',
        description: t('Notifications.requestSuccess'),
      });
    },
    onError: () => {
      notification.open({
        message: '',
        description: t('Notifications.requestFailed'),
      });
    }
  });
  const {mutateAsync: updateSettingsForStations} = useMutation(saveSettingsForStations);

  useEffect(() => {
    if(data?.styles) {
      setStyles(data.styles);
    }
  }, [data]);

  return (
    <Container >
      <Header
        title={t('Settings.targetGroup')}
        handlePressSave={handleSave}
        inProgress={isLoading}
      />
      <TargetOptions
        gender={gender}
        setGender={setGender}
        styles={styles}
        setStyles={handleSelectStyles}
        age={age}
        setAge={setAge}
      />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 20px 0px 0px;
  padding-right: 20px;
`;

export default SettingsTarget;