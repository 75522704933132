import create from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import {update} from './middleware';
import Sound from 'react-hifi';
import { notification } from 'antd';

import {
  Model,
  ReducerEffect,
  ReducerEffectPromise
} from './';
import {
  ItemSchedule,
  Days,
  Track,
  CurrentTrackData,
  MediaToPlay
} from '../services/SoundSuitServiceTypes';
import {setMediaToZone} from '../services/SoundSuitService';
import {queryClient} from '../store/reactQueryInitial';

export type Gender = 'woman' | 'man' | 'manWoman';
export type AgeGap = [number, number];

const weekday = ["sunday","monday","tuesday","wednesday","thursday","friday","saturday"];

export enum SoundStatus {
  PAUSED = 'PAUSED',
  PLAYING = 'PLAYING',
  STOPPED = 'STOPPED',
}


export interface Settings {
  targetGender: Gender;
  targetAge: AgeGap;
};


export interface PlayerState {
  loading: boolean;
  settings: Settings;
  positionTrack: number;
  playlist: Track[];
  status: SoundStatus;
  criteriaError: boolean;
  volume: number;
  currentTrackData: CurrentTrackData;
  reloadQueueMeta: boolean;
  refreshTracks: boolean;
  timeslots: ItemSchedule[];
  scheduleDaySelected: Days;
  playWithSchedule: boolean;
  zoneSelected: string | null;
  mediaToPlay?: MediaToPlay;
  currentChoiceMedia?: {
    [key: string]: MediaToPlay;
  };
};
export interface PlayerReducers {
  initialize: ReducerEffect<[PlayerState]>;
  resetState: ReducerEffect<[]>;
  setTargetGroup: ReducerEffect<[Gender, AgeGap]>;
  setPositionTrack: ReducerEffect<[((currentPosition: number) => number) | number]>;
  setPlaylist: ReducerEffect<[Track[]]>;
  setStatus: ReducerEffect<[SoundStatus]>;
  setVolume: ReducerEffect<[number]>;
  setCriteriaError: ReducerEffect<[boolean]>;
  setCurrentTrackData: ReducerEffect<[CurrentTrackData]>;
  toggleReloadQueueMeta: ReducerEffect<[]>;
  toggleRefreshTracks: ReducerEffect<[]>;
  setTimeslots: ReducerEffect<[ItemSchedule[]]>;
  setScheduleDaySelected: ReducerEffect<[Days]>;
  togglePlayWithSchedule: ReducerEffect<[]>;
  setZoneSelected: ReducerEffect<[string]>;
  setPlayWithSchedule: ReducerEffect<[boolean]>;
  setMediaToPlayLocal: ReducerEffect<[MediaToPlay]>;
  setCurrentChoiceMedia: ReducerEffect<[MediaToPlay]>;
};
export interface PlayerEffects {
  setMediaToPlay: ReducerEffectPromise<[MediaToPlay]>;
};

const defaultStatePlayer: PlayerState = {
  loading: false,
  settings: {
    targetGender: 'man',
    targetAge: [20, 30],
  },
  positionTrack: 0,
  criteriaError: false,
  playlist: [],
  status: Sound.status.STOPPED,
  volume: 100,
  currentTrackData: {} as CurrentTrackData,
  reloadQueueMeta: false,
  refreshTracks: false,
  timeslots: [],
  scheduleDaySelected: weekday[new Date().getDay()] as Days,
  zoneSelected: null,
  playWithSchedule: false,
  mediaToPlay: undefined,
  currentChoiceMedia: undefined,
};

const player: Model<PlayerState, PlayerReducers, PlayerEffects> = (update, get) => ({
  state: defaultStatePlayer,
  reducers: {
    initialize(initialState) {
      update(state => ({
        ...state,
        settings: {
          ...initialState.settings
        }
      }));
    },
    resetState() {
      update(state => ({...defaultStatePlayer}));
    },
    setTargetGroup(targetGender, targetAge) {
      update(state => ({
        ...state,
        settings: {
          ...state.settings,
          targetGender,
          targetAge
        }
      }));
    },
    setPositionTrack(newPosition) {
      const currentPosition: number = get().state.positionTrack;
      let positionTrack: number = currentPosition;
      
      if (typeof newPosition === 'number') {
        positionTrack = newPosition;
      } else {
        positionTrack = newPosition(currentPosition);
      }
      update(state => ({
        ...state,
        positionTrack
      }));
    },
    setPlaylist(playlist) {
      update(state => ({
        ...state,
        playlist
      }));
    },
    setStatus(status) {
      update(state => ({
        ...state,
        status
      }));
    },
    setCriteriaError(criteriaError) {
      update(state => ({
        ...state,
        criteriaError
      }));
    },
    setVolume(volume) {
      update(state => ({
        ...state,
        volume
      }));
    },
    setCurrentTrackData(data) {
      update(state => ({
        ...state,
        currentTrackData: data
      }));
    },
    toggleReloadQueueMeta() {
      update(state => ({
        ...state,
        reloadQueueMeta: !state.reloadQueueMeta
      }));
    },
    toggleRefreshTracks() {
      update(state => ({
        ...state,
        refreshTracks: !state.refreshTracks
      }));
    },
    setTimeslots(timeslots) {
      update(state => ({
        ...state,
        timeslots
      }));
    },
    setScheduleDaySelected(day) {
      update(state => ({
        ...state,
        scheduleDaySelected: day
      }));
    },
    togglePlayWithSchedule() {
      update(state => ({
        ...state,
        playWithSchedule: !state.playWithSchedule
      }));
    },
    setZoneSelected(zoneSelected) {
      update(state => ({
        ...state,
        zoneSelected
      }))
    },
    setPlayWithSchedule(playWithSchedule) {
      update(state => ({
        ...state,
        playWithSchedule
      }));
    },
    setMediaToPlayLocal(mediaToPlay) {
      update(state => ({
        ...state,
        mediaToPlay
      }))
    },
    setCurrentChoiceMedia(media) {
      update(state => ({
        ...state,
        currentChoiceMedia: {
          ...state.currentChoiceMedia,
          [media.type]: media
        }
      }))
    }
  },
  effects: {
    setMediaToPlay: async function (media: MediaToPlay) {
      const setMediaToPlayLocal = get().reducers.setMediaToPlayLocal;
      const setCurrentChoiceMedia = get().reducers.setCurrentChoiceMedia;
      const mediaToPlay = get().state.mediaToPlay;
      const zoneId = get().state.zoneSelected;

      // We update the zone if we have a zoneId
      if (zoneId) {
        try {
          const res = await queryClient.fetchQuery(
            '/mutateMediaToPlay',
            () => setMediaToZone(zoneId, media),
          );
          if (Object.keys(res).length > 0) {
            // We save the last choice on the current
            if (mediaToPlay) {
              setCurrentChoiceMedia(mediaToPlay);
            }
            // We save the local mediaToPlay
            setMediaToPlayLocal(media);
          }
        } catch (error) {
          console.log('ERROR while switching media: ', error);
          notification.open({
            message: '',
            description: "Error while sending request",
          });
        }
      } else {
        notification.open({
          message: '',
          description: "Error while sending request",
        });
      }
    }
  }
});

// @ts-ignore
export const usePlayer = create(subscribeWithSelector((set, get) => player(update(set, get), get)));