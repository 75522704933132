"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const useScrollAnimation_1 = require("./useScrollAnimation");
const react_1 = require("react");
const useHandleKeyboard = (itemHeight) => {
    const [pressedKeys, setPressedKeys] = react_1.useState({});
    const onKeyUp = react_1.useCallback((e) => {
        const code = e.keyCode;
        if (pressedKeys[code]) {
            e.persist();
            setPressedKeys(prev => (Object.assign(Object.assign({}, prev), { [code]: false })));
        }
    }, [pressedKeys]);
    const onKeyPress = react_1.useCallback((e) => {
        const target = e.currentTarget;
        const code = e.keyCode;
        if (!pressedKeys[code] && code === 16) {
            e.persist();
            setPressedKeys(prev => (Object.assign(Object.assign({}, prev), { [e.keyCode]: true })));
        }
        if ((!pressedKeys[16] && code === 9) || code === 40) {
            e.preventDefault();
            const animate = useScrollAnimation_1.setScrollAnimation(target, target.scrollTop, itemHeight);
            requestAnimationFrame(animate);
        }
        if ((pressedKeys[16] && code === 9) || code === 38) {
            e.preventDefault();
            const animate = useScrollAnimation_1.setScrollAnimation(target, target.scrollTop, itemHeight * -1);
            requestAnimationFrame(animate);
        }
        if (code === 32 || code === 27) {
            e.preventDefault();
            target.blur();
        }
    }, [itemHeight, pressedKeys]);
    return {
        onKeyUp,
        onKeyPress
    };
};
exports.default = useHandleKeyboard;
