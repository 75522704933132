import React from "react";
import styled from 'styled-components';

// import components
import ButtonPlay from '../atoms/ButtonPlay';
import ButtonChangeTrack from '../atoms/ButtonChangeTrack';
import ProgressPlayer from '../molecules/ProgressPlayer';
import ProgressVolume from './ProgressVolume';
import SmallInfoTrack from './SmallInfoTrack';

interface Props {
  onNext: () => void;
  onPrevious: () => void;
  onTogglePlayback: () => void;
};

const ControlsPlayer: React.FC<Props> = props => {

  const {
    onNext,
    onPrevious,
    onTogglePlayback,
  } = props;

  return (
    <Container>
      <ProgressPlayer />
      <Row>
        <SmallInfoTrack />
        <Controls>
          <ButtonChangeTrack next={false} handlePress={onPrevious} />
          <ButtonPlay size={'normal'} handlePress={onTogglePlayback} />
          <ButtonChangeTrack next handlePress={onNext} />
        </Controls>
        <WrapperVolume>
          <ProgressVolume />
        </WrapperVolume>
      </Row>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Controls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const Row = styled.div`
  width: 100%;
  padding-left: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const WrapperVolume = styled.div`
  width: 20%;
`;

export default ControlsPlayer;