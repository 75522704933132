import React, { useMemo } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

import { SettingsScreen } from "../../store/modelApp";
import { useApp, usePlayer } from "../../store";
import { idToLabel, IdToLabel } from "../../config/hardData";
import { loadUserProfile } from "../../services/SoundSuitService";
// import components
import Title from "../atoms/Title";
import BigButton from "../molecules/BigButton";

interface Routes {
  [arg: number]: string;
}

const routes: Routes = {
  1: "type",
  2: "target",
  3: "genres",
  4: "mood",
  5: "advancedSettings"
};

interface Props {}

const SettingsMenu: React.FC<Props> = (props) => {
  
  function handlePressOnButton(index: number, screen: SettingsScreen): void {
    setSettingsScreen(screen);
    navigate(routes[index]);
  }

  const [t] = useTranslation();
  const navigate = useNavigate();
  const settingsScreen = useApp((appState) => appState.state.settingsScreen);
  const setSettingsScreen = useApp.getState().reducers.setSettingsScreen;

  const { data } = useQuery("/userinfo", loadUserProfile);
  const genres = data?.genres?.filter(genre => !!genre) || ["smartgenres"];

  const targetGender = usePlayer(
    (playerApp) => playerApp.state.settings.targetGender
  );
  const targetAge = usePlayer(
    (playerApp) => playerApp.state.settings.targetAge
  );

  const currentIdToLabel: IdToLabel = useMemo(
    () => idToLabel(),
    [targetAge, targetGender, data]
  );

  if (!data) {
    return null;
  }

  return (
    <Container>
      <Header>
        <Title>{t("Settings.settingsTitle")}</Title>
      </Header>
      <WrapperButtons>
        <BigButton
          title={t("Settings.businessType")}
          type="business"
          active={settingsScreen === SettingsScreen.TypeSetting}
          handlePress={() => handlePressOnButton(1, SettingsScreen.TypeSetting)}
          actualState={`${currentIdToLabel[data?.business_type]}`}
        />
        <BigButton
          title={t("Settings.targetGroup")}
          type="target"
          active={settingsScreen === SettingsScreen.TargetSetting}
          handlePress={() =>
            handlePressOnButton(2, SettingsScreen.TargetSetting)
          }
          actualState={`${currentIdToLabel[targetGender]}, ${targetAge[0]} - ${
            targetAge[1]
          }, ${currentIdToLabel[data?.styles[0] || "alternative"]}`}
        />
        <BigButton
          title={t("Settings.preferredGenres")}
          type="genres"
          active={settingsScreen === SettingsScreen.GenresSetting}
          handlePress={() =>
            handlePressOnButton(3, SettingsScreen.GenresSetting)
          }
          actualState={`${currentIdToLabel[genres[0] || 'smartgenres']}${currentIdToLabel[genres[1]] ? ',' : '' } ${currentIdToLabel[genres[1]] || '' }${currentIdToLabel[genres[2]] ? ',' : '' } ${currentIdToLabel[genres[2]] || ''}`}
        />
        <BigButton
          title={t("Settings.advancedSettings")}
          type="advanced"
          active={settingsScreen === SettingsScreen.AdvancedSetting}
          handlePress={() => handlePressOnButton(5, SettingsScreen.AdvancedSetting)}
          actualState={`${data?.genres.includes("explicit") ? t("Settings.includeExplicit") : ""}`}
        />
      </WrapperButtons>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
const Header = styled.div`
  width: 100%;
  height: 50px;
  padding-left: 20px;
  padding-right: 20px;
`;
const WrapperButtons = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin-top: 10px;
  padding: 15px;
  justify-content: flex-start;
  gap: 15px;
`;

export default SettingsMenu;
