import React, {useState, useRef, useEffect, useMemo} from 'react';
import styled from 'styled-components';
import {useQueries} from 'react-query';
import {useTranslation} from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {UnorderedListOutlined} from '@ant-design/icons';
import { Modal } from 'antd';

import scheduleIcon from '../../assets/icons/scheduleIcon.png';
import stationIcon from '../../assets/icons/stationsIcon.png';
import playlistIcon from '../../assets/icons/playlistIcon.png';
import {
  redBG,
  grey,
  lightgray,
  white,
  veryVeryLightGrey,
  black
} from '../../config/colors';
import {
  getCurrentScheduleItem,
  getZoneById
} from '../../services/SoundSuitService';
import {
  ItemSchedule,
  TypeMedia,
  Zone,
  MediaToPlay
} from '../../services/SoundSuitServiceTypes';
import {getMoodFromRange, capitalizeFirstLetter} from '../../utils/functions';
import {useSchedule} from '../../hooks';
import {usePlayer, useApp} from '../../store';
// import components
import Text from '../atoms/Text';
import SelectedMediaLabel from './SelectedMediaLabel';
import ModalMedias from './ModalMedias';

interface Props {
}

const BarOptionsPlayback: React.FC<Props> = props => {

  function modalCancel() {
    setOpenModalMedia(false);
  }

  function getStartLimit(): string | undefined {
    if (currentItem) {
      const daySlots = sortTimeslotsByDay(
        timeslots,
        currentItem.timeSlot.start.day,
      );
      const indexDay = daySlots.findIndex(sl => sl.id === currentItem.id);
      const startLimit =
        typeof daySlots[indexDay - 1] !== 'undefined'
          ? daySlots[indexDay - 1].timeSlot.end.hour
          : undefined;
      return startLimit;
    }
  }
  function getEndLimit(): string | undefined {
    if (currentItem) {
      const daySlots = sortTimeslotsByDay(
        timeslots,
        currentItem.timeSlot.start.day,
      );
      const indexDay = daySlots.findIndex(sl => sl.id === currentItem.id);
      const endLimit =
        indexDay < daySlots.length - 1
          ? daySlots[indexDay + 1].timeSlot.start.hour
          : undefined;
      return endLimit;
    }
  }


  function handlePressSchedule() {
    if (currentItem) {
      navigate("/schedule/item?" + new URLSearchParams({
        id: currentItem.id,
        day: currentItem.timeSlot.start.day,
        startDate: currentItem.timeSlot.start.hour,
        endDate: currentItem.timeSlot.end.hour,
        // @ts-ignore
        repeat: currentItem.repeat,
        mood: JSON.stringify(getMoodFromRange(currentItem.bpmRange as number[])),
        genre: JSON.stringify(currentItem.genres),
        target: JSON.stringify(currentItem.targetGroup),
        startLimit: getStartLimit(),
        endLimit: getEndLimit()
      }).toString());
    } else {
      navigate('/schedule')
    }
  }

  function handleModalsNavigation() {
    if (mode === 'schedule') {
      handlePressSchedule();
    } else {
      setOpenModalMedia(true);
    }
  }

  async function handleModeChange(type: TypeMedia) {
    try {
      if (currentChoiceMedia && typeof currentChoiceMedia[type] !== 'undefined') {
        await setMediaToPlay(currentChoiceMedia[type]);
      } else {
        if (dataZone) {
          const keyMedia = `authorized${capitalizeFirstLetter(type)}s`;
          // @ts-ignore
          const defaultId: string | undefined = dataZone?.[keyMedia]?.[0] || undefined;
          if (defaultId) {
            const mediaToSend: MediaToPlay = { id: defaultId, type };
            await setMediaToPlay(mediaToSend);
          } else {
            // TODO: TEMP FOR SCHEDULE
            if (type === 'schedule') {
              await setMediaToPlay({id: '123654789654', type: 'schedule'});
            }
          }
        }
      }
    } catch (error) {
      console.log('ERROR changing media: ', error)
    }
  }

  const {t} = useTranslation();
  const navigate = useNavigate();

  const {sortTimeslotsByDay} = useSchedule();

  const mediaToPlay = usePlayer(statePlayer => statePlayer.state.mediaToPlay);
  const currentChoiceMedia = usePlayer(statePlayer => statePlayer.state.currentChoiceMedia);
  const zoneSelected = usePlayer(statePlayer => statePlayer.state.zoneSelected);
  const timeslots = usePlayer(statePlayer => statePlayer.state.timeslots);
  const setMediaToPlay = usePlayer.getState().effects.setMediaToPlay;

  const [openModalMedia, setOpenModalMedia] = useState(false);

  const mode = mediaToPlay?.type || 'station';
  const idMedia = mediaToPlay?.id || '';



  const timezone: string = useRef(Intl.DateTimeFormat().resolvedOptions().timeZone).current;
  // @ts-ignore
  const [
    {data: dataS},
    {data: dataZone}
  ]:
  [
    {data: ItemSchedule},
    {data: Zone}
  ] =
    useQueries([
      {
        queryKey: '/currentschedule',
        queryFn: () => getCurrentScheduleItem(timezone),
      },
      {
        queryKey: ['zone', zoneSelected],
        queryFn: () => {
          if (zoneSelected) {
            return getZoneById(zoneSelected)
          }
        }
      }
    ]);

  const [currentItem, setCurrentItem] = useState<ItemSchedule | null>(null);

  useEffect(() => {
    if (mode === 'schedule' && dataS && Object.keys(dataS).length > 0) {
      setCurrentItem(dataS as ItemSchedule);
    }
  }, [mode, dataS]);

  const labels = useMemo(() => ({
    'schedule': t('Schedule.scheduleTitle'),
    'station': t('Settings.stationLabel'),
    'playlist': t('Settings.playlistLabel'),
  }), []);

  return (
    <Container>
      <ButtonMode
        onClick={handleModalsNavigation}
      >
        <WrapperSelectInfo>
          <Text color={redBG} size='small'>{labels[mode]}</Text>
          {mediaToPlay && <SelectedMediaLabel media={mediaToPlay} />}
        </WrapperSelectInfo>
        <UnorderedListOutlined style={{ fontSize: '20px' }}  />
      </ButtonMode>
      <WrapperOptions>
        <WrapperIcon
          active={mode === 'schedule'}
          onClick={() => handleModeChange('schedule')}
        >
          <Icon
            active={mode === 'schedule'}
            src={scheduleIcon}
          />
        </WrapperIcon>
        <WrapperIcon
          active={mode === 'station'}
          onClick={() => handleModeChange('station')}
        >
          <Icon
            active={mode === 'station'}
            src={stationIcon}
          />
        </WrapperIcon>
        {/* <WrapperIcon
          active={mode === 'playlist'}
          onClick={() => handleModeChange('playlist')}
          >
          <Icon
            active={mode === 'playlist'}
            src={playlistIcon)}
          />
        </WrapperIcon> */}
      </WrapperOptions>
      <Modal
        title=""
        visible={openModalMedia}
        width={800}
        onCancel={modalCancel}
        footer={[]}
      >
        <ModalMedias type={mode} close={() => setOpenModalMedia(false)} />
      </Modal>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;
const WrapperSelectInfo = styled.div`
  display: flex;
  flex-direction: column;

`;
const ButtonMode = styled.button`
  width: 100%;
  height: 50px;
  border: solid 1px ${lightgray};
  border-radius: 8px;
  padding: 5px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
`;
const WrapperOptions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;
const WrapperIcon = styled.button<{active: boolean}>`
  width: 40px;
  height: 40px;
  background-color: ${({active}) => active ? veryVeryLightGrey : white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 0;
  background-color: transparent;
  cursor: pointer;
`;
const Icon = styled.img<{active: boolean}>`
  width: 20px;
  height: 20px;
  tint-color: ${({active}) => active ? black : grey};
`;

export default BarOptionsPlayback;
