import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import {useTranslation} from 'react-i18next';

import { MediaToPlay, ItemSchedule } from "../../services/SoundSuitServiceTypes";
import { getStationById, getCurrentScheduleItem } from '../../services/SoundSuitService';
// import components
import Text from "../atoms/Text";

type Props = {
  media: MediaToPlay
};

const SelectedMediaLabel = (props: Props) => {

  const {
    media: {
      id,
      type
    }
  } = props;

  const timezone: string = useRef(Intl.DateTimeFormat().resolvedOptions().timeZone).current;
  const {t} = useTranslation();

  const { data: dataStations } = useQuery(['station', id], () => {
    if (type === 'station' && id) {
      return getStationById(id)
    }
  });
  // @ts-ignore
  const { data: dataSchedule }: {data: ItemSchedule} = useQuery('/currentschedule', () => getCurrentScheduleItem(timezone));

  const [modeDescription, setModeDescription] = useState<string>('');

  useEffect(() => {
    if (type === 'schedule') {
      if (dataSchedule && Object.keys(dataSchedule).length > 0) {
        const day = dataSchedule.timeSlot.start.day;
        const start = dataSchedule.timeSlot.start.hour.slice(0, -3);
        const end = dataSchedule.timeSlot.end.hour.slice(0, -3);
        setModeDescription(`${t(`Schedule.${day}`)} ${start}-${end}`);
      } else {
        setModeDescription(t('Player.scheduleLabel'));
      }
    } else if (type === 'station') {
      if (dataStations && Object.keys(dataStations).length > 0) {
        setModeDescription(dataStations.name);
      }
    } else if (type === 'playlist') {

    }
  }, [type, dataStations, dataSchedule]);

  return (
    <ButtonRow>
      <Text size="xsmall">
        {modeDescription}
      </Text>
    </ButtonRow>
  );
}

const ButtonRow = styled.div`

`;

export default SelectedMediaLabel;