import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import iconBanned from '../../assets/icons/banned.png';
import iconBan from '../../assets/icons/ban.png';
import iconLiked from '../../assets/icons/liked.png';
import iconLike from '../../assets/icons/like.png';

import {
  likeSongs,
  unlikeSongs,
  banSongs,
  unbanSongs
} from '../../services/SoundSuitService';
import { Track } from '../../services/SoundSuitServiceTypes';
// import components

interface Props {
  currentTrack: Track;
};

const BarOptionsTrack: React.FC<Props> = props => {

  async function editTrack(key: "isLiked" | "isBanned", newValue: boolean): Promise<void> {

  }

  async function handleBan() {
    if(isBanned) {
      await mutateUnbannedSongs([trackId]);
    } else {
      await mutateBanSongs([trackId]);
    }
  };

  async function handleLike() {
    if(isLiked) {
      await mutateUnlikeSongs([trackId]);
    } else {
      await mutateLikeSongs([trackId]);
    }
  };

  const {
    currentTrack,
  } = props;

  const [t] = useTranslation();
  const [trackId, setTrackId] = useState<string>("");
  const [isLiked, setIsLiked] = useState<boolean>(false);
  const [isBanned, setIsBanned] = useState<boolean>(false);

  const { mutateAsync: mutateLikeSongs } = useMutation(likeSongs, {
    onSuccess: async () => {
      setIsLiked(true);
      editTrack( "isLiked" , true);
      mutateUnbannedSongs([trackId]);
    },
    onError: () => {
    }
  });
  const { mutateAsync: mutateUnlikeSongs } = useMutation(unlikeSongs, {
    onSuccess: () => {
      setIsLiked(false);
      editTrack( "isLiked", false);
    },
    onError: () => {
    }
  });
  const { mutateAsync: mutateBanSongs } = useMutation(banSongs, {
    onSuccess: () => {
      setIsBanned(true);
      editTrack("isBanned", true);
      mutateUnlikeSongs([trackId]);
    },
    onError: () => {
    }
  });
  const { mutateAsync: mutateUnbannedSongs } = useMutation(unbanSongs, {
    onSuccess: () => {
      setIsBanned(false);
      editTrack("isBanned", false);
    },
    onError: () => {
    }
  });

  useEffect(() => {
    setTrackId(currentTrack?._id);
    setIsLiked(currentTrack?.isLiked);
    setIsBanned(currentTrack?.isBanned);
  }, [currentTrack]);

  return (
    <Container>
      <Button onClick={handleBan} >
        {isBanned ? (
          <Icon src={iconBanned} />
        ):(
          <Icon src={iconBan} />
        )}
      </Button>
      <Button onClick={handleLike} >
        {isLiked ? (
          <Icon src={iconLiked} />
        ):(
          <Icon src={iconLike} />
        )}
      </Button>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const Button = styled.div`
  width: 60px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const Icon = styled.img`
  width: 24px;
  height: 22px;
`;

export default BarOptionsTrack;