import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  veryVeryLightGrey,
  veryLightLightGrey,
  almostWhite,
} from '../../config/colors';
import { MoodLabel } from '../../services/SoundSuitServiceTypes';
// import components
import Header from '../../components/molecules/Header';
import MoodOptionsSchedule from '../../components/organisms/MoodOptionsSchedule';

interface Props {};

const ScheduleMood: React.FC<Props> = (props) => {

  const navigate = useNavigate();

  const location = useLocation();
  const [params, setSearchParams] = useSearchParams();
  const data = Object.fromEntries([...params]);

  const [mood, setMood] = useState<(MoodLabel | undefined)[]>(typeof data?.mood !== 'undefined' ? JSON.parse(decodeURIComponent(data.mood)) : ['smartmood']);

  const { t } = useTranslation();

  useEffect(() => {
    setSearchParams({
      ...data,
      mood: encodeURIComponent(JSON.stringify(mood))
    });
  }, [mood, setSearchParams]);

  return (
    <Container>
      <Header
        title={t('Schedule.labelMood')}
        customGoBack={() => navigate(`..${location.search}`)}
      />
      <Body>
        <Wrapper>
          <MoodOptionsSchedule mood={mood} setMood={setMood} />
        </Wrapper>
      </Body>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  background-color: ${veryVeryLightGrey};
`;
const Body = styled.div`
  width: 100%;
  align-items: center;
`;
const Wrapper = styled.div`
  width: 100%;
  background-color: ${almostWhite};
  border-color: ${veryLightLightGrey};
  border-width: 1px;
  border-radius: 10px;
  margin-top: 25px;
`;

export default ScheduleMood;