import { Link } from "react-router-dom";
import { type ItemSchedule } from "../../services/SoundSuitServiceTypes";
import { useMemo } from "react";
import styled from "styled-components";
import {
  getHeightItemSchedule,
  getMoodFromRange,
  getTopItemSchedule,
} from "../../utils/functions";
import ItemScheduleContent from "./ItemScheduleContent";

interface Props {
  data: ItemSchedule;
  leftPos: number;
  widthColumn: number;
  startLimit: Date | string | undefined;
  endLimit: Date | string | undefined;
}

export default function ItemSchedule2(props: Props) {
  const { data, startLimit, endLimit, widthColumn, leftPos } = props;

  const {
    timeSlot: { start, end },
  } = data;

  const params = useMemo(
    () =>
      new URLSearchParams({
        id: data.id,
        originalDay: data.originalDay,
        day: data.timeSlot.start.day,
        startDate: data.timeSlot.start.hour,
        endDate: data.timeSlot.end.hour,
        repeat: data.repeat,
        days: JSON.stringify(data.days),
        mood: JSON.stringify(getMoodFromRange(data.bpmRange as number[])),
        genre: JSON.stringify(data.genres),
        target: JSON.stringify(data.targetGroup),
        startLimit,
        endLimit,
      } as Record<string, string>),
    [data, startLimit, endLimit]
  );

  const top = useMemo(() => getTopItemSchedule(start.hour), [start.hour]);
  const height = useMemo(
    () => getHeightItemSchedule(start.hour, end.hour),
    [start.hour, end.hour]
  );

  return (
    <Link to={"item?" + params.toString()}>
      <Container top={top} height={height} left={leftPos} width={widthColumn}>
        <ItemScheduleContent {...data} />
      </Container>
    </Link>
  );
}

const Container = styled.div<{
  top: number;
  height: number;
  left: number;
  width: number;
}>`
  position: absolute;
  top: ${({ top }) => `${top}px`};
  left: ${({ left }) => `${left}px`};
  width: ${({ width }) => `${width - 5}px`};
  height: ${({ height }) => `${height}px`};
  margin-left: 2.5px;
  padding: 5px;
  z-index: 10;
`;
