import React, { useRef, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { listGenres, idToLabel, IdToLabel, } from '../../config/hardData';
import { Genres } from '../../services/SoundSuitServiceTypes';
// import components
import Text from '../atoms/Text';
import CheckBox from '../atoms/CheckBox';

interface Props {
  genre: (Genres | undefined)[];
  setGenre: React.Dispatch<React.SetStateAction<(Genres | undefined)[]>>;
};

const GenreOptionsSchedule: React.FC<Props> = props => {

  function handleToggleSelection(label: Genres): void {
    const cloneGenre = genre.slice();
    if(genre.includes(label)) {
      const index = cloneGenre.findIndex(g => g === label);
      cloneGenre.splice(index, 1);
    } else {
      cloneGenre.push(label);
    }
    setGenre(cloneGenre);
  }

  const {
    genre,
    setGenre
  } = props;

  const [ t ] = useTranslation();

  const currentIdToLabel: IdToLabel = useRef(idToLabel()).current;
  const listGenresSorted = useMemo(() => listGenres.sort((a, b) =>  t(`Settings.${a.label}`).localeCompare(t(`Settings.${b.label}`))), [listGenres]);

  return (
    <WrapperOptions>
      <RowCenter>
      {listGenresSorted.map((g, i) => {
        return (
          <Line key={`${g.label}-${i}`} top={i !== 0} bottom={true} onClick={() => handleToggleSelection(g.label)} >
            <Icon src={g.img} />
            <Text size="xsmall">{currentIdToLabel[g.label]}</Text>
            <WrapperCheck><CheckBox active={genre.includes(g.label)} /></WrapperCheck>
          </Line>
        );
        }
      )}
      </RowCenter>
    </WrapperOptions>
  );
}

const WrapperOptions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const RowCenter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
const Line = styled.div<{top: boolean, bottom?: boolean}>`
  height: 70px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  border-top: solid ${({top}) => top ? '1px': '0px'} rgba(239, 240, 245, .4);
  border-bottom: solid ${({bottom}) => bottom ? '1px' : '0px'} rgba(239, 240, 245, .4);
  cursor: pointer;
`;
const Icon = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 15px;
`;
const WrapperCheck = styled.div`
  margin-left: auto;
`;

export default GenreOptionsSchedule;