import React from "react";
import styled from "styled-components";
import hexToRgba from "hex-alpha";

import {
  buttonBlue,
  buttonGreen,
  buttonRed,
  buttonPurple,
  white,
  lightBeige,
} from "../../config/colors";
// import components
import Text from "../atoms/Text";

interface Icons {
  [arg: string]: any;
}
interface BG {
  [arg: string]: string;
}

const icons: Icons = {
  business: require("../../assets/icons/businessType.png"),
  mood: require("../../assets/icons/mood.png"),
  genres: require("../../assets/icons/genres.png"),
  target: require("../../assets/icons/targetGroup.png"),
  advanced: require("../../assets/icons/advanced-settings.png"),
};
const bg: BG = {
  business: buttonRed,
  mood: buttonBlue,
  genres: buttonGreen,
  target: buttonPurple,
  advanced: lightBeige,
};

interface Props {
  title: string;
  active: boolean;
  handlePress: () => void;
  type: string;
  actualState: string;
}

const BigButton: React.FC<Props> = (props) => {
  const { title, active, handlePress, type, actualState } = props;

  return (
    <BackgroundColor onClick={handlePress} type={type}>
      <Container>
        {icons[type] ? <Icon src={icons[type]} size={60} /> : <></>}
        <WrapperTexts>
          <Text size="xsmall" style={{ paddingTop: 6 }} color={white} bold>
            {title}
          </Text>
          <Text size="small" style={{ paddingTop: 6 }} color={white}>
            {actualState}
          </Text>
        </WrapperTexts>
      </Container>
    </BackgroundColor>
  );
};

const BackgroundColor = styled.div`
  width: 40%;
  max-width: 180px;
  min-width: 100px;
  height: 230px;
  background-color: ${({ type }) => hexToRgba(bg[type], 1)};
  border-radius: 13px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  padding-bottom: 30px;
  cursor: pointer;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 70px 15px 0px 15px;
`;
const WrapperTexts = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;
const Icon = styled.img<{ size: number }>`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  margin-right: 10px;
`;

const BigButtonMemoized = React.memo(BigButton);

export default BigButtonMemoized;
