import { PlayerState } from '../store/modelPlayer';
import { UserState } from '../store/modelUser';


function setItem(key: string, payload: string): void {
  if(process.env.REACT_APP_PLATFORM === 'web') {
    localStorage.setItem(key, payload);
  } else if (process.env.REACT_APP_PLATFORM === 'electron') {
    const { myIpcRenderer } = window;
    const data = {
      [key]: payload
    };
    myIpcRenderer.send('APP_SAVE_STORAGE', data);
  }
}

async function getItem(key: string): Promise<string> {
  if(process.env.REACT_APP_PLATFORM === 'web') {
    return localStorage.getItem(key);
  } else if (process.env.REACT_APP_PLATFORM === 'electron') {
    const { myIpcRenderer } = window;
    const result: string = await myIpcRenderer.invoke('APP_GET_STORAGE', key);
    return result;
  }
}

function removeItem(key: string) {
  if(process.env.REACT_APP_PLATFORM === 'web') {
    localStorage.removeItem(key);
  } else if (process.env.REACT_APP_PLATFORM === 'electron') {
    const { myIpcRenderer } = window;
    myIpcRenderer.send('APP_REMOVE_STORAGE', key);
  }
}

export const labelStorageElectron = {
  'userState': 'APP_SAVE_USER',
  'playerState': 'APP_SAVE_PLAYER'
};

async function persistState(state: UserState | PlayerState, nameStorage: 'userState' | 'playerState'): Promise<void> {
  if (process.env.REACT_APP_PLATFORM === 'web') {
    try {
      const stringifiedState: string = JSON.stringify(state);
      setItem(nameStorage, stringifiedState);
    } catch (error) {
      console.error(error);
    }
  } else if (process.env.REACT_APP_PLATFORM === 'electron') {
    const { myIpcRenderer } = window;
    myIpcRenderer.send(labelStorageElectron[nameStorage], state);
  }
}



const storage = {
  setItem,
  getItem,
  removeItem,
  persistState
}

export default storage;