import type { ItemSchedule, Days } from '../services/SoundSuitServiceTypes';

const daysBeforeSunday = (day: Days): Days[] => {
  const days: Days[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
  const index = days.indexOf(day);
  return days.slice(index);
};

export default function useMigrateSlots() {

  function migrateSlots(timeslots: ItemSchedule[]): ItemSchedule[] {
    const timeSlotsClone = JSON.parse(JSON.stringify(timeslots));
    const updateTimeslots = [] as ItemSchedule[];
  
    timeSlotsClone.forEach(slot => {
      if (slot.days.length > 0) {
        updateTimeslots.push(slot);
      } else {
        let days: Days[];
        if (slot.repeat === 'daily') {
          days = daysBeforeSunday(slot.timeSlot.start.day);
        } else {
          days = [slot.timeSlot.start.day];
        }
    
        updateTimeslots.push({
          ...slot,
          days
        });
      }
    });
  
    return updateTimeslots;
  }

  return { migrateSlots};

}