import React from 'react';
import styled from 'styled-components';
import { CheckOutlined } from "@ant-design/icons";

import { lightgray, buttonRed, white } from '../../config/colors';
// import components

interface Props {
  active: boolean;
  disabled?: boolean;
};

const CheckBox: React.FC<Props> = ({active, disabled = false}) => {

  return (
    <Container active={active} disabled={disabled}>
      {active && <CheckOutlined style={{ color: white }} />}
    </Container>
  );
}

const Container = styled.div<{active: boolean; disabled: boolean}>`
  width: 25px;
  height: 25px;
  border: solid 2px ${({active, disabled}) => disabled ? lightgray : active ? buttonRed : lightgray};
  border-radius: 5px;
  padding-top: 1px;
  background-color: ${({active, disabled}) => disabled ? lightgray : active ? buttonRed : white};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default CheckBox;