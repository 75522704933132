import styled from 'styled-components';

// import components

interface Props {
  imgs: string[];
};

const MultipleImagesThumbnail = (props: Props) => {

  const {imgs} = props;

  if (imgs.length === 0) {
    return null;
  }

  return (
    <Container>
      {imgs.length === 1 && (
        <CardImage src={imgs[0]} width={100} height={100} />
      )}
      {imgs.length === 2 && (
        <Row>
          <CardImage src={imgs[0]} width={50} height={100} />
          <CardImage src={imgs[1]} width={50} height={100} />
        </Row>
      )}
      {imgs.length === 3 && (
        <Column>
          <Row>
            <CardImage src={imgs[0]} width={50} height={50} />
            <CardImage src={imgs[1]} width={50} height={50} />
          </Row>
          <Row>
            <CardImage src={imgs[2]} width={100} height={50} />
          </Row>
        </Column>
      )}
      {imgs.length > 3 && (
        <Column>
          <Row>
            <CardImage src={imgs[0]} width={50} height={50} />
            <CardImage src={imgs[1]} width={50} height={50} />
          </Row>
          <Row>
            <CardImage src={imgs[2]} width={50} height={50} />
            <CardImage src={imgs[3]} width={50} height={50} />
          </Row>
        </Column>
      )}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  z-index: 0;
  width: 100px;
  height: 100px;
  border-radius: 13px;
  overflow: hidden;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
const CardImage = styled.img<{width: number; height: number}>`
  z-index: 0;
  width: ${({width}) => width}px;
  height: ${({height}) => height}px;
  object-fit: cover;
`;

export default MultipleImagesThumbnail;