import React, { useEffect, useState } from "react";


function useWithColumnSchedule(myRef: React.MutableRefObject<any>):number {

  const [dimensions, setDimensions] = useState<number>(0);

  useEffect(() => {
    const getDimensions = () => myRef.current.offsetWidth;
    const handleResize = () => {
      setDimensions(getDimensions())
    }

    if (myRef.current) {
      setDimensions(getDimensions())
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [myRef]);

  return dimensions / 8;
};

export default useWithColumnSchedule;