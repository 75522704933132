import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { Outlet } from "react-router-dom";

import {
  getScheduleProgram,
} from '../../services/SoundSuitService';
import { usePlayer } from '../../store';
import {
  useSchedule,
  useWithColumnSchedule,
  useMigrateSlots
} from '../../hooks';
// import components
import HeaderSchedule from '../molecules/HeaderSchedule';
import CalendarTablet from '../molecules/CalendarTablet';

interface Props {};

const ScheduleScreen: React.FC<Props> = props => {

  const { data, isLoading } = useQuery('/scheduleprogram', getScheduleProgram);

  const { generateRecurrentSlots } = useSchedule();
  const { migrateSlots } = useMigrateSlots();

  const refScroll = useRef(null);
  const widthColumn = useWithColumnSchedule(refScroll);

  const setTimeslots = usePlayer.getState().reducers.setTimeslots;
  const scheduleDaySelected = usePlayer(statePlayer => statePlayer.state.scheduleDaySelected);

  useEffect(() => {
    if (data) {
      const migratedSlots = migrateSlots(data.timeslots);
      const recurrentProgram = generateRecurrentSlots(migratedSlots);
      setTimeslots(recurrentProgram);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <Container ref={refScroll}>
        <HeaderSchedule day={scheduleDaySelected} />
        <WrapperCalendar>
          <CalendarTablet widthColumn={widthColumn} loading={isLoading} />
        </WrapperCalendar>
      </Container>
      <Outlet />
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;
const WrapperCalendar = styled.div`
  width: 100%;
  flex-grow: 1;
  margin-top: 10px;
  overflow: hidden;
`;

export default ScheduleScreen;