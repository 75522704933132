import { black, buttonBlue, veryVeryLightGrey, white } from "../../config/colors";
import { type ItemSchedule } from "../../services/SoundSuitServiceTypes";
import styled from "styled-components";
import { idToLabel as getIdToLabel, listMoods } from "../../config/hardData";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useUser } from "../../store";
import { getMoodFromRange, getNowWithFormat, getSlotLength } from "../../utils/functions";
import { fetchListOfTracks } from "../../services/SoundSuitService";

export default function ItemScheduleContent(props: ItemSchedule) {
  const {
    timeSlot: { start, end },
    bpmRange,
    genres,
    targetGroup: {
      styles,
    },
  } = props;

  const [timezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const [artists, setArtists] = useState<string[]>([]);

  const bpms= bpmRange as number[];

  const slotLength = getSlotLength(start.hour, end.hour);

  const language = useUser((userState) => userState.state.language);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const idToLabel = useMemo(() => getIdToLabel(), [language]);

  const labelMood = getMoodFromRange(bpms);
  const indexMood = listMoods.findIndex((m) => m.label === labelMood[0]);
  const img = indexMood >= 0 ? listMoods[indexMood].img : null;

  const title = idToLabel[labelMood[0]];
  const title2 = genres.reduce((acc, g, i) => {
    if (i > 2) return acc;
    if (i === 2) {
      acc.push(idToLabel[g]);
      acc.push("...");
    } else {
      acc.push(idToLabel[g]);
    }
    return acc;
  }, []);

  const styleTags = styles.map((style) => idToLabel[style]);

  const fetchArtists = useCallback(async () => {
    const songs = await fetchListOfTracks({
      count: 3,
      mood: 0,
      now: getNowWithFormat(),
      playWithSchedule: false,
      timezone,
      reject: []
    });
    
    const artists = songs.map((song) => song.artistName);
    setArtists(artists);
  }, [timezone])

  useEffect(() => {
    fetchArtists();
  }, [fetchArtists]);

  return (
    <Container>
      <Header>
        {img && <Img src={img} alt={title} />}
        <HeaderContent>
          <Title>{title}</Title>
          {slotLength === 1 && (
            <TagContainer className="header">
              {title2.map((genre) => (
                <Tag key={genre}>{genre}</Tag>
              ))}
            </TagContainer>
          )}
          {slotLength > 1 && (
            <Bpm>{bpms.join(' - ')}</Bpm>
          )}
        </HeaderContent>
      </Header>
      {slotLength > 1 && (
        <>
          <TagContainer>
            {title2.map((genre) => (
              <Tag key={genre}>{genre}</Tag>
            ))}
          </TagContainer>
          {artists.length > 0 && (
            <Artists>
              {artists.join(", ")}
            </Artists>
          )}
          <TagContainer>
            {styleTags.slice(0, 2).map((style) => (
              <Tag key={style} className='outline'>{style}</Tag>
            ))}
          </TagContainer>
        </>
      )}
    </Container>
  );
}

const Img = styled.img`
  width: 40%;
  object-fit: cover;
  &::before {
    content: "";
    display: block;
    padding-top: 100%;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  width: 100%;
  height: 100%;
  padding: 15px 10px;
  border-radius: 5px;

  background-color: ${veryVeryLightGrey};
  container: item-schedule / size;
`;

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 12px;
`;

const HeaderContent = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
  height: 100%;
  overflow: hidden;
`;

const Title = styled.h3`
  width: 100%;
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1;
  margin: 0;
`;

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const Tag = styled.div`
  font-size: 0.75rem;
  line-height: 1;
  padding: 4px 8px;
  border-radius: 9999px;
  background-color: ${buttonBlue};
  color: ${white};

  &.outline {
    background-color: transparent;
    border: 1px solid ${buttonBlue};
    color: ${buttonBlue};
  }
`;

const Bpm = styled.div`
  display: flex;
  font-size: 0.75rem;
  line-height: 1;
  color: ${buttonBlue};
  padding-bottom: 4px;
`;

const Artists = styled.p`
  width: 100%;
  font-size: 0.75rem;
  line-height: 1;
  color: ${black};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  @supports not (-webkit-line-clamp: 2) {
    white-space: nowrap;
  }
`;