import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { CheckOutlined } from "@ant-design/icons";

import {
  veryVeryLightGrey,
  veryLightLightGrey,
  almostWhite,
  buttonRed
} from '../../config/colors';
import { Days } from '../../services/SoundSuitServiceTypes';
import { useSchedule } from '../../hooks';
import {  usePlayer } from '../../store';
import {
  transformHourStringtoDate,
  delay
} from '../../utils/functions';
// import components
import Text from '../../components/atoms/Text';
import Header from '../../components/molecules/Header';
import CheckBox from '../atoms/CheckBox';

interface Props {};

const ScheduleRepeat: React.FC<Props> = (props) => {

  function handleSelectAll() {
    if (days.length === (7 - unavailableDays.length)) {
      setDays([originalDay as Days])
    } else {
      const daysPossible = daysTab.map(({key}) => key).filter(key => !unavailableDays.includes(key as Days)) as Days[];
      setDays(daysPossible);
    }
  }

  function handlePress(day: Days) {
    if (day === originalDay) {
      return;
    }
    if (unavailableDays.includes(day)) {
      setMessageError(true);
      return;
    }
    setMessageError(false);
    const cloneDays = days.slice();
    if (cloneDays.includes(day)) {
      const i = cloneDays.indexOf(day);
      cloneDays.splice(i, 1);
    } else {
      cloneDays.push(day);
    }
    setDays(cloneDays);
  }

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { checkTimeSlotAvailable } = useSchedule();

  const [params, setSearchParams] = useSearchParams();
  const data = Object.fromEntries([...params]);

  const {
    id,
    day: originalDay,
    startDate,
    endDate,
  } = data;

  const [days, setDays] = useState<Days[]>(typeof data?.days !== 'undefined' ? JSON.parse(decodeURIComponent(data?.days)) : [originalDay]);
  const [unavailableDays, setUnavailableDays] = useState<Days[]>([]);
  const [messageError, setMessageError] = useState<boolean>(false);

  const timeslots = usePlayer(statePlayer => statePlayer.state.timeslots);

  const daysTab = useMemo(() => ([
    { key: 'monday', label: t('Schedule.monday') },
    { key: 'tuesday', label: t('Schedule.tuesday')  },
    { key: 'wednesday', label: t('Schedule.wednesday')  },
    { key: 'thursday', label: t('Schedule.thursday') },
    { key: 'friday', label: t('Schedule.friday') },
    { key: 'saturday', label: t('Schedule.saturday') },
    { key: 'sunday', label: t('Schedule.sunday') },
  ]), []);

  useEffect(() => {
    const col: Days[] = [];
    for (let index = 0; index < daysTab.length; index++) {
      const { key } = daysTab[index];
      if (key !== originalDay) {
        const isValid = checkTimeSlotAvailable(
          timeslots,
          key as Days,
          id,
          transformHourStringtoDate(startDate),
          transformHourStringtoDate(endDate)
        );
        if (isValid !== 'valid') {
          col.push(key as Days);
        }
      }
    }
    // We remove the unvailable days from the selection if they are presents
    const newSel = days.filter(d => !col.includes(d));
    setDays(newSel);
    setUnavailableDays(col);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSearchParams({
      ...data,
      days: encodeURIComponent(JSON.stringify(days))
    })
  }, [days]);

  return (
    <Container>
      <Header
        title={t('Schedule.repeatTitle')}
        customGoBack={() => {
          navigate(`..${location.search}`);
        }}
      />
      <Body>
        {unavailableDays.length === 0 && (
          <BlocLine>
            <Line top={false} onClick={handleSelectAll}>
              <WrapperCheck><CheckBox active={days.length === (7 - unavailableDays.length)} /></WrapperCheck>
              <Text style={{paddingTop: 6}} size="xsmall" >{t('Schedule.labelRepeatdaily')}</Text>
            </Line>
          </BlocLine>
        )}
        <BlocLine>
            {daysTab.map((day => {
              const { key, label } = day;
              return (
                <Day key={`repeat-days-${key}`} onClick={() => handlePress(key as Days)}>
                  <WrapperCheck><CheckBox active={days.includes(key as Days)} disabled={key === originalDay} /></WrapperCheck>
                  <Text style={{ paddingTop: 5 }} color={unavailableDays.includes(key as Days) ? veryLightLightGrey : 'black'} >{label}</Text>
                </Day>
              );
            }))}
          </BlocLine>
          {messageError && <Text style={{ marginTop: 10 }} color={buttonRed}>{t('Schedule.unavailableDay')}</Text>}
      </Body>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  background-color: ${veryVeryLightGrey};
`;
const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const BlocLine = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: ${almostWhite};
  border-color: ${veryLightLightGrey};
  border-width: 1px;
  border-radius: 10px;
  margin-top: 25px;
  padding: 10px 0 10px 15px;
`;
const Line = styled.div<{top: boolean}>`
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-top: solid ${({top}) => top ? '1px': '0px'} ${veryLightLightGrey};
  cursor: pointer;
`;
const WrapperCheck = styled.div`
  padding-right: 10px;
`;
const Day = styled.div`
  height: 50px;
  width: 130px;
  margin-right: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export default ScheduleRepeat;