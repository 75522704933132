import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { createId } from '@paralleldrive/cuid2';
import { Modal, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import ENVS from '../../config';
import { useApp } from '../../store';
// import components
import ScreenContainer from '../atoms/ScreenContainer';

type Props = {};

const SignScreen: React.FC<Props> = (props) => {

  const [modalVisible, setModalVisible] = useState<boolean>(true);
  const [tokenId, setTokenId] = useState<string>("");


  const [t] = useTranslation();
  const params = useParams();
  const loginSucess = useApp.getState().effects.loginSuccess;
  const navigate = useNavigate();

  useEffect(() => {
    const id = createId();
    setTokenId(id);
  }, []);

  useEffect(() => {

    function handleMessage(event) {
      const data = JSON.parse(event.data);
      if (data.redirectId === tokenId) {
        loginSucess(data, navigate);
      } else {
        notification.open({
          message: '',
          description: t('Notifications.requestFailed'),
        });
      }
    }

    if (tokenId) {
      window.addEventListener('message', handleMessage);
    }

    return () => {
      window.removeEventListener('message', handleMessage);
    }
  }, [tokenId]);


  if (!createId) {
    return null;
  }


  return (
    <ScreenContainer gradientBG>
      <Modal
        title=""
        visible={modalVisible}
        onCancel={() => navigate('/welcome')}
        width={600}
        footer={[]}
        style={{padding: 0}}
      >
        <ContainerModal>
          <iframe
            title='account'
            src={`${ENVS.ACCOUNT_SOUNDSUIT_URL}${params.type === 'signup' ? '' : '/signin'}?from=electron&redirectId=${tokenId}`}
            width={600}
            height={700}
            style={{ border: "none"}}
          ></iframe>
        </ContainerModal>
      </Modal>
    </ScreenContainer>
  );
}

const ContainerModal = styled.div`
  width: 100%;
  height: 700px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default SignScreen;